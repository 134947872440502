import { DRMServiceConfig, WidevineConfig, PlayreadyConfig, FairplayConfig } from './drm.types';
import { DRMService } from './drm.service';
import { PlaylistEntry, VmsRealtimeService } from '../../components/player';

const PLAYREADY_TOKEN_PATH: string = '/authorization/playready/gettoken';
const WIDEVINE_PATH: string = '/authorization/widevine/getresourcekey';
const FAIRPLAY_PATH: string = '/authorization/fairplay/getresourcekey';

// This service is responsible for handling VMS DRM calls. The different types of DRM calls and their differences
//    are documented below. All DRM-related calls to VMS require an authorizationToken from a getStream request.

export class VMSDRMService extends DRMService {
    public setup(config: DRMServiceConfig) {
        this._config = {
            // Microsoft PlayReady is handled in the old two-part style: a DRM license token must be requested first at
            //   PLAYREADY_TOKEN_PATH, **before** playback; subsequently, JW can request a license at the below URL.
            playreadyLicenseUrl: config.playreadyLicenseUrl ? config.playreadyLicenseUrl : this.PLAYREADY_LICENSE_URL,

            // Widevine is handled using the below URL, sending the encryption through VMS as a proxy.
            //   JW actually sends two calls; the first two-byte call should be (but currently isn't) treated as a
            //   certificate request, **not** a license token request. (Long-standing issue.)
            widevineLicenseUrl: config.widevineLicenseUrl ? config.widevineLicenseUrl : config.vmsRealtimeUrl + WIDEVINE_PATH,

            // Apple Fairplay has separate URLs for the certificate and license requests. Only the license comes through VMS.
            fairplayCertificateUrl: config.fairplayCertificateUrl ? config.fairplayCertificateUrl : this.FAIRPLAY_CERTIFICATE_URL,
            fairplayLicenseUrl: config.fairplayLicenseUrl ? config.fairplayLicenseUrl : config.vmsRealtimeUrl + FAIRPLAY_PATH,
        };
    }

    public handleDRM(entry: PlaylistEntry): void {
        // While loading, Edge requires a primary call to get the token
        if (this._utils.isEdge() || this._utils.isIE()) {
            VmsRealtimeService.requestAndDecode(PLAYREADY_TOKEN_PATH, {
                authorization_token: entry.authorizationToken
            }).then(responseData => {
                this._applyDRMConfig(entry, responseData.request_token_data);
                this.drmHandled.fire(this, { entry: entry });
            });
        } else {
            this._applyDRMConfig(entry);
            // Fire drmHandled event
            this.drmHandled.fire(this, { entry: entry });
        }
    }

    protected _applyDRMConfig(entry: PlaylistEntry, token: string = null): void {
        const { sources } = entry;
        for (let i = 0; i < sources.length; i++) {
            switch (sources[i].type) {
                case 'dash':
                    this._setWidevineConfig(sources[i], entry.authorizationToken);
                    if (token) {
                        this._setPlayreadyConfig(sources[i], token);
                    }
                    break;

                case 'hls':
                    this._setFairplayConfig(sources[i], entry.mediaId, entry.authorizationToken);
                    break;
            }
        }
    }

    protected _setWidevineConfig(source: VideoSource, authorizationToken: string): void {
        var config: WidevineConfig = {
            url: this._config.widevineLicenseUrl,
            licenseRequestHeaders: [{
                name: 'Content-Type',
                value: 'application/json'
            }],
            licenseRequestFilter: (request: any) => {
                // create the license request body required by VMS
                request.body = JSON.stringify({
                    authorization_token: authorizationToken,
                    license_request_data: Array.apply(null, new Uint8Array(request.body)),
                });
            }
        };

        source.drm = source.drm || {};
        source.drm.widevine = config;
    }

    protected _setFairplayConfig(source: VideoSource, contentId: string, authorizationToken: string): void {
        var config: FairplayConfig = {
            certificateUrl: this._config.fairplayCertificateUrl,
            processSpcUrl: this._config.fairplayLicenseUrl,
            extractContentId: () => { return contentId; },
            licenseRequestHeaders: [{
                name: 'Content-Type',
                value: 'application/json'
            }],
            licenseRequestMessage: (keyMessage: string) => {
                var body: any = {
                    authorization_token: authorizationToken,
                    license_request_data: this._base64EncodeUint8Array(keyMessage)
                };
                return JSON.stringify(body);
            },
            licenseResponseType: 'arraybuffer',
        };

        source.drm = source.drm || {};
        source.drm.fairplay = config;
    }

    // While the required DRM token is retrieved in this class, PlayReady configuration is handled
    //   in the legacy fashion in the parent class.
}
