import { Player, Render } from '../player';
import { ErrorMessageModel, GenericMessageTemplate } from '../errorMessage';
import { UtilsService } from '../../services';

export class ErrorMessageComponent {
    private MESSAGE_CSS_CLASS: string = 'jwplayer-messaging';
    private get _containerElement():HTMLElement { return <HTMLElement> this.$window.document.querySelector(this._container); }
    private get _messageContainerElement():HTMLElement { return <HTMLElement> this._containerElement.querySelector('.' + this.MESSAGE_CSS_CLASS); }

    public constructor( private _container: string,
                        private _model: ErrorMessageModel,
                        private _skin: string,
                        private _template: any = GenericMessageTemplate,
                        private $window: Window = window,
                        private _utils: UtilsService = UtilsService.Instance,
                        private $render: any = Render) {

        if (this._skin) {
            this._model.skin = 'jw-skin-' + this._skin;
        }

        if (!this._model.type) {
            this._model.type = 'generic';
        }

        this.render();
    }

    public render(): void {
        var element = document.createElement('div');
        element.classList.add(this.MESSAGE_CSS_CLASS);
        this._containerElement.appendChild(element);

        const html = this.$render(this._template, this._model);
        this._messageContainerElement.innerHTML = html;
    }

    private _log(message: string, info?: any) {
        this._utils.log('ErrorMessageComponent', message, info);
    }
}
