import { Player } from '../player';
import { UtilsService } from '../../services';

export class CaptionComponent {
    private _playerContainer: HTMLElement;

    private _tracks: Caption[];
    private _initialTrack: number;
    private _ccOnTrackIdx: number;
    private _ccOffTrackIdx: number;
    private _buttonClickRegistered: boolean = false;

    private get _ccButton(): HTMLElement { return <HTMLElement> this._playerContainer.querySelector('.jw-icon-cc'); }
    private get _ccOverlay(): HTMLElement { return <HTMLElement> this._ccButton.querySelector('.jw-overlay'); }

    public constructor(
        private _player: Player,
        e: CaptionsEvent,
        private _utils: UtilsService = UtilsService.Instance) {

        this._playerContainer = this._player.playerContainer;
        this.update(e);
    }

    public update(e: CaptionsEvent):void {
        if (!this._tracks || e.tracks.length > this._tracks.length) {
            this._log('update', e);
            this._tracks = e.tracks;

            // Custom button click listener to enable toggling on/off of CC when multiple CC tracks are available.
            if (e.tracks.length >= 2 && !this._buttonClickRegistered) {
                this._buttonClickRegistered = true;
                this._ccButton.addEventListener('click', (e: Event) => this._ccButtonClicked(e), false);
                this._ccButton.addEventListener('touchstart', (e: Event) => this._ccButtonClicked(e), false);

                if (this._ccOverlay) {
                    this._ccOverlay.style.visibility = 'hidden';
                }
            }

            // Determine the track index for CC on / off, prioritizing the in stream track.
            for ( let i = 0; i < this._tracks.length; i++) {
                const trackId = this._tracks[i].id;
                if ( 'off' === trackId ) {
                    this._ccOffTrackIdx = i;
                } else if ( /http(s):\/\/.+\.vtt$/.test( trackId ) ) {
                    // Webvtt track (sideloaded track for speciality channels)
                    // Only rely on Webvtt track when in stream track is not available.
                    this._ccOnTrackIdx = i;
                } else if ( /^[a-zA-Z0-9\-_]+$/.test( trackId ) ) {
                    // In stream embedded track
                    this._ccOnTrackIdx = i;
                }
            }

            this._log( 'CC Track Off Track', this._ccOffTrackIdx.toString() );
            this._log( 'CC Track On Track', this._ccOnTrackIdx.toString() );

            // Be sure to set the proper ON track
            if (this._isCCOn(e) && this._ccOnTrackIdx !== e.track && this._ccOnTrackIdx >= 0) {
                this._log('select proper ON track to', this._tracks[this._ccOnTrackIdx]);
                this._player.setCurrentCaptions(this._ccOnTrackIdx);
            }
        }
    }

    private _ccButtonClicked(e: Event): void {
        this._log('_ccButtonClicked');

        e.stopPropagation();
        e.preventDefault();

        var currentIdx: number = this._player.currentCaptionsTrack;
        var target: number = (currentIdx === this._ccOffTrackIdx) ? this._ccOnTrackIdx : this._ccOffTrackIdx;
        this._log('set caption track to', this._tracks[target]);
        this._player.setCurrentCaptions(target);
    }

    private _isCCOn(e: CaptionsEvent): boolean {
        return e.track !== this._ccOffTrackIdx;
    }

    private _log(message: string, info?: any): void {
        this._utils.log('CaptionComponent', message, info);
    }
}
