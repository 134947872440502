export type XhrCallback = (success: boolean, status: number, data: any) => void;

export class XhrService {
    public getJson(url: string, callback?: XhrCallback): any {
        this.sendRequest(url, 'GET', null, (success: boolean, status: number, data: any) => {
            if (callback) {
                if (success) {
                    const json: any = JSON.parse(data);
                    callback.call(null, true, status, json);
                } else {
                    callback.call(null, false, status, data);
                }
            }
        });
    }

    public getPlain(url: string, callback?: XhrCallback): any {
        this.sendRequest(url, 'GET', null, (success: boolean, status: number, data: any) => {
            if (callback) {
                callback.call(null, success, status, data);
            }
        });
    }

    public post(url: string, data: string, callback?: XhrCallback) {
        this.sendRequest(url, 'POST', data, (success: boolean, status: number, data: any) => {
            if (callback) {
                callback.call(null, success, status, null);
            }
        });
    }

    private sendRequest(url: string, method: string, data: string, callback: XhrCallback): any {
        var request = new XMLHttpRequest();
        request.open(method, url, true);

        request.onreadystatechange = function() {
            if (this.readyState === XMLHttpRequest.DONE) {
                if (this.status >= 200 && this.status < 400) {
                    try {
                        callback.call(null, true, this.status, this.responseText);
                    } catch (error) {
                        callback.call(null, false, this.status, error);
                    }
                } else {
                    callback.call(null, false, this.status, this.responseText);
                }
            }
        };

        if (data) {
            request.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            request.send(data);
        } else {
            request.send();
        }
        request = null;
    }
}
