import { UtilsService } from '../utils';
import { Service } from '../';
import { Player } from '../../components/player';

export class ChartbeatService implements Service {
    private static _instance: ChartbeatService;

    public constructor(private $window:Window = window, private _util:UtilsService = UtilsService.Instance) {
    }

    public static get Instance():ChartbeatService {
        if (!ChartbeatService._instance) {
            ChartbeatService._instance = new ChartbeatService();
        }
        return ChartbeatService._instance;
    }

    public register( jwplayer: JWPlayer, player: Player ): void {
        this._log('register JWPlayer');
        this.$window['_cbv'] = this.$window['_cbv'] || [];
        this.$window['_cbv'].push(jwplayer);
    }

    private _log(message: string, info?: any): void {
        this._util.log('ChartbeatService', message, info);
    }
}