import { PlaylistEntry } from '../../components/player';

export interface AdConfig {
    [key: string]: any;
    adUnitPath: string;
    cmsId: string;
    environment: string;
    playerFormat: string;
    adProviderUrlFormat?: string;
    customParamFormat?: string;
    companionDivs?: AdDiv[];
    customParams?: { [key: string]: any };
    biddingEnabled?: boolean;
    descriptionUrl?: string;
    mutedAutoplay?: boolean;
    adTagParameters?: any;
    dai?: boolean;
}

/**
 * Helper class for video ad logic for JW Player.
 * Responsible for (1) generating the ad settings, and (2) managing companion ad slots.
 * For a single ad, this service assumes it will be handled by the video player.
 */
export class AdService {
    /**
     * Google IMA ad URL. The variables come in a few forms and are replaced in different stages:
     * %%PARAMS%% are replaced by Google DFP. (None currently in use.)
     * __params__ are replaced by JWPlayer.
     * {params} are replaced by our code below.
     * @prop {string} DEFAULT_AD_URL - the default value; suitable for most sites.
     */

    private DEFAULT_AD_URL: string = '//pubads.g.doubleclick.net/gampad/ads?sz=320x240&iu={adUnitPath}&impl=s&gdfp_req=1&env=vp&output=xml_vast3&unviewed_position_start=1&url=__page-url__&correlator=__timestamp__&cmsid={cmsId}&vid={videoId}&cust_params={customParams}&description_url={descriptionUrl}';

    private DEFAULT_CUSTOM_PARAM: string = 'playerformat%3D{playerFormat}%26environ%3D{environment}%26chapter%3D{chapter}';

    private _biddingEnabled: boolean = false;

    /** @prop {AdvertisingOptions} _adOptions - Options for JW Player (the "advertising" property of its config). */
    private _adOptions: AdvertisingOptions;
    get adOptions(): AdvertisingOptions {
        return this._adOptions;
    }

    constructor(
        private _config: AdConfig = null,
        private $document: Document = document,
        private $window: Window = window,) {
        this._adOptions = {
            client: 'googima',
            enablePreloading: false,
            loadVideoTimeout: 6000,
            requestTimeout: 12000,
            vastLoadTimeout: 6000,
            vpaidcontrols: true,
            vpaidmode: 'enabled'
        };

        // Allow ads to autoplay with muted audio. This way, mobile autoplay should work
        if ( this._config.mutedAutoplay != null ) {
            this._adOptions.autoplayadsmuted = this._config.mutedAutoplay;
        }

        if (this._config.biddingEnabled != null) {
            this._biddingEnabled = this._config.biddingEnabled;
        }
    }

    public applySchedule(playlist: PlaylistEntry[]): void {
        for (let { length } = playlist, i = 0; i < length; ++i) {
            const playlistItem = playlist[i];

            if ( this._config.dai && playlistItem && playlistItem.daiSetting && this._config.adTagParameters ) {
                // DAI settings.
                this._adOptions.client = 'dai';
                this._adOptions.adTagParameters = this._config.adTagParameters;
            } else {
                // Regular ad settings.
                const url = this._generateAdUrl(playlistItem);
                playlistItem.adschedule = this._biddingEnabled ? this._generateIndexVideoTag(url) : url;
            }
        }
    }

    private _generateIndexVideoTag(url: string) {
        if (typeof this.$window.generateIndexVideoTag === 'function') {
            url = this.$window.generateIndexVideoTag(url);
        }
        return url;
    }

    private _generateAdUrl(videoItem: PlaylistEntry): string {
        var result: string = (this._config.adProviderUrlFormat || this.DEFAULT_AD_URL);
        var chapterString: string = (videoItem.startChapter === undefined ? -1 : videoItem.startChapter).toString();

        // Replace any additional properties in the ad URL.
        result = result
            .replace('{adUnitPath}', this._config.adUnitPath)
            .replace('{cmsId}', this._config.cmsId)
            .replace('{videoId}', videoItem.mediaId)
            .replace('{customParams}', this._generateCustomParams())
            .replace('{environment}', this._config.environment)
            .replace('{playerFormat}', this._config.playerFormat)
            .replace('{descriptionUrl}', this._computeDescriptionUrl(videoItem))
            .replace('{chapter}', chapterString);

        return result;
    }

    private _generateCustomParams(): string {
        var result: string = this._config.customParamFormat != null ? this._config.customParamFormat : this.DEFAULT_CUSTOM_PARAM;
        var params: string[] = [];

        // Append additional custom params
        if (this._config.customParams) {
            for (const key in this._config.customParams) {
                params.push(key + '=' + this._config.customParams[key]);
            }
            result += encodeURIComponent((result ? '&' : '') + params.join('&'));
        }

        return result;
    }

    private _computeDescriptionUrl(videoItem: PlaylistEntry): string {
        var descriptionUrl: string;

        if (this._config.descriptionUrl) {
            descriptionUrl = this._config.descriptionUrl;
        } else if (videoItem.redirectUrl && typeof URL === 'function') {
            // Note: URL function is not supported in IE11.
            const absoluteItemUrl = new URL(videoItem.redirectUrl, this.$window.location.href);
            descriptionUrl = absoluteItemUrl.href;
        } else {
            descriptionUrl = this.$window.location.href;
        }

        return descriptionUrl;
    }

    /**
     * Given a list of ad companions from DFP (e), matches them to those in the document as specified in
     *   the config and populates them with their respective content.
     * @param {AdCompanionsOptions} e - the ad companion options.
     */
    public populateAdCompanions(e: AdCompanionsOptions) {
        if (this._config.companionDivs) {
            // Make a copy of the array.
            var unassignedSlots = this._config.companionDivs.slice(0);

            // For each companion slot in the returned ad playlist, find a slot for it in the settings.
            for (var adIndex = 0; adIndex < e.companions.length; adIndex++) {
                for (var slotIndex = 0; slotIndex < unassignedSlots.length; slotIndex++) {
                    if (e.companions[adIndex].height == unassignedSlots[slotIndex].height
                        && e.companions[adIndex].width == unassignedSlots[slotIndex].width) {
                        // Insert the ad and prevent a subsequent companion ad from showing here.
                        this._insertAd(unassignedSlots[slotIndex].id, e.companions[adIndex].resource);
                        unassignedSlots.splice(slotIndex, 1);
                        break;
                    }
                }
            }
        }
    }

    private _insertAd(element: string, resource: string) {
        var div = this.$document.getElementById(element);
        div.innerHTML = resource;
    }
}
