import { Player, PlaylistEntry } from '../player';
import { ComscoreConfig } from './comscoreConfig';
import { ChapterEvent } from './../player';

interface UpdateClipOptions {
    isAd: boolean;
    ad?: AdImpressionOptions;
    looped?: boolean;
}

interface Clip {
    c3: string;
    c4: string;
    c6: string;
    c9: string;
    c16: number;
    ns_st_ci: string; // Content id
    ns_st_cl: number; // Clip length
    ns_st_pu: string; // Publisher Brand Name
    ns_st_pr: string; // Program title (show name)
    ns_st_ep: string; // Episode Title (showname-s01-e01)
    ns_st_st: string; // Station title
    ns_st_tdt: string; // TV Airdate
    ns_st_ddt: string; // Digital Airdate
    ns_st_ge: string; // Genre
    ns_st_sn: string; // Season number
    ns_st_en: string; // Episode number
    ns_st_ce: number; // Complete Episode Flag
    ns_st_ia: number; // Ad Load Flag - does streaming content carry the same ad load that was used during tv airing
    ns_st_ad: string; // is ad
    ns_st_ti: string; // TMS / Gracenote ID
    ns_st_cu: string;
    ns_st_ct: string;
    ns_st_ty: string;
    ns_st_cn: number;
    ns_st_pn: number;
    ns_st_tp: number;
    ns_st_li: number;
    ns_st_de: any;
    // New Comscore Variables 2018/10/23
    ns_st_stc: string; // Channel ID
    ns_st_tpr: string; // Program ID
    ns_st_tep: string; // Episode Number
    // Optional New Comscore Variables 2018/10/23
    ns_st_tm: string; //Time of initial broadcast
    ns_st_cmt: string; // Content Media Type
    ns_st_cdm: string; // Content Distribution Model
}

class StreamsenseContentType {
    public static SHORT_FORM_VOD: string = 'vc11';
    public static LONG_FORM_VOD: string = 'vc12';
    public static LIVE: string = 'vc13';
    public static PREROLL_AD: string = 'va11';
    public static MIDROLL_AD: string = 'va12';
    public static LIVE_AD: string = 'va21';
}

const enum StreamsenseVideoType {
    BROADCAST_TV = 4,
    WEBISODE = 3
}

export class StreamsenseManager {
    private _comscoreURL: string;
    private _currentEvent: any;
    private _videoStarted: boolean = false;
    private _videoSeeking: boolean = false;
    private _wasAd: boolean = false;
    private _clipNumber: number = 0;
    private _contentClipNumber: number = 0;
    private _videoType: StreamsenseVideoType;
    private _videoContentType: string;
    private _videoDuration: number;
    private _videoId: string;
    private _videoFormattedDate: string;
    private _videoFormattedAvailableDate: string;
    private _videoSeasonNumber: string;
    private _videoEpisodeNumber: string;
    private _videoGenre: string;
    private _videoItem: PlaylistEntry;
    private _trackingTitle: string;
    private _adStartTime: number = 0;
    private _adPauseTime: number = 0;
    private _adElapsedTime: number = 0;
    private _currentAd: any;
    private _currentClip: Clip;
    private _missingValue: string = '*null';

    public streamSense: ns_.StreamingAnalytics;

    constructor(private _config:ComscoreConfig, private _player: Player, private _secured: boolean = false) {
        this._config.c1 = this._config.c1 || '2';
        this._config.c4 = this._config.c4 || this._missingValue;
        this._config.c2 = this._config.c2 || '3005670';
        this._config.channel = this._config.channel || this._config.c3;
        this._createSearchUrl();
        this.streamSense = new ns_.StreamingAnalytics({ liveEndpointURL: this._comscoreURL } );
    }

    private _createSearchUrl() {
        const comscoreURL = (this._secured ? 'https://sb' : 'http://b') + '.scorecardresearch.com/p?c1=' + this._config.c1 + '&c2=' + this._config.c2;

        this._log(comscoreURL);
        this._comscoreURL = comscoreURL;
    }

    private _formatEpisodeSeason(prefix:string, value:string = null): string {
        let result: string = value || '';
        if (result) {
            result = prefix + this._pad(result);
        }
        return result;
    }

    private _pad(s:string) {
        return s.length < 2 ? '0' + s : s;
    }

    private _formatDate(d: any) {
        return d.getUTCFullYear() + '-' + this._pad(d.getUTCMonth() + 1) + '-' + this._pad(d.getUTCDate());
    }

    private _generateTrackingTitle(item: PlaylistEntry) {
        let result: string = item.show ? item.show.replace(/[\s]+/g, '') : '';
        const e: string = this._formatEpisodeSeason('E', item.metadata.episodeNumber);
        const s: string = this._formatEpisodeSeason('S', item.metadata.seasonNumber);

        if (s) {
            result += '-' + s;
        }

        if (e) {
            result += '-' + e;
        }

        // If no episode information available, be sure to include the episode title
        if (!e) {
            result += (result ? '-' : '') + item.title.replace(/[\s]+/g, '');
        }

        return result;
    }

    private _start() {
        this._log('_start()');
        this.streamSense.createPlaybackSession();
    }

    private _log(message: string, info?: any) {
        this._player.log('[comscore streamsense]', message, info);
    }

    private _updateClip(options: UpdateClipOptions) {
        if (options.isAd || !this._contentClipNumber) {
            this._clipNumber++;
        }
        if (this._videoFormattedDate === '1970-1-1') {
            this._videoFormattedDate = this._missingValue;
        }
        if (this._videoFormattedAvailableDate === '1970-1-1') {
            this._videoFormattedAvailableDate = this._missingValue;
        }

        const clip:Clip = {
            c16: 1,
            c6: this._trackingTitle,
            c3: this._config.c3,
            c4: this._config.c4,
            c9: this._missingValue,
            ns_st_pu: this._config.c3,
            ns_st_ep: this._videoItem.metadata.ns_st_ep ? this._videoItem.metadata.ns_st_ep : this._trackingTitle,
            ns_st_cu: this._missingValue,
            ns_st_cn: 0,
            ns_st_pn: 1,
            ns_st_tp: this._player.totalChapters,
            ns_st_ty: this._videoType.toString(),
            ns_st_li: this._videoItem.metadata.liveStream ? 1 : 0,
            ns_st_de: this._videoItem.show ? this._videoItem.show : this._config.c3,
            ns_st_ad: '0',
            ns_st_st: this._config.channel,
            ns_st_en: this._videoEpisodeNumber,
            ns_st_ge: this._videoGenre, // content genre
            ns_st_ia: 0, // ad log flag
            ns_st_ce: this._videoItem.metadata.clipType == 'episode' ? 1 : 0,
            ns_st_ddt: this._videoFormattedAvailableDate, // Digital Air date
            ns_st_ti: this._missingValue,
            // Manditory Values VAM
            ns_st_stc: this._config.VAM_station_code ? this._config.VAM_station_code : this._missingValue, // Channel ID
            ns_st_tpr: this._videoItem.metadata.ns_st_tpr ? this._videoItem.metadata.ns_st_tpr : this._missingValue, // Program ID
            ns_st_sn: this._videoItem.metadata.ns_st_sn ? this._videoItem.metadata.ns_st_sn : this._videoSeasonNumber, // Season Number
            ns_st_tep: this._videoItem.metadata.ns_st_tep ? this._videoItem.metadata.ns_st_tep : this._videoEpisodeNumber, // Episode Number
            ns_st_ci: this._videoItem.metadata.ns_st_ci ? this._videoItem.metadata.ns_st_ci : this._videoId, // Content or Asset ID
            ns_st_cl: this._videoItem.metadata.ns_st_cl ? parseInt( this._videoItem.metadata.ns_st_cl ) : this._videoDuration, // Clip Length
            ns_st_ct: this._videoItem.metadata.ns_st_ct ? this._videoItem.metadata.ns_st_ct : this._videoContentType, // Classification Type
            ns_st_pr: this._videoItem.metadata.ns_st_pr ? this._videoItem.metadata.ns_st_pr :
                ( this._videoItem.show ? this._videoItem.show : this._missingValue ), // Program Name
            // Optional Values VAM
            ns_st_tdt: this._videoItem.metadata.ns_st_tdt ? this._videoItem.metadata.ns_st_tdt : this._missingValue, // TV Airdate
            ns_st_tm: this._videoItem.metadata.ns_st_tm ? this._videoItem.metadata.ns_st_tm : this._missingValue, // Time of initial broadcast
            ns_st_cmt: this._videoItem.metadata.ns_st_cmt ? this._videoItem.metadata.ns_st_cmt : this._missingValue, // Content Media Type
            ns_st_cdm: this._videoItem.metadata.ns_st_cdm ? this._videoItem.metadata.ns_st_cdm : this._missingValue // Content Distribution Model
        };

        if (options.isAd) {
            var adLength: number = 0;
            var podType: string = '';
            var adId: string = '';
            var contentType: string = '';

            if ( options.ad.ima && options.ad.ima.ad ) {
                adLength = options.ad.ima.ad.getDuration();
                adId = options.ad.ima.ad.getAdId();
                podType = options.ad.ima.ad.getAdPodInfo().getPodIndex() == 0 ? 'pre-roll' : 'mid-roll';
                contentType =
                    this._videoItem.metadata.liveStream ? StreamsenseContentType.LIVE_AD : (options.ad.ima.ad.getAdPodInfo().getPodIndex() == 0)
                    ? StreamsenseContentType.PREROLL_AD : StreamsenseContentType.MIDROLL_AD;
            } else if ( 'dai' === options.ad.client ) {
                adId = options.ad.id;
                adLength = options.ad.duration;
                podType = 'mid-roll';
                contentType = StreamsenseContentType.LIVE_AD;
            }

            if (this._config.VAM_enabled) {
                clip.ns_st_ad = podType;
            }
            else {
                clip.ns_st_ad = '1';
            }
            clip.ns_st_ty = this._missingValue;
            clip.ns_st_ci = adId;
            clip.ns_st_cl = (adLength > 0) ? this._toMS(adLength) : 0; // milliseconds (must be positive or 0)
            clip.ns_st_pn = 0;
            clip.ns_st_tp = 0;
            clip.ns_st_ct = contentType;
            clip.ns_st_cn = this._clipNumber;
        } else {
            this._contentClipNumber = this._contentClipNumber || this._clipNumber;
            clip.ns_st_cn = this._contentClipNumber;
            clip.ns_st_pn = this._player.currentChapter;
        }

        this._currentClip = clip;
        this.streamSense.getPlaybackSession().setAsset(clip);
        this._wasAd = options.isAd;

        this._log('updateClip | looped = ' + options.looped + ' | ' + (options.isAd ? '*AD*' : '*CONTENT*'), clip);
    }

    private _getTimestamp():number {
        return ( new Date() ).getTime();
    }

    private _toMS(s:number):number {
        return Math.round(s * 1000);
    }

    public setup() {
        this._log('setup', this._player.currentMedia);
        this._videoItem = this._player.currentMedia;

        this._trackingTitle = this._generateTrackingTitle(this._videoItem);
        this._videoType = this._videoItem.metadata.clipType == 'webisode' ? StreamsenseVideoType.WEBISODE : StreamsenseVideoType.BROADCAST_TV;
        this._videoDuration = this._toMS(this._videoItem.metadata.duration); // milliseconds
        this._videoContentType = this._videoItem.metadata.liveStream ? StreamsenseContentType.LIVE : (this._videoItem.metadata.clipType == 'episode' ? StreamsenseContentType.LONG_FORM_VOD : StreamsenseContentType.SHORT_FORM_VOD);
        this._videoId = this._videoItem.mediaId;
        this._videoSeasonNumber = this._videoItem.metadata.seasonNumber ? this._videoItem.metadata.seasonNumber.replace(/^0+/, '') : this._missingValue;
        this._videoEpisodeNumber = this._videoItem.metadata.episodeNumber ? this._videoItem.metadata.episodeNumber.replace(/^0+/, '') : this._missingValue;
        this._videoGenre = this._videoItem.metadata.genre ? this._videoItem.metadata.genre : this._missingValue;
        this._videoFormattedDate = this._formatDate(this._videoItem.metadata.liveStream ? new Date() : new Date(this._videoItem.metadata.airDate));

        const availableDate = this._videoItem.metadata.availableDate ? new Date(this._videoItem.metadata.availableDate) : null;
        // strip out only start of availability window
        // availabilityWindow: "start=2020-07-02T15:48:15+00:00;end=2022-01-01T00:00:00+00:00;scheme=W3C-DTF"
        const availabilityWindow = this._videoItem.metadata.availabilityWindow ? new Date( this._videoItem.metadata.availabilityWindow.split( ';' )[0].replace( 'start=', '' ) ) : new Date();
        this._videoFormattedAvailableDate = this._formatDate(availableDate ? availableDate : availabilityWindow);

        this._start();
    }

    public trackAdStart(e: AdImpressionOptions) {
        this._log('trackAdStart()');
        this._adStartTime = this._getTimestamp();
        this._adElapsedTime = 0;

        if (this._videoStarted && this._currentEvent !== ns_.StreamingAnalytics.PlayerEvents.END) {
            if (!this._wasAd) {
                this._notifyEnd(this._toMS(this._player.currentMediaPosition));
            } else if (this._currentAd) {
                this._notifyEnd(this._toMS(this._getAdDuration()));
            }
        }

        this._currentAd = e.ima && e.ima.ad ? e.ima.ad : e;
        this._videoStarted = true;
        this._updateClip({
            isAd: true,
            ad: e
        });
    }

    public trackAdPause(e: AdPauseResumeOptions) {
        this._log('trackAdPause()');
        this._adElapsedTime = this._getTimestamp() - this._adStartTime;
        this._notifyPause(this._adElapsedTime);
    }

    public trackAdResume(e: AdPauseResumeOptions) {
        this._log('trackAdResume()');

        let elapsedTime = 0;
        if (this._currentEvent == ns_.StreamingAnalytics.PlayerEvents.PAUSE) {
            elapsedTime = this._adElapsedTime;
            this._adStartTime = this._getTimestamp() - elapsedTime;
        }

        this._notifyPlay(elapsedTime);
    }

    public trackAdComplete(e: AdCompleteOptions) {
        this._log('trackAdComplete()');
        var adDuration: number = this._getAdDuration();
        this._notifyEnd( adDuration );
    }

    public trackComplete() {
        this._log('trackComplete()');
        this._notifyEnd(this._toMS(this._player.currentMediaPosition));
    }

    public trackBuffering() {
        if (this._videoStarted && !this._videoSeeking) {
            this._notifyBuffer(this._toMS(this._player.currentMediaPosition));
        }
    }

    public trackSeeking(e: SeekEvent) {
        this._log('trackSeeking()', e);
        this._videoSeeking = true;
        this._notifyPause(this._toMS(e.position));
    }

    public trackSeeked() {
        // The seeked event should only take place when the video has actually started.
        if ( this._videoStarted ) {
            this._log('trackSeeked()');
            this._videoSeeking = false;
            this._notifyPlay(this._toMS(this._player.currentMediaPosition));
        }
    }

    public trackChapterStart(e: ChapterEvent) {
        this._log('trackChapterStart()', e);
        const time: number = this._toMS(this._player.currentMediaPosition);
        if (this._videoStarted && this._currentEvent !== ns_.StreamingAnalytics.PlayerEvents.END) {
            this._notifyEnd(time);
        }

        this._videoStarted = true;
        this._updateClip({ isAd: false });
        this._notifyPlay(time);
    }

    public trackPlay() {
        this._videoSeeking = false;

        if (this._wasAd) {
            this._log('trackPlay() after ad break');

            if (this._currentEvent !== ns_.StreamingAnalytics.PlayerEvents.END) {
                this._notifyEnd(this._toMS(this._getAdDuration()));
            }

            this._updateClip({ isAd: false });
            this._currentAd = null;
        } else {
            if (this._player.currentMedia.metadata.liveStream && (!this._currentClip || this._currentClip.ns_st_ci !== this._videoId)) {
                this._updateClip({ isAd: false });
            }
            this._log('trackPlay() resume paused content');
        }

        if (this._currentEvent !== ns_.StreamingAnalytics.PlayerEvents.PLAY) {
            this._notifyPlay(this._toMS(this._player.currentMediaPosition));
        }
    }

    public trackPause() {
        if (!this._videoSeeking) {
            this._log('trackPause()');
            this._notifyPause(this._toMS(this._player.currentMediaPosition));
        }
    }

    public _notifyBuffer(timeInMS: number) {
        this._currentEvent = ns_.StreamingAnalytics.PlayerEvents.BUFFER;
        this.streamSense.notifyBufferStart(timeInMS);

        this._log('_notifyBuffer() Notify BUFFER', timeInMS );
    }

    public _notifyPause(timeInMS: number) {
        this._currentEvent = ns_.StreamingAnalytics.PlayerEvents.PAUSE;
        this.streamSense.notifyPause(timeInMS);

        this._log('_notifyPause() Notify PAUSE | ' + new Date(), timeInMS );
    }

    private _notifyPlay(timeInMS: number) {
        this._currentEvent = ns_.StreamingAnalytics.PlayerEvents.PLAY;
        this.streamSense.notifyPlay(timeInMS);

        this._log('_notifyPlay() Notify PLAY | ' + new Date(), timeInMS );
    }

    private _notifyEnd(timeInMS: number) {
        this._currentEvent = ns_.StreamingAnalytics.PlayerEvents.END;
        this.streamSense.notifyEnd(timeInMS);

        this._log('_notifyEnd() Notify END ', timeInMS);
    }

    private _getAdDuration() {
        if ( this._currentAd ) {
            if ( 'dai' === this._currentAd.client ) {
                return this._currentAd.duration;
            } else {
                return this._currentAd.getDuration();
            }
        }

        return 0;
    }

    public reset() {
        this._log('reset()');
        this._videoStarted = false;
        this._currentEvent = '';
        this._clipNumber = 0;
        this._contentClipNumber = 0;
        this._currentClip = null;
    }
}
