import { DRMServiceConfig, DRMPolicy, WidevineConfig, PlayreadyConfig, FairplayConfig } from './drm.types';
import { UtilsService, XhrService } from '..';
import { PlaylistEntry } from '../../components/player';
import { EventHandler } from '../../components/events';

export class DRMService {
    protected _config: DRMServiceConfig;

    protected PLAYREADY_LICENSE_URL: string = 'https://playready-license.drm.technology/rightsmanager.asmx?token={token}';
    protected WIDEVINE_LICENSE_URL: string = 'https://widevine-proxy.drm.technology/proxy';
    protected FAIRPLAY_LICENSE_URL: string = 'https://fairplay-license.drm.technology/license';
    protected FAIRPLAY_CERTIFICATE_URL: string = 'https://cdn.vuplay.co.uk/corus/corus-fairplay.cer'; // 'https://fairplay-license.drm.technology/certificate'

    public drmHandled = new EventHandler<DRMService, any>();

    public constructor(
        config?: DRMServiceConfig,
        protected _xhr: XhrService = new XhrService(),
        protected _utils: UtilsService = UtilsService.Instance
    ) {
        if (config) {
            this.setup(config);
        }
    }

    public setup(config: DRMServiceConfig) {
        this._config = {
            tokenAPIUrl: config.tokenAPIUrl,
            playreadyLicenseUrl: config.playreadyLicenseUrl ? config.playreadyLicenseUrl : this.PLAYREADY_LICENSE_URL,
            widevineLicenseUrl: config.widevineLicenseUrl ? config.widevineLicenseUrl : this.WIDEVINE_LICENSE_URL,
            fairplayCertificateUrl: config.fairplayCertificateUrl ? config.fairplayCertificateUrl : this.FAIRPLAY_CERTIFICATE_URL,
            fairplayLicenseUrl: config.fairplayLicenseUrl ? config.fairplayLicenseUrl : this.FAIRPLAY_LICENSE_URL
        };
    }

    public handleDRM(entry: PlaylistEntry): void {
        // Set policy value and retrieve DRM token
        var url: string = this._generateTokenApiUrl(entry);

        this._xhr.getJson(
            url,
            (success: boolean, status: number, data: any) => {
                this._log('Retrieve DRM token', { success: success, data: data });
                if (success) {
                    // Apply DRM config with token value
                    this._applyDRMConfig(entry, data.token);
                    // Fire drmHandled event
                    this.drmHandled.fire(this, { entry: entry });

                } else {
                    throw new Error('Failed to get DRM token: ' + url);
                }
            }
        );
    }

    // private methods

    protected _generateTokenApiUrl(entry: PlaylistEntry) {
        var policy: DRMPolicy = {};
        var url: string = this._config.tokenAPIUrl.replace(/{policy}/, JSON.stringify(policy));
        return url;
    }

    protected _applyDRMConfig(entry: PlaylistEntry, drmToken: string): void {
        const { sources } = entry;
        for (let i = 0; i < sources.length; i++) {
            switch (sources[i].type) {
                case 'dash':
                    this._setWidevineConfig(sources[i], drmToken);
                    this._setPlayreadyConfig(sources[i], drmToken);
                    break;

                case 'hls':
                    this._setFairplayConfig(sources[i], entry.mediaId, drmToken);
                    break;
            }
        }
    }

    protected _setWidevineConfig(source: VideoSource, drmToken: string): void {
        var config: WidevineConfig = {
            url: this._config.widevineLicenseUrl,
            licenseRequestHeaders: [{
                name: 'Content-Type',
                value: 'application/json'
            }],
            licenseRequestFilter: function (request: any, drmInfo: any) {
                // select the first key id and convert to uppercase as it is hex.
                var keyId = drmInfo.keyIds[0].toUpperCase();

                // create the license request body required by the license server
                var body = JSON.stringify({
                    token: drmToken,
                    drm_info: Array.apply(null, new Uint8Array(request.body)),
                    kid: keyId
                });

                // set the request body
                request.body = body;
            }
        };

        source.drm = source.drm || {};
        source.drm.widevine = config;
    }

    protected _setPlayreadyConfig(source: VideoSource, drmToken: string): void {

        var config: PlayreadyConfig = {
            url: this._config.playreadyLicenseUrl.replace(/{token}/, encodeURIComponent(drmToken))
        };

        source.drm = source.drm || {};
        source.drm.playready = config;
    }

    protected _setFairplayConfig(source: VideoSource, contentId: string, drmToken: string): void {

        var config: FairplayConfig = {
            certificateUrl: this._config.fairplayCertificateUrl,
            processSpcUrl: this._config.fairplayLicenseUrl,
            extractContentId: () => { return contentId; },
            licenseRequestHeaders: [{
                name: 'Content-Type',
                value: 'application/json'
            }],
            licenseResponseType: 'arraybuffer',
            licenseRequestMessage: (keyMessage: string) => {
                var body: any = {
                    token: drmToken,
                    contentId: contentId,
                    payload: this._base64EncodeUint8Array(keyMessage)
                };
                return JSON.stringify(body);
            }
        };

        source.drm = source.drm || {};
        source.drm.fairplay = config;
    }

    // This is a utility method used to convert the Uint8Array key message into a base64 encoded string used as the payload in the license request.
    protected _base64EncodeUint8Array(input: string): string {
        var keyStr: string = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';
        var output: string = '';
        var chr1: any, chr2: any, chr3: any;
        var enc1: number, enc2: number, enc3: number, enc4: number;
        var i: number = 0;

        while (i < input.length) {
            chr1 = input[i++];
            chr2 = i < input.length ? input[i++] : Number.NaN;
            chr3 = i < input.length ? input[i++] : Number.NaN;

            enc1 = chr1 >> 2;
            enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
            enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
            enc4 = chr3 & 63;

            if (isNaN(chr2)) {
                enc3 = enc4 = 64;
            } else if (isNaN(chr3)) {
                enc4 = 64;
            }

            output += keyStr.charAt(enc1) + keyStr.charAt(enc2) +
                keyStr.charAt(enc3) + keyStr.charAt(enc4);
        }

        return output;
    }

    protected _log(message: string, info?: any) {
        this._utils.log('DRMService', message, info);
    }
}
