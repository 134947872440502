import { ErrorMessageModel, ErrorMessageComponent } from '../errorMessage';
import { Player, Render } from '../player';
import { UtilsService, OSVersion } from '../../services';
import { EventHandler } from '../events';

export interface DRMComponentConfig {
    generic?: ErrorMessageModel,
    ios?: ErrorMessageModel,
    android?: ErrorMessageModel,
}

export enum DRMErrorType {
    PLATFORM_NOT_SUPPORTED = 0,
    PLAYBACK_FAILED = 1
}

export class DRMComponent {
    private _player: Player;
    private _playbackStarted: boolean = false;

    public drmFailed = new EventHandler<DRMComponent, any>();

    private DRM_MESSAGE_MODEL: ErrorMessageModel = {
        title: 'Sorry, Playback Is Unavailable',
        message: '<p>DRM not supported</p>'
    };

    public constructor( private _container: string,
                        private _config: DRMComponentConfig,
                        private $window: Window = window,
                        private _utils: UtilsService = UtilsService.Instance) {
    }

    public bindTo(player: Player): void {
        this._player = player;
        player.playbackFailed.subscribe((caller, e) => this._onPlaybackFailed(caller, e));
        player.playlistLoaded.subscribe((caller, e) => this._onPlaylistLoaded(caller, e));
        player.playbackStarted.subscribe((caller, e) => this._onPlaybackStarted(caller, e));
    }

    public getErrorModel():ErrorMessageModel {
        let model: ErrorMessageModel = this._config.generic ? this._config.generic : this.DRM_MESSAGE_MODEL;
        if (this._utils.isAndroid()) {
            model = this._config.android ? this._config.android : model;
        } else if (this._utils.isIOS()) {
            model = this._config.ios ? this._config.ios : model;
        }

        model.image = model.image ? model.image : this._player.currentMediaData.image;
        model.blockPlayerOnly = true;

        return model;
    }

    private _onPlaylistLoaded(caller: Player, e: any): void {
        if (!caller.isRemoved && caller.currentMediaData.isDRM && !this.isDRMSupported()) {
            this._log('DRM not supported on iOS, Android, MacOS 10.10 and below, Windows 7 IE/Firefox');
            this.drmFailed.fire(this, { type: DRMErrorType.PLATFORM_NOT_SUPPORTED });
        }
    }

    private _onPlaybackStarted(caller: Player, e: any): void {
        this._playbackStarted = true;
    }

    private _onPlaybackFailed(caller: Player, e: any): void {
        // Only surface DRM error message when playback has not been started.
        // If playback has already started, chances are decryption was successful, but we encountered a different error
        if (!caller.isRemoved && !this._playbackStarted && caller.currentMediaData.isDRM) {
            this._log('DRM error');
            this.drmFailed.fire(this, { type: DRMErrorType.PLAYBACK_FAILED });
        }
    }

    public isDRMSupported(): boolean {
        var result: boolean = !this._utils.isAndroid();

        if (this._utils.isIOS()) {
            var iosVersion: OSVersion = this._utils.getIOSVersion();
            result = iosVersion.major > 11 || ( iosVersion.major == 11 && iosVersion.minor >= 2 );
        }

        if (this._utils.isMac()) {
            var macVersion: OSVersion = this._utils.getMacOSVersion();
            result = macVersion.minor > 10 || macVersion.major > 10;
        }

        if (this._utils.isWindows() && ( this._utils.isIE() || this._utils.isFirefox() ) ) {
            var winVersion: OSVersion = this._utils.getWindowsVersion();
            result = winVersion.major > 7;
        }

        return result;
    }

    private _log(message: string, info?: any) {
        this._utils.log('DRMComponent', message, info);
    }
}
