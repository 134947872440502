import { Player, PlaylistEntry } from '../index';
import { UtilsService } from '../../services';

export class NextUpComponent {
    private _playerContainer: HTMLElement;
    private _nextUpTime: number;
    private _rendered: boolean;

    private NEXT_UP_LINK_CLASS: string = 'jw-nextUp-link';

    private get title(): HTMLElement { return <HTMLElement> this._playerContainer.querySelector('.jw-nextup-title'); }
    private get component(): HTMLElement { return <HTMLElement> this._playerContainer.querySelector('.jw-nextup'); }
    private get thumbnail(): HTMLElement { return <HTMLElement> this._playerContainer.querySelector('.jw-nextup-thumbnail'); }

    constructor(private _player: Player,
                private $document: Document = document,
                private _util: UtilsService = UtilsService.Instance,) {
        this._playerContainer = this._player.playerContainer;
        this._nextUpTime = this._player.currentMediaDuration + this._player.config.jwOptions.nextupoffset;
        this._log('nextup time', this._nextUpTime);
    }

    public isTime(currentTime: number): boolean {
        return (currentTime >= this._nextUpTime) && !this._rendered;
    }

    public render(): void {
        var media = <PlaylistEntry> this._player.getNextPlaylistItem();
        if (!media) {
            media = this._player.currentMediaData;
        }

        var linkNode: any = this.$document.createElement('div');
        linkNode.setAttribute('class', this.NEXT_UP_LINK_CLASS);
        linkNode.addEventListener('click', (e: Event) => this._playNext(), false);
        this.component.appendChild(linkNode);

        this._log('nextup', media);
        this._player.nextUp.fire(this._player, { nextMedia: media });
        this.title.innerHTML = media.show + '<br />' + media.title;
        this.thumbnail.style.backgroundImage = 'url(\'' + media.thumbnail ? media.thumbnail : media.image + '\')';

        this._rendered = true;
    }

    private _playNext():void {
        this._player.playNext(true);
    }

    private _log(message: string, info?: any) {
        this._util.log('NextUpComponent', message, info);
    }
}
