import { OverlayTemplate } from './index';
import { LocalStorageService } from '../../services';
import { Player, Render } from '../index';


export class OverlayComponent {

    private _eventsRegistered: boolean = false;

    public get overlayElement(): HTMLElement { return <HTMLElement> this._player.playerContainer.querySelector('.welcomeBackOverlay'); }
    private get _resumeButton(): HTMLElement { return <HTMLElement> this._player.playerContainer.querySelector('.btn-resume'); }
    private get _restartButton(): HTMLElement { return <HTMLElement> this._player.playerContainer.querySelector('.btn-restart'); }

    constructor(
        private _player: Player,
        private $document: Document = document,
        private $render: any = Render) { }

    public render() {
        if (!this.overlayElement) {
            const overlayElement: string = this.$render(OverlayTemplate);
            this._player.jwOverlays.insertAdjacentHTML('afterend', overlayElement);
            this._player.iconContainer.setAttribute('style', 'display:none');
            this._registerEventListeners();
        }
    }

    public removeOverlay() {
        if (this.overlayElement) {
            this._removeEventListeners();
            this.overlayElement.parentElement.removeChild(this.overlayElement);
        }
    }

    private _registerEventListeners() {
        if (!this._eventsRegistered) {
            this._restartButton.addEventListener('click', this.restartPlayback, false);
            this._restartButton.addEventListener('keyup', (e) => { var key = e.which || e.keyCode; if (key === 13) { this.restartPlayback(); } });

            this._resumeButton.addEventListener('click', this.resumePlayback, false);
            this._resumeButton.addEventListener('keyup', (e) => { var key = e.which || e.keyCode; if (key === 13) { this.resumePlayback(); } });
            this._eventsRegistered = true;
        }
    }

    private _removeEventListeners() {
        if (this._eventsRegistered) {
            this._resumeButton.removeEventListener('click', this.resumePlayback, false);
            this._resumeButton.removeEventListener('keyup', this.resumePlayback, false);
            this._restartButton.removeEventListener('click', this.restartPlayback, false);
            this._restartButton.removeEventListener('keyup', this.restartPlayback, false);
            this._eventsRegistered = false;
        }
    }

    public resumePlayback = () => {
        this.removeOverlay();
        this._player.resume();
    };

    public restartPlayback = () => {
        this.removeOverlay();
        this._player.restart();
    };
}
