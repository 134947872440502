import { Player } from '../player';
import { UtilsService, ConcurrencyService } from '../../services';

export class ConcurrencyComponent {
    private _concurrencyService: ConcurrencyService;

    public constructor( private $window: Window = window,
                        private _utils: UtilsService = UtilsService.Instance) {
    }

    public bindTo(player: Player): void {
        player.ready.subscribe((caller, e) => this.onVideoReady(caller, e));
        player.completed.subscribe((caller, e) => this.onVideoCompleted(caller, e));
        player.videoUnload.subscribe((caller, e) => this.onVideoUnload(caller, e));
        this._concurrencyService = ConcurrencyService.Instance;
    }

    private onVideoReady(caller: Player, e: any): void {
        this._log('onVideoReady()');
        if (this._concurrencyService.initialized && !this._concurrencyService.started) {
            this._concurrencyService.start();
        }
    }

    private onVideoCompleted(caller: Player, e: any): void {
        this._log('onVideoCompleted()');
        if (this._concurrencyService.initialized) {
            this._concurrencyService.stop();
        }
    }

    private onVideoUnload(caller: Player, e: any): void {
        this._log('onVideoUnload()');
        if (this._concurrencyService.initialized) {
            this._concurrencyService.stop();
        }
    }

    private _log(message: string, info?: any) {
        this._utils.log('ConcurrencyComponent', message, info);
    }
}
