import { Player, PlaylistEntry, Render } from '../player';
import { ErrorMessageModel, ErrorMessageComponent } from '../errorMessage';
import { EventHandler } from '../events';
import { AuthenticationComponentConfig, AuthenticationComponent } from '../auth';
import { UtilsService, ContentRestrictionService, ContentRestrictionType, ContentRestrictionEvent } from '../../services';
import { DRMComponent, DRMErrorType, ConcurrencyComponent, AuthorizationComponent, AuthorizationComponentConfig } from '.';

export interface ContentRestrictionComponentConfig {
    container?: string,
    drm?: any,
    geoBlock?: ErrorMessageModel,
    concurrency?: ErrorMessageModel,
    authentication?: AuthenticationComponentConfig,
    authorization ?: AuthorizationComponentConfig,
}

export class ContentRestrictionComponent {
    private MESSAGE_CSS_CLASS: string = 'jwplayer-messaging';
    private _DRMComponent: DRMComponent;
    private _errorBroadcasted: boolean = false;
    private get _containerElement():HTMLElement { return <HTMLElement> this.$window.document.querySelector(this._config.container); }
    private get _messageContainerElement():HTMLElement { return <HTMLElement> this._containerElement.querySelector('.' + this.MESSAGE_CSS_CLASS); }

    private GEO_BLOCK_MESSAGE_MODEL: ErrorMessageModel = {
        title: 'Sorry, Playback Is Unavailable',
        message: '<p>This content can only be viewed from within Canada.</p><p>If you believe you are seeing this message in error, please connect to a different network and try refreshing your browser.<br />Contact us if this problem persists.</p>'
    };

    private CONCURRENCY_MESSAGE_MODEL: ErrorMessageModel = {
        title: 'Sorry, Playback Is Unavailable',
        message: '<p>Your account is in use on too many devices. Please stop playing on another device to continue.</p>'
    };

    public contentPrepared = new EventHandler<ContentRestrictionComponent, any>();
    public contentFailed = new EventHandler<ContentRestrictionComponent, any>();

    public constructor( private _player: Player,
                        private _contentRestrictionService: ContentRestrictionService,
                        private _config: ContentRestrictionComponentConfig,
                        private $window: Window = window,
                        private _utils: UtilsService = UtilsService.Instance) {

        this._bindTo(this._player);
        this._contentRestrictionService.restrictionHandled.subscribe((caller, e) => this._onRestrictionHandled(caller, e));
    }

    private _bindTo(player: Player): void {
        this._player = player;

        var concurrency: ConcurrencyComponent = new ConcurrencyComponent();
        concurrency.bindTo(player);

        if (this._config.authentication) {
            var authSubscriber: AuthenticationComponent = new AuthenticationComponent(this._config.container, this._config.authentication, this._contentRestrictionService.authenticationService);
            authSubscriber.bindTo(player);
            var authorizationSubscriber: AuthorizationComponent = new AuthorizationComponent(this._config.container, this._config.authorization);
            authorizationSubscriber.bindTo(player);
        }

        if (this._config.drm) {
            this._DRMComponent = new DRMComponent(this._config.container, this._config.drm);
            this._DRMComponent.bindTo(player);
            this._DRMComponent.drmFailed.subscribe((caller, e) => this._onDRMFailed(caller, e));
        }

        this._player.playlistLoaded.subscribe((caller, e) => this._onPlaylistLoaded(caller, e));
    }

    private _onDRMFailed(caller: DRMComponent, e: any): void {
        new ErrorMessageComponent(this._config.container, this._DRMComponent.getErrorModel(), this._player.config.jwOptions.skin.name);
        this._broadcastError(ContentRestrictionType.DRM);
    }

    private _onPlaylistLoaded(caller: Player, e: any): void {
        // Player in error state, do nothing.
        if (caller.isRemoved) {
            return;
        }

        if (caller.currentMediaData && caller.currentMediaData.sources) {
            var targetSource: VideoSource = caller.currentMediaData.sources[0];
            caller.currentMediaData.sources.map((source: VideoSource) => {
                if (this._getSupportedFormat() === source.type) {
                    targetSource = source;
                }
            });
            caller.currentMediaData.sources = [targetSource];
            this._log('selected source type: ', targetSource.type);

            if (!this._errorBroadcasted ) {
                this._contentRestrictionService.handleRestriction(caller.currentMediaData, this._player.playerId);
            }
        } else if (caller.currentMediaData && caller.currentMediaData.error) {
            // Handle VMS Realtime Services errors.
            let exception: ContentRestrictionType = ContentRestrictionType.Others;
            if (!this._errorBroadcasted) {
                switch (caller.currentMediaData.error) {
                    case 'authenticationError':
                        exception = ContentRestrictionType.Authentication;
                        break;
                    case 'authorizationError':
                        exception = ContentRestrictionType.Authorization;
                        break;
                    case 'geolocationError':
                        exception = ContentRestrictionType.GeoLocation;
                        break;
                    case 'concurrencyError':
                        exception = ContentRestrictionType.Concurrency;
                        break;
                    case 'videoNotFoundError':
                        this._player.remove('video not found');
                        this._player.displayVideoNotFound();
                        return;
                }
                // Sort of hacky, to get Realtime Services working. This whole component should maybe be split out for VMS.
                this._onRestrictionHandled(null, {
                    entry: caller.currentMediaData,
                    exception,
                });
            }
        } else {
            this._broadcastError(ContentRestrictionType.Others);
        }
    }

    private _getSupportedFormat(): string {
        var format: string = 'hls';

        if (this._player.currentMediaData.isDRM) {
            // DRM content defaults to dash format, unless on Safari
            if (!this._utils.isSafari()) {
                format = 'dash';
            }
        }

        return format;
    }

    private _onRestrictionHandled(caller: ContentRestrictionService, e: ContentRestrictionEvent): void {
        if (this._errorBroadcasted === true) {
            return;
        }
        this._log('onRestrictionHandled', e);

        switch (e.exception) {
            case ContentRestrictionType.GeoLocation:
                this._player.remove('Content geo blocked');

                const geoBlockError: ErrorMessageModel = this._config.geoBlock ? this._config.geoBlock : this.GEO_BLOCK_MESSAGE_MODEL;
                geoBlockError.image = this._player.currentMediaData.image;
                geoBlockError.blockPlayerOnly = true;

                new ErrorMessageComponent(this._config.container, geoBlockError, this._player.config.jwOptions.skin.name);
                this._broadcastError(e.exception);
                break;

            case ContentRestrictionType.Concurrency:
                const concurrencyError: ErrorMessageModel = this._config.concurrency ? this._config.concurrency : this.CONCURRENCY_MESSAGE_MODEL;
                concurrencyError.image = this._player.currentMediaData.image;
                concurrencyError.blockPlayerOnly = true;

                new ErrorMessageComponent(this._config.container, concurrencyError, this._player.config.jwOptions.skin.name);
                this._broadcastError(e.exception);
                break;

            case ContentRestrictionType.Authentication:
                this._log('user not authenticated');
                this._player.unAuthUserBlocked.fire(this._player, Player.UNAUTH_USER_BLOCK_EVENT);
                this._broadcastError(e.exception);
                break;

            case ContentRestrictionType.DRM:
                if (this._DRMComponent) {
                    this._log('DRM error');
                    new ErrorMessageComponent(this._config.container, this._DRMComponent.getErrorModel(), this._player.config.jwOptions.skin.name);
                    this._broadcastError(e.exception);
                }
                break;

            case ContentRestrictionType.Authorization:
                this._log('user not authorized');
                this._player.unauthorizedResource.fire(this._player, Player.UNAUTHORIZED_RESOURCE_EVENT);
                this._broadcastError(e.exception);
                break;

            default:
                this.contentPrepared.fire(this, {});
                break;
        }

    }


    private _broadcastError(type: ContentRestrictionType) {
        if (!this._errorBroadcasted) {
            this.contentFailed.fire(this, { exception: type });
            this._errorBroadcasted = true;
        }
    }

    private _log(message: string, info?: any) {
        this._utils.log('ContentRestrictionComponent', message, info);
    }
}
