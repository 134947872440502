import { PlayerSubscriber, Player } from './player';
import { EventHandler } from './events';

export interface MediaInfo {
    title: string,
    description: string,
    image: string,
    thumbnail: string,
    id: string,
    metadata: MediaMetadata
}

export class VideoPlayerSubscriber implements PlayerSubscriber {
    public playlistItemChanged = new EventHandler<VideoPlayerSubscriber, any>('', this.$window);

    public constructor(private $window: Window = window) {}

    public bindTo(player: Player): void {
        player.playlistItemChanged.subscribe((caller, e) => this.onPlaylistItemChanged(caller, e));
    }

    private onPlaylistItemChanged(caller: Player, e: any): void {
        var mediaItem = caller.currentMedia;
        var info: MediaInfo = {
            title: mediaItem.title,
            description: mediaItem.description,
            image: mediaItem.image,
            thumbnail: mediaItem.thumbnail,
            id: mediaItem.mediaId,
            metadata: mediaItem.metadata
        };
        this.playlistItemChanged.fire( this, info );
    }
}
