interface MediaItem {
    mediaId: string,
    value: number,
    chapter?: number,
    isCompleted?: boolean,
    percentage?: number,
    timestamp: number,
}

export class LocalStorageService {

    private static _instance: LocalStorageService;
    private _items: MediaItem[] = null;
    private _limit: number = 50;
    public static id = '[Local Storage Service]';

    constructor(private $window: Window = window) {
        $window.addEventListener('pagehide', () => this.save());
    }

    public static get Instance(): LocalStorageService {
        if (!LocalStorageService._instance) {
            LocalStorageService._instance = new LocalStorageService();
        }
        return LocalStorageService._instance;
    }

    public config(limit: number) {
        this._limit = limit;
    }

    public setCompleted(mediaId: string, status: boolean = false) {
        this._items.forEach((media) =>{
            if (mediaId == media.mediaId) {
                if (status) media.value = 0;
                media.isCompleted = status;
            }
        });
    }

    public get items() {
        if (this._items === null) {
            try {
                var storageItems = localStorage.getItem(LocalStorageService.id);
            } catch ( e ) {
                console.log('localstorage is not available on this browser.');
            }
            if (storageItems === 'null') {
                storageItems = null;
            }
            this._items = JSON.parse(storageItems || '[]');
        }
        return this._items;
    }

    public get = (mediaId: string): MediaItem => {
        let storageItem: any = null;
        for (var i = 0; i < this.items.length; i++) {
            if (mediaId === this.items[i].mediaId)
                storageItem = this.items[i];
        }
        return storageItem;
    };

    public put = (media: MediaItem) => {
        var itemExists = false;
        this.items.forEach((item: any, index: number) => {
            if (media.mediaId === item.mediaId) {
                itemExists = true;
                item.value = media.value;
                item.chapter = media.chapter;
                item.timestamp = Date.now();
                item.percentage = media.percentage;
                if (index !== this.items.length - 1) this._move(index);
            }
        });
        if (!itemExists) {
            this._items.push({
                mediaId: media.mediaId,
                value: media.value,
                chapter: media.chapter,
                timestamp: Date.now(),
                percentage : media.percentage
            });
            this._items = this._limitItems(this.items);
        }
        this.setCompleted(media.mediaId, false);
    };

    private _limitItems(items: MediaItem[]): MediaItem[] {
        if (items.length > this._limit) {
            return items.slice((items.length - this._limit), items.length);
        }
        return items;
    }

    private _move = (moveFrom: number) => {
        const moveTo = this.items.length - 1;
        this.items.splice(moveTo, 0, this.items.splice(moveFrom, 1)[0]);
    };

    public remove = (mediaId: string) => {
        for (var i = 0; i < this.items.length; i++) {
            if (mediaId === this.items[i].mediaId) {
                this.items.splice(i, 1);
            }
        }
    };

    public removeAll = () => {
        this._items = [];
    };

    public save(): void {
        if (this._items) {
            localStorage.setItem(LocalStorageService.id, JSON.stringify(this._items));
        }
    }

    public saveData(id: string, data: string): void {
        localStorage.setItem(id, data);
    }

    public getDataById(id: string): string {
        var result: string = localStorage.getItem(id);
        return result;
    }

    public deleteDataById(id: string): void {
        localStorage.removeItem(id);
    }
}
