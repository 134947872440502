export class DateFormatter {
    public formatTimeRange(time: number, increment: number = 3, limit: number = 30): string {
        var seconds: number = time / 1000;
        var result: string;

        if (seconds >= limit) {
            result = `${limit}+`;
        }
        else {
            const multiplier: number = Math.floor(seconds / increment);
            const low: number = multiplier * increment;
            const high: number = low + increment;
            result = `${low}-${high}`;
        }

        return result;
    }
}

export class DateProvider {
    public getTime(): number {
        var date: Date = new Date();
        var result: number = date.getTime();
        return result;
    }
}
