import { Player, PlayerSubscriber, Render } from './player';

export class GeoBlockingSubscriber implements PlayerSubscriber {

    public constructor(container: string, $render: any, $window: Window) {
    }

    public bindTo(player: Player): void {
        // obscelete
        player.log('GeoBlockingSubscriber obsceleted.  Do nothing');
    }
}
