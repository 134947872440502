import { Player, PlayerSubscriber, Render } from '../player';
import { UtilsService, AuthenticationService } from '../../services';

const AuthMessageTemplate = require('../auth/authMessage.html');

interface AuthorizationMessageModel {
    image: string,
    message: string,
    skin: string,
    title: string,
    ctaLabel: string
}

export interface AuthorizationComponentConfig {
    siteAuth: any,
    signInUrl: string,
    title?: string,
    message?: string,
    ctaLabel?: string,
    href ?: string,
}

export class AuthorizationComponent {
    private _messageContainer: string = 'jwplayer-auth-messaging';
    private _isInitialized: boolean = false;
    private _player: Player;

    private get _containerElement():HTMLElement { return <HTMLElement> this.$window.document.querySelector(this._container); }
    private get _messageContainerElement():HTMLElement { return <HTMLElement> this.$window.document.querySelector('.' + this._messageContainer); }

    public constructor( private _container: string,
                        private _config: AuthorizationComponentConfig,
                        private $render: any = Render,
                        private $window: Window = window,
                        private _utils: UtilsService = UtilsService.Instance) {
        var element = document.createElement('div');
        element.classList.add(this._messageContainer);
        this._containerElement.appendChild(element);
    }

    public bindTo(player: Player): void {
        player.unauthorizedResource.subscribe((caller, e) => this.onException(caller, e));
        this._player = player;
    }

    private onException(caller: Player, e: any): void {
        this._log('onException', e);

        let skin: string = null;
        if (caller.config.jwOptions.skin.name) {
            skin = 'jw-skin-' + caller.config.jwOptions.skin.name;
        }

        this.render(caller.currentMediaData.image, skin, e);
    }

    private render(image: string, skin: string, exception: string = ''): void {
        if (this._messageContainerElement) {
            const authorizationMessageModel: AuthorizationMessageModel = {
                image: image,
                message: this._config.message ? this._config.message : 'TV Service provider subscription needed to watch this episode',
                skin: skin,
                title: this._config.title ? this._config.title : 'You\'re almost there!',
                ctaLabel: this._config.ctaLabel ? this._config.ctaLabel : 'More Info'
            };
            const html = this.$render(AuthMessageTemplate, authorizationMessageModel);
            this._messageContainerElement.addEventListener('click', (e: Event) => window.open(this._config.href));
            this._messageContainerElement.innerHTML = html;
        }
    }

    private _log(message: string, info?: any) {
        this._utils.log('AuthorizationComponent', message, info);
    }
}
