import { UtilsService, XhrService } from '..';
import { Player } from '../../components/player';
import { EventHandler } from '../../components/events';

export interface Location {
    lat?: number,
    long?: number,
    city?: string,
    region?: string,
    country_code?: string,
    zip?: string;
    [key: string]: string | number;
}

export interface GeoContent {
    id: string,
    title: string,
    location: Location
}

export interface GeoContentDetectionConfig {
    feedUrl?: string,
    locationCookieName?: string,
    userLocation?: Location
}

export class GeoContentDetectionService {
    private _geoContent: GeoContent[];
    private _userLocation: Location;
    private _detected: boolean = false;
    private DEFAULT_LOCATION_STRING: string = 'lat=43.67|long=-79.42|city=TORONTO|country_code=CA|region=ON|zip=M3H+M3M+M4B+M4C+M4E+M4G+M4H+M4J+M4K+M4L+M4M+M4N+M4P+M4R+M4S+M4T+M4V+M4W+M4X+M4Y+M5A+M5B+M5C+M5E+M5G+M5H+M5J+M5K+M5L+M5M+M5N+M5P+M5R+M5S+M5T+M5V+M5W+M5X+M6A+M6B+M6C+M6E+M6G+M6H+M6J+M6K+M6L+M6M+M6N+M6P+M6R+M6S+M7A+M7Y+M9M+M9N+M9P+M9W';

    public contentDetected = new EventHandler<GeoContentDetectionService, GeoContent>();
    public get detected(): boolean { return this._detected; }

    constructor(private _config: GeoContentDetectionConfig = null,
                private _util: UtilsService = UtilsService.Instance,
                private _xhrService: XhrService = new XhrService) {
    }

    public initialize(config: GeoContentDetectionConfig) {
        this._config = config;

        var locationDataUrl: string = this._config.feedUrl + '?form=cjson&fields=id,title,:latitude,:longitude';
        this._detectUserLocation(config);

        this._xhrService.getJson(
            locationDataUrl,
            (success: boolean, status: number, data: any) => {
                if (data && data.entries) {
                    this._geoContent = [];
                    for (let i: number = 0; i < data.entries.length; i++) {
                        const item:any = data.entries[i];
                        const geoContent = {
                            id: item.id.replace(/.+\//, ''),
                            title: item.title,
                            location: {
                                lat: item.pl1$latitude,
                                long: item.pl1$longitude
                            }
                        };
                        this._geoContent.push(geoContent);
                    }

                    this._log('All locations', this._geoContent);

                    const selectedContent = this._getClosestContent(this._geoContent, this._userLocation);
                    this._log('Selected location', selectedContent);
                    this.contentDetected.fire(this, selectedContent);

                } else {
                    this._log('ERROR', 'Failed to get geo location cotnent');
                }
            }
        );

        this._detected = true;
    }

    // Private Methods

    private _detectUserLocation(config: GeoContentDetectionConfig) {
        var userLocationString: string = this._util.readCookie(config.locationCookieName);
        if (config.userLocation) {
            this._userLocation = config.userLocation;
        } else if (!userLocationString) {
            userLocationString = this.DEFAULT_LOCATION_STRING;
            this._log('Location not avaialble, use default');
        }

        if (!this._userLocation) {
            this._userLocation = this._parseGeoLocationCookie(decodeURIComponent(userLocationString));
        }

        this._log('User location', this._userLocation);
    }

    private _getClosestContent(contentList: GeoContent[], targetLocation: Location): GeoContent {
        var selectedContent: GeoContent = contentList[0];
        var shortestDistance: number = 999999999999; // start with a high value so we can go through the for loop.
        var deltaDistance: number;

        for (let i:number = 0; i < contentList.length; i++) {
            deltaDistance = this._getDistance(targetLocation, contentList[i].location);
            if (deltaDistance < shortestDistance) {
                shortestDistance = deltaDistance;
                selectedContent = contentList[i];
            }
        }

        return selectedContent;
    }

    private _parseGeoLocationCookie(locationString: string): Location {
        var location: Location = {};

        if (locationString) {
            var geoDataArray: string[] = locationString.split('|');
            for (let i:number = 0; i < geoDataArray.length; i++) {
                const keyValPair: string[] = geoDataArray[i].split('=');
                location[keyValPair[0]] = keyValPair[1];
            }
        }

        return location;
    }

    private _deg2rad(degrees: number): number {
        return degrees * (Math.PI / 180);
    }

    private _getDistance(location_1: Location, location_2: Location): number {
        // Uses Haversine Formula
        var r = 6371; // diameter of earth, in kilometers
        var deltaLat, deltaLong, lat1, lat2, a, c;

        deltaLat = this._deg2rad(location_2.lat - location_1.lat);
        deltaLong = this._deg2rad(location_2.long - location_1.long);
        lat1 = this._deg2rad(location_1.lat);
        lat2 = this._deg2rad(location_2.lat);

        a = Math.sin(deltaLat / 2) * Math.sin(deltaLat / 2) + Math.sin(deltaLong / 2) * Math.sin(deltaLong / 2) * Math.cos(lat1) * Math.cos(lat2);
        c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

        return r * c;
    }

    private _log(message: string, info?: any): void {
        this._util.log('GeoContentDetectionService', message, info);
    }
}
