export interface EventCallback<CallerType, ArgumentsType> {
    (caller: CallerType, e: ArgumentsType): void;
}

export class EventHandler<CallerType, ArgumentsType> {
    private subscribers: EventCallback<CallerType, ArgumentsType>[] = [];

    public constructor(private name?: string, private $window: Window = window) { }

    public fire(caller: CallerType, e: ArgumentsType): void {
        var i: number;
        var { length } = this.subscribers;

        for (i = 0; i < length; ++i) {
            this.subscribers[i].call(null, caller, e);
        }

        if (this.name) {
            const event = this.$window.document.createEvent('Event');
            event.initEvent(this.name, true, true);
            (<any>event).detail = { caller: caller, e: e };
            this.$window.dispatchEvent(event);
        }
    }

    public subscribe(callback: EventCallback<CallerType, ArgumentsType>): void {
        this.subscribers.push(callback);
    }
}
