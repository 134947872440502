export * from './adBlock';
// eslint-disable-next-line @typescript-eslint/no-var-requires
export const AdBlockerMessageTemplate = require('./adBlockMessage.html');

export interface AdBlockerMessageModel {
    image: string,
    message: string,
    skin: string,
    title: string,
    ctaLabel: string,
    ctaLink: string,
    linkTarget: string
}
