import { Player } from '../player';
import { UtilsService } from '../../services';

export class DVComponent {
    private _playerContainer: HTMLElement;
    private _isInitialized: boolean = false;
    private _tracks: AudioTrack[];
    private _initialTrack: number;

    private get _audioTrackButton(): HTMLElement { return <HTMLElement> this._playerContainer.querySelector('.jw-icon-dv'); }

    private HIDDEN_CLASS: string = 'hidden';
    private OFF_STATE: string = 'jw-off';
    private ENGLISH_TRACK_LANGUAGE: string = 'en';
    private JW_BUTTON_CLASSES: string[] = ['jw-icon', 'jw-icon-inline', 'jw-button-color', 'jw-reset', 'jw-icon-dv', 'hidden'];

    public constructor(
        private _player: Player,
        options: AudioTracksOptions) {
        this._tracks = options.tracks;
        this._initialTrack = options.currentTrack;
        this._playerContainer = this._player.playerContainer;
        this._player.log('dv', '', this._tracks);

        this._initialize();
    }

    public update(options: AudioTracksOptions): void {
        if ( this._audioTrackButton != null ) {
            this._player.log('DV', 'update');

            if ( this._tracks.length > 1 ) {
                this._audioTrackButton.classList.remove(this.HIDDEN_CLASS);
            }

            // IE doesn't like classList.toggle.  Going to explicitly call add() or remove()
            if (this._tracks[options.currentTrack].language === this.ENGLISH_TRACK_LANGUAGE) {
                this._audioTrackButton.classList.add(this.OFF_STATE);
            } else {
                this._audioTrackButton.classList.remove(this.OFF_STATE);
            }
        }
    }

    private _initialize(): void {
        this._player.log('DV', 'initialize');

        if ( this._audioTrackButton == null ) {
            this._createButton();
        }
        this._audioTrackButton.addEventListener('click', (e: Event) => this._dvButtonClicked(e), false);
        this._audioTrackButton.addEventListener('touchstart', (e: Event) => this._dvButtonClicked(e), false);
    }

    private _createButton(): void {
        var button = document.createElement('div');

        var dvOffIcon = '<svg class="jw-svg-icon jw-svg-icon-dv-off"></svg>';
        var dvOnIcon = '<svg class="jw-svg-icon jw-svg-icon-dv-on"></svg> ';

        button.innerHTML = dvOnIcon + dvOffIcon;

        this.JW_BUTTON_CLASSES.forEach( function(val) {
            button.classList.add(val);
        });

        button.setAttribute('role', 'button');
        button.setAttribute('tabindex', '0');
        button.setAttribute('aria-label', 'DV');
        document.querySelector('.jw-controlbar .jw-button-container').insertBefore(button, document.querySelector('.jw-icon-cc'));
    }

    private _dvButtonClicked(e: Event): void {
        this._player.log('DV', 'dvButtonClicked');

        e.stopPropagation();
        e.preventDefault();

        this._player.log('DV', 'stop event propagation');

        var currentIdx: number = this._player.currentAudioTrack;
        var target: number = currentIdx ? 0 : 1;

        this._player.log('DV', 'set audio track to', this._tracks[target]);
        this._player.setCurrentAudioTrack(target);
    }
}
