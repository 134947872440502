import { Player, PlaylistEntry } from './../player';

export interface AdobeVideoHeartbeatConfig {
    account: string,
    channel: string,
    debug?: boolean,
    useTagManager?: boolean,
    ssl?: boolean,
    heartbeatServer: string,
    producer: string,
    secureTrackingServer: string,
    trackingServer: string,
    customMetadataCallback?: any,
    platform: string,
}

export class AdobeVideoHeartbeatManager implements VideoPlayerPluginDelegate {
    private static PLAYER_NAME = 'Corus Video Player';
    private static LIVE_STREAM_DURATION: number = 86400;
    private aaPlugin: any;
    private adBreakInfo: AdBreakInfo = null;
    private adInfo: AdInfo = null;
    private mediaHeartbeat: any;
    private config: AdobeVideoHeartbeatConfig;
    private debugLogging: boolean = false;

    // Start DAI ad pod count at 10 to distinguish between VOD ad pods.
    private daiPodCount: number = 10;

    public constructor(private player: Player, private $window: Window = window) {
        return;
    }

    public setup(config: AdobeVideoHeartbeatConfig): void {
        this.debugLogging = Boolean(config.debug);
        this.config = config;

        var visitor;
        visitor = Visitor.getInstance(config.producer, {
            trackingServer: config.trackingServer,
            trackingServerSecure: config.secureTrackingServer,
            marketingCloudServer: config.trackingServer,
            marketingCloudServerSecure: config.secureTrackingServer
        });

        var appMeasurement;

        // Version 2 of AppMeasurement library requires the account to be passed as part of the initialization.
        if (this.getAppMeasurementVersion().major >= 2) {
            appMeasurement = new AppMeasurement(config.account);
        } else {
            appMeasurement = new AppMeasurement();
            appMeasurement.account = config.account;
        }

        appMeasurement.visitor = visitor;
        appMeasurement.trackingServer = config.trackingServer;
        appMeasurement.trackingServerSecure = config.secureTrackingServer;
        (<any>window).appMeasurement = appMeasurement;

        // Video Player plugin
        var mediaConfig = new ADB.va.MediaHeartbeatConfig();
        mediaConfig.trackingServer = config.heartbeatServer;
        mediaConfig.playerName = AdobeVideoHeartbeatManager.PLAYER_NAME;
        mediaConfig.debugLogging = this.debugLogging;
        // set this to true to enable Heartbeat calls through HTTPS
        mediaConfig.ssl = (this.$window.location.protocol === 'https:');
        mediaConfig.channel = config.platform ? config.platform : config.channel;
        mediaConfig.ovp = 'unknown';
        mediaConfig.sdk = 'unknown';
        mediaConfig.appVersion = 'unknown';

        const mediaDelegate = new ADB.va.MediaHeartbeatDelegate();
        mediaDelegate.getCurrentPlaybackTime = () => {
            return this.getCurrentPlaybackTime();
        };
        mediaDelegate.getQoSObject = () => {
            return this.getQoSInfo();
        };

        this.mediaHeartbeat = new ADB.va.MediaHeartbeat(mediaDelegate, mediaConfig, appMeasurement);

    }

    public trackAdComplete(): void {
        this.mediaHeartbeat.trackEvent(ADB.va.MediaHeartbeat.Event.AdComplete);
    }

    public trackAdBreakComplete(): void {
        this.mediaHeartbeat.trackEvent(ADB.va.MediaHeartbeat.Event.AdBreakComplete);
    }

    public trackAdStart(ad: any, isNewBreak: boolean = false): void {
        var { MediaHeartbeat } = ADB.va;

        var adId: string = '';
        var podIndex: number = 0;
        var adLength: number = 0;
        var adPosition: number = 0;

        if (ad.ima && ad.ima.ad) {
            // Retrieve ad info via ad.ima SDK
            var imaAd: any = ad.ima.ad;
            var podInfo: ImaAdPodInfo = imaAd.getAdPodInfo();

            adId = imaAd.getAdId();
            podIndex = podInfo.getPodIndex() + 1;
            adLength = Math.max(0, imaAd.getDuration());
            adPosition = podInfo.getAdPosition();
        } else if ('dai' === ad.client) {
            // DAI ad values.
            adId = ad.id;
            adLength = ad.duration;

            // In case ad.sequence returned 0
            adPosition = ad.sequence ? ad.sequence : 1;

            // Pod number returns the same number for all pods.
            // Keep track of ad pod number mannually.
            podIndex = this.daiPodCount;

            if (isNewBreak) {
                this.daiPodCount++;
            }
        }

        this.adBreakInfo = MediaHeartbeat.createAdBreakObject(
            AdobeVideoHeartbeatManager.PLAYER_NAME,
            podIndex,
            this.player.currentMediaPosition
        );

        this.adInfo = MediaHeartbeat.createAdObject(
            ad.adtitle,
            adId,
            adPosition,
            adLength
        );

        if (isNewBreak) {
            this.mediaHeartbeat.trackEvent(MediaHeartbeat.Event.AdBreakStart, this.adBreakInfo);
        }

        this.mediaHeartbeat.trackEvent(MediaHeartbeat.Event.AdStart, this.adInfo, this.getCustomMetadata());
    }

    public trackChapterComplete(): void {
        this.mediaHeartbeat.trackEvent(ADB.va.MediaHeartbeat.Event.ChapterComplete);
    }

    public trackChapterStart(): void {
        this.mediaHeartbeat.trackEvent(ADB.va.MediaHeartbeat.Event.ChapterStart, this.getChapterInfo());
    }

    public trackComplete(): void {
        this.mediaHeartbeat.trackComplete();
        this.mediaHeartbeat.trackSessionEnd();
    }

    public trackSeekStart(): void {
        this.mediaHeartbeat.trackEvent(ADB.va.MediaHeartbeat.Event.SeekStart);
    }

    public trackSeekComplete(): void {
        this.mediaHeartbeat.trackEvent(ADB.va.MediaHeartbeat.Event.SeekComplete);
    }

    public trackBufferStart(): void {
        this.mediaHeartbeat.trackEvent(ADB.va.MediaHeartbeat.Event.BufferStart);
    }

    public trackBufferComplete(): void {
        this.mediaHeartbeat.trackEvent(ADB.va.MediaHeartbeat.Event.BufferComplete);
    }

    public trackPause(): void {
        this.mediaHeartbeat.trackPause();
    }

    public trackPlay(): void {
        this.mediaHeartbeat.trackPlay();
    }

    public trackVideoLoad(): void {
        var index = this.player.playlistIndex || 0;
        var currentMedia = <PlaylistEntry> this.player.config.jwOptions.playlist[index];
        var episodeNumber: number;
        var seasonNumber: number;
        var { MediaHeartbeat } = ADB.va;
        var type: string = this.player.currentMedia.metadata.liveStream ? MediaHeartbeat.StreamType.LIVE : MediaHeartbeat.StreamType.VOD;
        const savedItem = JSON.parse(localStorage.getItem('items'));

        if (currentMedia) {
            var mediaInfo = MediaHeartbeat.createMediaObject(this.player.currentMediaMetaData,
                this.player.currentMedia.mediaId,
                this.player.currentMediaDuration,
                type);

            // Set standard Video Metadata
            var metadata: Record<string, any> = {};
            metadata[MediaHeartbeat.VideoMetadataKeys.SHOW] = currentMedia.show;
            metadata[MediaHeartbeat.VideoMetadataKeys.STREAM_FORMAT] = currentMedia.metadata.clipType === 'episode' ? '1' : '0';
            metadata[MediaHeartbeat.VideoMetadataKeys.AUTHORIZED] = (currentMedia.metadata.restricted === true).toString();

            let contextData: any = {
                'video.episode-name': currentMedia.title,
                'video.show': currentMedia.show,
                'video.episode-id': currentMedia.metadata.episodeID || 0,
                'video.type': currentMedia.metadata.clipType,
                'video.genre': currentMedia.metadata.genre,
                'video.channel': this.config.channel,
            };

            try {
                episodeNumber = parseInt(currentMedia.metadata.episodeNumber, 10);
                if (!isNaN(episodeNumber)) {
                    contextData['video.episode'] = episodeNumber;
                    metadata[MediaHeartbeat.VideoMetadataKeys.EPISODE] = episodeNumber;
                }
            } catch (error) {
                episodeNumber = 0;
            }

            try {
                seasonNumber = parseInt(currentMedia.metadata.seasonNumber, 10);
                if (!isNaN(seasonNumber)) {
                    contextData['video.season'] = seasonNumber;
                    metadata[MediaHeartbeat.VideoMetadataKeys.SEASON] = seasonNumber;
                }
            } catch (error) {
                seasonNumber = 0;
            }

            // AB Testing tracking
            for (var experiment in this.$window['abTest']) {
                contextData['abtest.' + experiment] = this.$window['abTest'][experiment].toString();
            }

            // Ad Block tracking
            contextData['video.adblocked'] = this.player.isAdBlocked.toString();

            contextData = this.getCustomMetadata(contextData);

            // Analytics data for shuffle play event
            const shuffleSequence = JSON.parse(localStorage.getItem('shuffleSequence'));
            if (shuffleSequence === 0) {  // checkout for first shuffle play event
                contextData['video.random'] = 1;
            }
            const urlParams = new URLSearchParams(window.location.search);
            const isSmartPlay = urlParams.get('action') === 'smartPlay';

            // Analytics data for smartplay event
            if (isSmartPlay) {
                contextData['video.smartplay'] = 1;
            }

            // Analytics data for binge watch event
            if (savedItem?.bingeWatched > 1) {
                contextData['video.binge'] = savedItem.bingeWatched - 1;
            }

            mediaInfo.setValue(MediaHeartbeat.MediaObjectKey.StandardVideoMetadata, metadata);
            this.mediaHeartbeat.trackSessionStart(mediaInfo, contextData);
        }
    }

    public trackVideoError(errorId: string): void {
        this.mediaHeartbeat.trackError(errorId);
        this.trackVideoUnload();
    }

    public trackVideoUnload(): void {
        if (this.mediaHeartbeat) {
            this.mediaHeartbeat.trackSessionEnd();
        }
    }

    // VideoPlayerPluginDelegate Implementation

    public getVideoInfo(): VideoInfo {
        var result: VideoInfo = {
            id: this.player.currentMedia.mediaId,
            name: this.player.currentMediaMetaData,
            length: this.player.currentMedia.metadata.liveStream ? AdobeVideoHeartbeatManager.LIVE_STREAM_DURATION : this.player.currentMediaDuration,
            streamType: this.player.currentMedia.metadata.liveStream
                ? ADB.va.plugins.videoplayer.AssetType.ASSET_TYPE_LIVE : ADB.va.plugins.videoplayer.AssetType.ASSET_TYPE_VOD,
            playerName: AdobeVideoHeartbeatManager.PLAYER_NAME,
            playhead: this.player.currentMediaPosition
        };
        return result;
    }

    public getChapterInfo(): any {
        var result: ChapterInfo = ADB.va.MediaHeartbeat.createChapterObject(
            'Chapter ' + this.player.currentChapter,
            this.player.currentChapter,
            this.player.currentChapterDuration,
            this.player.currentChapterOffset
        );
        return result;
    }

    public getQoSInfo(): any {
        return null;
    }

    public getAdBreakInfo(): any {
        return this.adBreakInfo;
    }

    public getAdInfo(): any {
        return this.adInfo;
    }

    public getCustomMetadata(contextData: any = null): any {
        contextData = contextData || {};
        if (this.config.customMetadataCallback) {
            contextData = this.config.customMetadataCallback.call(null, contextData);
        }

        return contextData;
    }

    public getCurrentPlaybackTime(): any {
        return this.player.currentMediaPosition;
    }

    private getAppMeasurementVersion(): any {
        var version: any = {};
        if (typeof (s) !== 'undefined') {
            var versionParts = s.version.split('.');
            if (versionParts.length >= 2) {
                version = {
                    major: parseInt(versionParts[0]),
                    minor: parseInt(versionParts[1]),
                };
            }
        }
        this.player.log('AppMesurement version', version);

        return version;
    }
}