import { BeforePlayingEvent, ChapterEvent, PlayerSubscriber, Player, PlayEvent } from './../player';
import { AdobeVideoHeartbeatConfig, AdobeVideoHeartbeatManager } from './heartbeatManager';

export class AdobeVideoHeartbeatSubscriber implements PlayerSubscriber {
    private sessionStarted = false;
    private playbackStarted = false;
    private isAdBreak = false;
    private paused = false;
    private seeking = false;
    private player: Player;

    private cachedChapterStart: number = -1;
    private cachedAdComplete: boolean = false;
    private chaptersTracked: number[] = [];

    public constructor(private config: AdobeVideoHeartbeatConfig, private manager: AdobeVideoHeartbeatManager = null) {
        return;
    }

    public bindTo(player: Player): void {
        if (this.player) {
            throw new Error('Subscriber is already bound.');
        }

        if (!this.manager) {
            this.manager = new AdobeVideoHeartbeatManager(player);
        }

        this.player = player;
        player.initialized.subscribe((caller, e) => this.onInitialized(caller, e));
        player.playing.subscribe((caller, e) => this.onStart(caller, e));
        player.adStarting.subscribe((caller, e) => this.onStart(caller, e));
        player.adStarted.subscribe((caller, e) => this.onAdStarted(caller, e));
        player.adResumed.subscribe((caller, e) => this.onAdResumed(caller, e));
        player.adPaused.subscribe((caller, e) => this.onAdPaused(caller, e));
        player.adCompleted.subscribe((caller, e) => this.onAdCompleted(caller, e));
        player.adFirstFrame.subscribe((caller, e) => this.onAdFirstFrame(caller, e));
        player.contentResumed.subscribe((caller, e) => this.onPlayed(caller, e));
        player.played.subscribe((caller, e) => this.onPlayed(caller, e));
        player.pausing.subscribe((caller, e) => this.onPausing(caller, e));
        player.seeked.subscribe((caller, e) => this.onSeeked(caller, e));
        player.seeking.subscribe((caller, e) => this.onSeeking(caller, e));
        player.chapterCompleted.subscribe((caller, e) => this.onChapterCompleted(caller, e));
        player.chapterStarted.subscribe((caller, e) => this.onChapterStarted(caller, e));
        player.completed.subscribe((caller, e) => this.onCompleted(caller, e));
        player.playlistItemChanged.subscribe((caller, e) => this.onPlaylistItemChanged(caller, e));
        player.videoUnload.subscribe((caller, e) => this.onVideoUnload(caller, e));

        // End video session when playback failed.
        player.playbackFailed.subscribe((caller, e) => this.onError(caller, e));
    }

    private onStart(caller: Player, e: any): void {
        if ( !this.sessionStarted ) {
            this.sessionStarted = true;
            this.manager.trackVideoLoad();
        }
    }

    private onAdCompleted(caller: Player, e: AdCompleteOptions): void {
        this.player.log( 'onAdCompleted', null, e );
        this.cachedAdComplete = true;
    }

    private onAdStarted(caller: Player, e: any): void {
        this.player.log( 'onAdStarted', null, e );

        // No duration avaialble for DAI on ad start, track ad start when first ad frame is triggered.
        if ( 'dai' === e.client ) {
            return;
        }

        this.handleAdStart( e );
    }

    private onAdFirstFrame(caller: Player, e: any): void {
        // Bail for non-DAI, triggers ad start for DAI differently to capture ad duarion.
        if ( 'dai' !== e.client ) {
            return;
        }

        this.player.log( 'onAdFirstFrame' );
        this.handleAdStart( e );
    }

    private handleAdStart( e:any ) {
        this.player.log( 'handleAdStart', null, e );

        // If video was paused before midroll ad start, be sure to trigger a play
        if ( this.playbackStarted ) {
            this.handlePlay();
        }

        // Already in an ad break, track the previous ad's ad complete event.
        if ( this.isAdBreak ) {
            this.handleAdComplete();
        }

        this.manager.trackAdStart(e, !this.isAdBreak);
        this.isAdBreak = true;
    }

    private onAdPaused(caller: Player, e: any): void {
        this.handlePause();
    }

    private onAdResumed(caller: Player, e: any): void {
        if ( this.playbackStarted ) {
            this.handlePlay();
        }
    }

    private onChapterCompleted(caller: Player, e: ChapterEvent): void {
        this.manager.trackChapterComplete();
    }

    private onChapterStarted(caller: Player, e: ChapterEvent): void {
        this.player.log( 'chapter started ' + caller.currentChapter );
        this.player.log( 'chapters tracked so far: ' + this.chaptersTracked.join( ',' ) );

        // Only cache the chapter starts for proper tracking
        // - when tracking the first chapter (preventing chapter_start from firing before the prerolls)
        // - when not playing back content in sequence (whether seeking chapters ahead or seeking backwards)
        var lastChapterTracked = this.chaptersTracked.length > 0 ? this.chaptersTracked[this.chaptersTracked.length - 1] : -1;
        if ( 1 === caller.currentChapter
            || this.chaptersTracked.indexOf( caller.currentChapter - 1 ) < 0
            || lastChapterTracked > caller.currentChapter ) {
            this.cachedChapterStart = caller.currentChapter;
            this.player.log( 'cache chapter start ' + caller.currentChapter );
        } else {
            this.handleChapterStart( caller.currentChapter );
        }
    }

    private onCompleted(caller: Player, e: any): void {
        this.manager.trackComplete();
        this.reset();
    }

    private onInitialized(caller: Player, e: any): void {
        this.manager.setup(this.config);
    }

    private onPausing(caller: Player, e: any): void {
        this.handlePause();
    }

    private onPlayed(caller: Player, e: PlayEvent): void {
        if ( this.cachedAdComplete ) {
            this.handleAdComplete();
            this.manager.trackAdBreakComplete();
            this.isAdBreak = false;
        }

        if ( this.cachedChapterStart ) {
            this.handleChapterStart( this.cachedChapterStart );
        }

        this.handlePlay();
    }

    private onSeeking(caller: Player, e: any): void {
        this.manager.trackSeekStart();
        this.seeking = true;
    }

    private onSeeked(caller: Player, e: any): void {
        if ( this.seeking ) {
            this.manager.trackSeekComplete();
        }
        this.seeking = false;
    }

    private onPlaylistItemChanged(caller: Player, e: any): void {
        if ( this.sessionStarted ) {
            this.manager.trackComplete();
            this.reset();
        }
    }

    private handleAdComplete() {
        // Delay the call to ad complete to avoid unexpected content play from firing
        // As per Adobe documentation:
        // https://marketing.adobe.com/resources/help/en_US/sc/appmeasurement/hbvideo/cookbook/c_vhl_cookbook_fix-ad-mainstart-ad.html
        if ( this.cachedAdComplete ) {
            this.manager.trackAdComplete();
            this.cachedAdComplete = false;
        }
    }

    private handleChapterStart( chapter: number ) {
        var chapterToTrack = chapter || this.cachedChapterStart;

        // Only track chapter start when the chapter hasn't been tracked.
        if ( chapterToTrack > 0 && this.chaptersTracked.indexOf( chapterToTrack ) < 0 ) {
            this.player.log( 'track chapter start ' + chapterToTrack );
            this.manager.trackChapterStart();
            this.chaptersTracked.push( chapterToTrack );
        }

        // Clear cached chapter start.
        this.cachedChapterStart = -1;
    }

    private handlePause() {
        this.player.log('player triggered #trackPause()');
        this.manager.trackPause();
        this.paused = true;
    }

    private handlePlay() {
        // trackPause and trackPlay should always be paired as per documentation
        // be sure to call trackPause if we're making duplicated play() call
        if ( this.paused || !this.playbackStarted ) {
            this.player.log('player triggered #trackPlay()');
            this.manager.trackPlay();
            this.paused = false;
            this.playbackStarted = true;
        }
    }

    private reset() {
        this.sessionStarted = false;
        this.playbackStarted = false;
        this.isAdBreak = false;
        this.paused = false;
        this.cachedAdComplete = false;
        this.cachedChapterStart = -1;
        this.seeking = false;
        this.chaptersTracked = [];
    }

    private onVideoUnload(caller: Player, e: any) {
        this.player.log('player triggered #trackVideoUnload()');
        if ( this.sessionStarted ) {
            this.reset();
        }
        this.manager.trackVideoUnload();
    }

    private onError( caller: Player, e: any ) {
        this.manager.trackVideoError( e.code );
    }
}