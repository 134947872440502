import { fetchJson, decodeJwt } from './helpers';
import { UtilsService } from '../utils';
import { LocalStorageService } from '../localStorage';

interface EnvironmentUrls {
	[key: string]: string;
}

const ENVIRONMENT_URLS: EnvironmentUrls = {
    stage: 'https://corus-authentication-dev.herokuapp.com',
    preprod: 'https://realtime.preprod.corusappservices.com',
    prod: 'https://global.corusappservices.com',
};

const DEFAULT_ENVIRONMENT = 'stage';

export interface VmsRealtimeServiceParams {
    [key: string]: any;
    environmentName?: string,
    environmentUrl?: string,
    platform?: string,
}

export interface UserIds {
    [key: string]: any;
    anonDuid?: string;
    authDuid?: string;
    authSuid?: string;
}

// Helper method to convert Adobe auth token into JSON for VMS.
function mapAdobeAuthToken(token: string) {
    // Token is malformed (thanks Adobe), with two opening tags.
    // Parsing doesn't work anyway, so chop off the whole signatureInfo block.
    // To prevent breaking in the future, make sure it stays malformed (sorry).
    const tokenParts = token.replace('</signatureInfo>', '<signatureInfo>').split('<signatureInfo>');
    const signatureInfo = tokenParts[1];
    token = tokenParts[2];

    const parser = new DOMParser();
    const parsedToken = parser.parseFromString(token, 'text/xml');

    return {
        signature_info: signatureInfo,
        session_GUID: parsedToken.getElementsByTagName('sessionGUID')[0].childNodes[0].nodeValue,
        requester_id: parsedToken.getElementsByTagName('requestorID')[0].childNodes[0].nodeValue,
        ttl: parsedToken.getElementsByTagName('ttl')[0].childNodes[0].nodeValue,
        issue_time: parsedToken.getElementsByTagName('issueTime')[0].childNodes[0].nodeValue,
        provider_id: parsedToken.getElementsByTagName('mvpdId')[0].childNodes[0].nodeValue,
    };
}

// Small helper function to properly remove null IDs from storage.
function setStorage(key: string, value?: string) {
    if (value) {
        window.localStorage.setItem(key, value);
    } else {
        window.localStorage.removeItem(key);
    }
}

let ls_anonDuid:string = null;
let ls_authDuid:string = null;
let ls_authSuid:string = null;
try {
    ls_anonDuid = window.localStorage.getItem('anonDuid') || null;
    ls_authDuid = window.localStorage.getItem('authDuid') || null;
    ls_authSuid = window.localStorage.getItem('authSuid') || null;
} catch ( e ) {
    console.log( 'Localstorage is not available in this site / browser' );
}

export class VmsRealtimeService {
    private static _userIds: UserIds = {
        anonDuid: ls_anonDuid,
        authDuid: ls_authDuid,
        authSuid: ls_authSuid
    };

    private static _platform: string;
    private static _profileId: string;
    private static _vmsRealtimeDomain: string = ENVIRONMENT_URLS[DEFAULT_ENVIRONMENT];
    private static _utilsService: UtilsService;
    private static _localStorage: LocalStorageService;

    //// Accessor methods ////
	// The user's "device ID". This is a unique ID that will refresh every time the user clears their cookies.
	// The anonymous DUID should remain stored even when the user is logged in, in case they log out.
	static get duid() {
		return this._userIds.authDuid || this._userIds.anonDuid;
	}

	// The user's "subscription ID". This ID is unique per provider (Adobe) account.
	static get suid() {
		return this._userIds.authSuid;
    }

    // The user's "profile ID". This ID is unique per session .
    static get puid() {
        return this._profileId;
    }

    static get hasAnonymousVMSAccount() {
        return !!this._userIds.anonDuid;
    }

    static get hasAuthenticatedVMSAccount() {
        return !!this._userIds.authSuid;
    }

    // Set methods to save in local storage and private object.
    static set anonDuid(value: string) {
        this._userIds.anonDuid = value;
        setStorage('anonDuid', value);
    }

    static set authDuid(value: string) {
        this._userIds.authDuid = value;
        setStorage('authDuid', value);
    }

    static set authSuid(value: string) {
        this._userIds.authSuid = value;
        setStorage('authSuid', value);
    }

    // The user's "profile ID". This ID is unique per session .
    static set puid(value: string) {
        this._profileId = value;
        setStorage('puid', value);
    }

    private static get utilsService() {
        if (!this._utilsService) {
            this._utilsService = UtilsService.Instance;
            const url = new URL(window.location.href);
            this._utilsService.setup({
                debug: (url.searchParams.get('debug') === 'true')
            });
        }
        return this._utilsService;
    }

    private static get localStorage() {
        if (!this._localStorage) {
            this._localStorage = LocalStorageService.Instance;
        }
        return this._localStorage;
    }

    public static setup(params: VmsRealtimeServiceParams = {}) {
        if (params.environmentUrl) {
            this._vmsRealtimeDomain = params.environmentUrl;
        } else if (params.environmentName) {
            this._vmsRealtimeDomain = ENVIRONMENT_URLS[params.environmentName];
        }

        if (params.platform) {
            this._platform = params.platform;
        } else {
            // Calculate a default platform.
            if (this.utilsService.isSafari() || this.utilsService.isWKWebview()) {
                this._platform = 'web_fairplay';
            } else if (this.utilsService.isIE() || this.utilsService.isEdge()) {
                this._platform = 'web_playready';
            } else {
                this._platform = 'web_widevine';
            }
        }
    }

    // Set any provided user IDs.
    public static setUserIds(userIds: UserIds) {
        for (var prop in userIds) {
            VmsRealtimeService._userIds[prop] = userIds[prop];
        }
    }

    // Helper method to sign any request to VMS that requires signing
    public static async getSignedRequest(path: string, data: object) {
        const requestBody: any = {
            path,
            data,
        };
        return await fetchJson(`${this._vmsRealtimeDomain}/authorization/untrusted/sign`, requestBody);
    }

    // Performs *two* API calls: one to the signing endpoint to sign the data,
    //   and then a second to actually perform the request and get a response.
    public static async signAndRequest(path: string, data: object) {
        const signatureResponse = await this.getSignedRequest(path, data);
        return await this.requestAndDecode(path, signatureResponse.data);
    }

    // For unsigned requests that returned a signed response, here is a helper function.
    public static async requestAndDecode(path: string, data: object) {
        let responseData: any = {};
        const jwtTokenResponse = await fetchJson(`${this._vmsRealtimeDomain}${path}`, data);

        try {
            responseData = JSON.parse(decodeJwt(jwtTokenResponse.session_data));
            this.utilsService.log('VmsRealtimeService', `${path} requested and decoded`, responseData);
        } catch (e) {
            console.error('Could not decode VMS response. It may not be signed.', jwtTokenResponse);
        }

        return responseData;
    }

    // Creates an anonymous user for playback of unauthenticated content.
    // Returns a payload like: {"exp":1569620603,"data":{},"duid":"df75db28-7fca-470a-b485-a670b613b03d","authenticator_id":"anonymous","status":"200"}
    // Example staging app IDs:
    // const appId = 'c5f5177c-defa-11e9-818a-423bc265bdc4'; // Global Stream
    // const appId = '424565a4-67fe-11e8-b419-0606fe'; // GlobalTV
    public static async createAnonymousUser(appId: string) {
        const responseData = await this.signAndRequest('/authentication/authenticate', {
            transaction_type: 'authenticate',
            authenticator_id: 'anonymous',
            application_id: appId,
            platform_id: this._platform,
        });
        this.anonDuid = responseData.duid;
        this.puid = responseData.puid;

        return responseData;
    }

    // Creates a user authenticated with Adobe.
    // Requires an Adobe authentication token.
    public static async createAdobeAuthenticatedUser(appId: string, distId: string, authToken: string, resourceIds: string[] = ['globaltv'], userId: string = null) {
        const tokenData: any = mapAdobeAuthToken(authToken);
        tokenData.user_id = userId || tokenData.session_GUID; // The user ID should be the upstream BDU ID. But, if not available, session GUID can be substituted.
        tokenData.adobe_resource_ids = resourceIds;

        // THIS LINE IS TEMPORARY - should probably be passed in? Not so easy to get though
        tokenData.provider_name = tokenData.provider_id;

        const responseData = await this.signAndRequest('/authentication/authenticate', {
                transaction_type: 'authenticate',
                authenticator_id: 'adobepass',
                application_id: appId,
                distribution_id: distId,
                platform_id: this._platform,
                data: tokenData,
        });

        this.authDuid = responseData.duid;
        this.authSuid = responseData.suid;
        this.puid = responseData.puid;

        return responseData;
    }

    // Deauthenticates (logs out) a user from the VMS. Returns an empty object if the user is not authenticated.
    // Resposne codes:
    // 200 - OK
    // 305 - Invalid credentials
    public static async deauthenticateUser() {
        if (this._userIds.authSuid) {
            const requestParams = {
                suid: this._userIds.authSuid,
                duid: this._userIds.authDuid
            };

            this.authDuid = null;
            this.authSuid = null;

            const responseData = await this.signAndRequest('/authentication/deauthenticate', requestParams);
            return responseData;
        }

        return {};
    }

    // Checks whether a user is still authenticated with the VMS.
    // Response codes:
    // 200 - OK
    // 303 - Invalid credentials
    // 304 - Expired credentials
    public static async checkAuthentication() {
        const userIds: any = {
            duid: this.duid
        };
        if (this.suid) {
            userIds.suid = this.suid;
        }

        const responseData = await this.signAndRequest('/authentication/checkauthentication', userIds);

        return responseData;
    }

    // Get a media stream and data. This part would be performed inside the video
    //   player (but the auth part needs site integration so it's not clear)
    // @resourceId: the video ID
    // @duid: the session (device) id. Cookie this
    // @suid: the authenticated user (subscriber) id, if available. Cookie this
    // See https://github.com/ShawONEX/vms-realtime-service#error-codes for error codes.
    public static async getPlaybackData(resourceId: string, isAuthenticated: boolean = false) {
        this.utilsService.log('VmsRealtimeService', 'Retrieving playback data authorization.');

        const mediaAuthParams: any = {
            duid: this.duid,
            resource_id: resourceId,
        };
        if (this.suid) {
            mediaAuthParams.suid = this.suid;
        }
        if (this._profileId) {
            mediaAuthParams.puid = this._profileId; // To pass the profile ID to vms know the users session progress
        }

        const mediaAuthorization = await this.signAndRequest('/authorization/authorizeresource', mediaAuthParams);
        const errorMessage = this._handleError(mediaAuthorization.status, {
            duid: this.duid,
            suid: this.suid,
            isAuthenticated
        });
        if (errorMessage) {
            return { error: errorMessage };
        }

        const playbackData = await this.requestAndDecode('/media/getstream', {
            authorization_token: mediaAuthorization.data.authorization_token,
            platform: this._platform,
        });

        // Add authorization token to playback data field to facilitate DRM request.
        playbackData.authorization_token = mediaAuthorization.data.authorization_token;

        return playbackData;
    }

    // Inform the VMS that a playback session is still in progress.
    // @sessionId: the session ID, from the getStream request
    // @sessionProgress: the session progress, from 0.0 to 1.0
    // See https://github.com/ShawONEX/vms-realtime-service#error-codes for error codes.
    public static async touchSession(sessionId: string, sessionProgress: number) {
        const progressString = sessionProgress.toString().substring(0, 6); // Decimal value from 0.0 to 1.0 with 4 places precision
        const responseData = await fetchJson(`${this._vmsRealtimeDomain}/metrics/session/media?session_id=${sessionId}&session_progress=${progressString}`, null, 'GET');

        return responseData;
    }

    // Update a VMS session with a new status.
    // @sessionId: the session ID, from the getStream request
    // @sessionStatus: the session progress, from 0.0 to 1.0
    // @duid: the device ID for the session
    // See https://github.com/ShawONEX/vms-realtime-service#error-codes for error codes.
    public static async updateSession(sessionId: string, sessionStatus: string, sessionProgress: number) {
        const progressString = sessionProgress.toString().substring(0, 6); // Decimal value from 0.0 to 1.0 with 4 places precision
        const responseData = await this.requestAndDecode('/metrics/session/media', {
            duid: this.duid,
            session_id: sessionId,
            session_status: sessionStatus,
            session_progress: progressString,
        });

        return responseData;
    }

    // Inform the VMS that a playback session has finished.
    // @sessionId: the session ID, from the getStream request
    // @sessionProgress: the session progress, from 0.0 to 1.0
    // See https://github.com/ShawONEX/vms-realtime-service#error-codes for error codes.
    public static async closeSession(sessionId: string, sessionProgress: number) {
        this.updateSession(sessionId, 'closed', sessionProgress);
    }

    // Formats a playback data object (from getPlaybackData) into fields that the Videoplayer expects.
    // @playbackData: a response object from getPlaybackData.
    public static formatPlaybackData(playbackData: any) {

        if (!playbackData.error) {
            const sources = [
                {
                    file: playbackData.resources.streaming_url,
                    type: (playbackData.origin === 'globalnewsott' && playbackData.type ==='live')  ? 'hls' : (playbackData.delivery_protocol === 'mpeg-dash' ? 'dash' : 'hls'),  // special case since the global news is receiving the dash stream for DAI
                },
            ];

            const tracks = playbackData.captions ? playbackData.captions.map((captionItem: any) => {
				return {
					file: captionItem.url,
					kind: 'captions',
					label: captionItem.label,
				};
            }) : [];

            const formattedData:any = {
                sources,
                tracks,
                authorizationToken: playbackData.authorization_token,
                sessionId: playbackData.session_id,
                isDRM: playbackData.protection_scheme !== 'none'
            };

            if ( playbackData.gam_dai_asset_id ) {
                formattedData.daiSetting = {
                    assetKey: playbackData.gam_dai_asset_id
                };
            }

            return formattedData;
		} else {
            return {
                error: playbackData.error
            };
		}
    }


	public static async userHistory(profileID: string, offSet: number,duration: number) {
		// Clear the history if any thing played in anonymous user and signed user
		this.localStorage.removeAll();
		this.localStorage.save();
		return await this.makeHistory(profileID,offSet,duration);
	}

    public static async makeHistory(profileID: string, offSet: number, duration: number) {
        if (profileID) {
            const requestBody = {
                transaction_type: 'retrieve',
                profile: profileID,
                filters: {
                    limit: 50,
                    offset: offSet,
                    type: ['episode','movie','media'],
                    date_start: this.utilsService.subtractDays(duration),         // for the smart play feature
                    date_end: UtilsService.formatDate( new Date())        // 2016-06-08
                }
            };
            this.signAndRequest('/user/history', requestBody).then(async (result) => {
                if (result.history.length > 0) {
                    this.addingStorage(result.history);
                    return await this.makeHistory(profileID, offSet + 50,duration);
                } else {
                    return false;
                }
            });
        }
    }

    public static async deleteHistory(profileID: string) {
        if (profileID) {
            const requestBody = {
                transaction_type: 'delete',
                profile: profileID,
            };
            const data = await this.signAndRequest('/user/history', requestBody);
            return data;
        }
    }


    public static addingStorage(history: Array<any>) {
        history.forEach(value => {
            const percent = value.progress * 100;
            this.localStorage.put({ mediaId: value.external_id, value: value.progress, percentage:percent, timestamp: Date.now() });
        });
        this.localStorage.save();
    }

    public static async configuration(appId: string, distId: string, versionID: string) {
        const requestBody = {
            application_id: appId,
            distribution_id: distId,
            version: versionID
        };
        const data = await this.signAndRequest('/application/configuration', requestBody);
        this.localStorage.saveData('config', JSON.stringify(data));
        return data;
    }

    // User Favourites
    // retrieve User favourites
    public static async userFavorites(profileID: string, offSet: number,type:string) {
        this.localStorage.deleteDataById('favorites');
        this.localStorage.save();
        return await this.makeFavorites(profileID,offSet,type);
    }

    public static async makeFavorites(profileID: string, offSet: number,type:string) {
        if (profileID || this._profileId) {
            const requestBody = {
                transaction_type: 'retrieve',
                profile: profileID || this._profileId,
                filters:{
                    limit:200,
                    offset:offSet,
                    type: ['series','movie', type ?? 'channel']
            }
            };
            this.signAndRequest('/user/favorites', requestBody).then(async (result) => {
                if (result.favorites.length > 0) {
                    this.addStorage(result.favorites);
                    return await this.makeFavorites(profileID, offSet + 200,type);
                } else {
                    return false;
                }
            });
        }
    }

    public static addStorage(favorites: Array<any>) {
        this.localStorage.saveData('favorites', JSON.stringify(favorites));
    }

    // delete user favorites
    public static async deleteFavourite(profileID: string,guid: string) {
        if (profileID || this._profileId) {
            const requestBody = {
                transaction_type: 'delete',
                profile: profileID || this._profileId,
                guid: guid
            };
            const data = await this.signAndRequest('/user/favorites', requestBody);
            return data;
        }

        return null;
    }
    // create user favorites

    public static async addFavourite(profileID: string,guid: string,type: string) {
        if (profileID || this._profileId) {
            const requestBody = {
                transaction_type: 'create',
                type: type,
                profile: profileID || this._profileId,
                external_id: guid,
                group_id: guid
            };
            const data = await this.signAndRequest('/user/favorites', requestBody);
            return data;
        }

        return null;
    }


    // Helper error-reporting method.
    private static _handleError(responseCode: string, errorData: any) {
        if (responseCode === '400' || responseCode === '413') {
            if (errorData.isAuthenticated === true) {
                this.utilsService.log('VmsRealtimeService', 'User is not authorized.', errorData);
                return 'authorizationError';
            } else {
                this.utilsService.log('VmsRealtimeService', 'User is not authenticated.', errorData);
                return 'authenticationError';
            }
        } else if (responseCode === '401') {
            this.utilsService.log('VmsRealtimeService', 'User geolocation restricted.', errorData);
            return 'geolocationError';
        } else if (responseCode === '402') {
            this.utilsService.log('VmsRealtimeService', 'Invalid VMS credentials.', errorData);
            return 'credentialsError';
        } else if (responseCode === '403') {
            this.utilsService.log('VmsRealtimeService', 'VMS credentials expired.', errorData);
            return 'credentialsExpiredError';
        } else if (responseCode === '414' || responseCode === '506') {
            this.utilsService.log('VmsRealtimeService', 'Concurrency limit reached.', errorData);
            return 'concurrencyError';
        }

        return false;
    }
}
