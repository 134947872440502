import { Player, PlaylistEntry } from './player';

export class RedirectionComponent {
    private _player: Player;

    public constructor(player: Player) {
        this._player = player;
        this._player.playlistLoaded.subscribe((caller, e) => this.onPlaylistLoaded(caller, e));
    }

    private onPlaylistLoaded(caller: Player, e: any): void {
        var format = this._player.config.redirectionUrlFormat;
        this._player.config.jwOptions.playlist.map((entry: PlaylistEntry) => {
            // Format in {camelCase} returns a URL where each segment is a slug
            var url = format.replace('{clipType}', this.slugify(entry.metadata.clipType))
                            .replace('{episodeTitle}', this.slugify(entry.title))
                            .replace('{mediaId}', entry.mediaId)
                            .replace('{showName}', this.slugify(entry.show))
                            .replace('{parentId}', entry.parentid);

            // Format in {lowercase} returns a URL where each segment is one word
            // Format in {lower-case} returns a URL where each segment is a slug
            url = url.replace('{cliptype}', this.unify(entry.metadata.clipType))
                    .replace('{episodetitle}', this.unify(entry.title))
                    .replace('{showname}', this.unify(entry.show))
                    .replace('{clip-type}', this.slugify(entry.metadata.clipType))
                    .replace('{episode-title}', this.slugify(entry.title))
                    .replace('{show-name}', this.slugify(entry.show))
                    .replace('{mediaid}', entry.mediaId)
                    .replace('{parentid}', entry.parentid)
                    .replace('{order}', entry.order)
                    .replace('{ordering}', entry.ordering);

            entry.redirectUrl = url;
        });
    }

    // 1. Remove all but alphanumeric, dashes, whitespace and underscores
    // 2. Replace whitespace with dashes
    // 3. Dedupe dashes
    private slugify(input: string): string {
        var result = input ? input.toLocaleLowerCase() : '';
        /* Normalize all accented characters and exclude invalid ones */
        /* Unsupported in IE11 */
        if ( String.prototype.normalize ) {
            result = result.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
        }
        result = result.replace(/[^\w\-\s]+/g,'').replace(/\s+/g,'-').replace(/[-]+/g,'-');
        return result;
    }

    private unify(input: string): string {
        var result = input ? input.toLocaleLowerCase() : '';
        /* Normalize all accented characters and exclude invalid ones */
        /* Unsupported in IE11 */
        if ( String.prototype.normalize ) {
            result = result.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
        }
        result = result.replace(/[^\w\-\s]+/g,'').replace(/\s+/g,'');
        return result;
    }
}
