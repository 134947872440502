var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <figure class=\"videoErrorMessage-figure\"><img class=\"videoErrorMessage-figure-image\" src=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"image") || (depth0 != null ? lookupProperty(depth0,"image") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"image","hash":{},"data":data,"loc":{"start":{"line":3,"column":98},"end":{"line":3,"column":107}}}) : helper)))
    + "\" /></figure>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <p class=\"videoErrorMessage-copy-message\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"message") || (depth0 != null ? lookupProperty(depth0,"message") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"message","hash":{},"data":data,"loc":{"start":{"line":9,"column":54},"end":{"line":9,"column":65}}}) : helper)))
    + "</p>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"videoErrorMessage-copy-message videoErrorMessage-copy-message--unescaped\">\n                "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"message") || (depth0 != null ? lookupProperty(depth0,"message") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"message","hash":{},"data":data,"loc":{"start":{"line":12,"column":16},"end":{"line":12,"column":29}}}) : helper))) != null ? stack1 : "")
    + "\n            </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"videoErrorMessage adBlockerMessage "
    + alias4(((helper = (helper = lookupProperty(helpers,"skin") || (depth0 != null ? lookupProperty(depth0,"skin") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"skin","hash":{},"data":data,"loc":{"start":{"line":1,"column":47},"end":{"line":1,"column":55}}}) : helper)))
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"image") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":4,"column":11}}})) != null ? stack1 : "")
    + "    <div class=\"videoErrorMessage-copy\">\n      <div class=\"corus-icon icon-face\"></div>\n        <h3 class=\"videoErrorMessage-copy-title\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":7,"column":49},"end":{"line":7,"column":58}}}) : helper)))
    + "</h3>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"doEscapeMessage") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data,"loc":{"start":{"line":8,"column":8},"end":{"line":14,"column":15}}})) != null ? stack1 : "")
    + "        <div class=\"videoErrorMessage-cta-wrapper\">\n          <a class=\"videoErrorMessage-cta\" href=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"ctaLink") || (depth0 != null ? lookupProperty(depth0,"ctaLink") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"ctaLink","hash":{},"data":data,"loc":{"start":{"line":16,"column":49},"end":{"line":16,"column":60}}}) : helper)))
    + "\" title=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"ctaLabel") || (depth0 != null ? lookupProperty(depth0,"ctaLabel") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"ctaLabel","hash":{},"data":data,"loc":{"start":{"line":16,"column":69},"end":{"line":16,"column":81}}}) : helper)))
    + "\" target=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"linkTarget") || (depth0 != null ? lookupProperty(depth0,"linkTarget") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"linkTarget","hash":{},"data":data,"loc":{"start":{"line":16,"column":91},"end":{"line":16,"column":105}}}) : helper)))
    + "\">\n              "
    + alias4(((helper = (helper = lookupProperty(helpers,"ctaLabel") || (depth0 != null ? lookupProperty(depth0,"ctaLabel") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"ctaLabel","hash":{},"data":data,"loc":{"start":{"line":17,"column":14},"end":{"line":17,"column":26}}}) : helper)))
    + "\n              <div class=\"corus-icon icon-ctrl\"></div>\n          </a>\n        </div>\n\n    </div>\n</div>\n";
},"useData":true});