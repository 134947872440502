import { AuthMessageModel, AuthMessageTemplate } from '.';
import { Player, Render } from '../player';
import { UtilsService, AuthenticationService } from '../../services';

export interface AuthenticationComponentConfig {
    siteAuth: any,
    signInUrl: string,
    title?: string,
    message?: string,
    ctaLabel?: string
}

export class AuthenticationComponent {
    private _messageContainer: string = 'jwplayer-auth-messaging';
    private _isInitialized: boolean = false;
    private _player: Player;

    private get _containerElement():HTMLElement { return <HTMLElement> this.$window.document.querySelector(this._container); }
    private get _messageContainerElement():HTMLElement { return <HTMLElement> this.$window.document.querySelector('.' + this._messageContainer); }

    public constructor( private _container: string,
                        private _config: AuthenticationComponentConfig,
                        private _authenticationService: AuthenticationService,
                        private $render: any = Render,
                        private $window: Window = window,
                        private _utils: UtilsService = UtilsService.Instance) {

        var element = document.createElement('div');
        element.classList.add(this._messageContainer);
        this._containerElement.appendChild(element);
    }

    public bindTo(player: Player): void {
        player.unAuthUserBlocked.subscribe((caller, e) => this.onException(caller, e));
        this._player = player;
    }

    private onException(caller: Player, e: any): void {
        this._log('onException', e);

        let skin: string = null;
        if (caller.config.jwOptions.skin.name) {
            skin = 'jw-skin-' + caller.config.jwOptions.skin.name;
        }

        this.render(caller.currentMediaData.image, skin, e);
    }

    private render(image: string, skin: string, exception: string = ''): void {
        if (this._messageContainerElement) {
            const authMessageModel: AuthMessageModel = {
                image: image,
                message: this._config.message ? this._config.message : 'Sign in using your TV Service Provider credentials to watch this episode',
                skin: skin,
                title: this._config.title ? this._config.title : 'You\'re almost there!',
                ctaLabel: this._config.ctaLabel ? this._config.ctaLabel : 'Sign in to watch'
            };
            const html = this.$render(AuthMessageTemplate, authMessageModel);
            this._messageContainerElement.addEventListener('click', (e: Event) => this.signIn());
            this._messageContainerElement.innerHTML = html;
        }
    }

    private signIn():void {
        this._authenticationService.signIn();
    }

    private _log(message: string, info?: any) {
        this._utils.log('AuthenticationComponent', message, info);
    }
}
