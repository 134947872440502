import { CancellableEvent, Player, PlayerSubscriber } from './player';
import { XhrService } from '../services';
import { EventHandler } from './events';

interface PlatformEntry {
    id: string,
}

interface PlatformResponse {
    entries: PlatformEntry[],
}

export interface PlatformRelatedContentConfig {
    count?: number,
    episodeNumber?: string,
    feedUrl?: string,
    mediaIds?: string[],
    seasonNumber?: string,
    show?: string,
    clipType?: string,
    isShowSpecific?: boolean
}

export class PlatformRelatedContentComponent {
    private DEFAULT_COUNT: number = 5;
    private _config: PlatformRelatedContentConfig;
    private _didGetLatestEpisodeIds: boolean = false;
    private _didGetNextEpisodeId: boolean = false;
    private _isInitialized: boolean = false;
    private _isInitializing: boolean = false;
    private _latestEpisodeIds: string[];
    private _mediaIds: string[];
    private _nextEpisodeId: string;
    private _player: Player;
    private _xhrService: XhrService;

    public videoNotFound = new EventHandler<PlatformRelatedContentComponent, any>();

    public constructor(config: PlatformRelatedContentConfig, player: Player, xhrService?: XhrService) {
        this._config = config;
        this._config.count = this._config.count ? this._config.count : this.DEFAULT_COUNT;
        this._config.feedUrl = this._config.feedUrl ? this._config.feedUrl : player.config.feedUrl;
        this._xhrService = xhrService ? xhrService : new XhrService();
        this.bindTo(player);
    }

    private bindTo(player: Player): void {
        this._player = player;

        player.initializing.subscribe((caller, e) => this.onInitializing(caller, e));
        player.playlistLoading.subscribe((caller, e) => this.onPlaylistLoading(caller, e));
        player.playlistLoaded.subscribe((caller, e) => this.onPlaylistLoaded(caller, e));
    }

    private checkMediaIdsStatus(): void {
        if (this._didGetLatestEpisodeIds && this._didGetNextEpisodeId) {
            this._mediaIds = [this._player.config.mediaId.toString()];
            if (this._nextEpisodeId) {
                this._mediaIds.push(this._nextEpisodeId);
            }
            if (this._latestEpisodeIds) {
                if (this._nextEpisodeId) {
                    const pos = this._latestEpisodeIds.indexOf(this._nextEpisodeId);
                    if (pos > -1) {
                        this._latestEpisodeIds.splice(pos, 1);
                    }
                }
                this._latestEpisodeIds.map((value: string) => this._mediaIds.push(value));
                this._mediaIds = this._mediaIds.slice(0, this._config.count + 1);
            }

            this._mediaIds = this._mediaIds.map(this._player.stripId);
            this._isInitialized = true;
            this._player.initialize();
        }
    }

    private getLatestEpisodeIds(): void {
        if (this._config.count > 0) {
            const parameters: any = {
                byCustomValue: '{clipType}{episode}',
                endIndex: this._config.count,
                fields: 'id',
                form: 'cjson',
                q: 'NOT id.exact:' + this._player.config.mediaId,
                sort: 'pubDate|desc',
            };

            const url: string = this._config.feedUrl + '?' + this.dictionaryToQuery(parameters);
            this._xhrService.getJson(url, (success: boolean, status: number, data: any) => {
                this._didGetLatestEpisodeIds = true;
                if (success) {
                    var response = <PlatformResponse>data;
                    this._latestEpisodeIds = [];
                    response.entries.map((value: any) => this._latestEpisodeIds.push(value.id));
                }
                this.checkMediaIdsStatus();
            });
        } else {
            this._didGetLatestEpisodeIds = true;
            this.checkMediaIdsStatus();
        }
    }

    private getNextEpisodeId(): void {
        if (this._config.show) {
            var clipType: string = this._config.clipType ? this._config.clipType : 'episode';
            var parameters: any = {
                endIndex: '100',
                fields: 'id',
                form: 'cjson',
                byCustomValue: `{show}{${this._config.show}},{clipType}{${clipType}}`,
                sort: 'pubDate|desc',
            };

            const url: string = this._config.feedUrl + '?' + this.dictionaryToQuery(parameters);

            this._xhrService.getJson(url, (success: boolean, status: number, data: any) => {
                this._didGetNextEpisodeId = true;
                if (success) {
                    var response = <PlatformResponse>data;
                    if (response.entries && response.entries.length > 0) {
                        for (let i = 0; i < response.entries.length; i++ ) {
                            if (response.entries[i].id.indexOf(this._player.config.mediaId) >= 0) {
                                var recoIndex: number = (i > 0) ? (i - 1) : (response.entries.length - 1);
                                if (clipType != 'episode' || i > 0) {
                                    this._nextEpisodeId = response.entries[recoIndex].id;
                                }
                                break;
                            }
                        }
                    }
                }
                this.checkMediaIdsStatus();
            });
        } else {
            this._didGetNextEpisodeId = true;
            this.checkMediaIdsStatus();
        }
    }

    private onInitializing(caller: Player, e: CancellableEvent): void {
        if (this._config.isShowSpecific) {
            return;
        }
        if (!this._isInitialized) {
            e.doCancel = true;
            if (!this._isInitializing) {
                this._isInitializing = true;
                if (this._config.mediaIds) {
                    // List of ID's supplied for playlist
                    this._mediaIds = [this._player.config.mediaId];
                    this._mediaIds = this._mediaIds.concat(this._config.mediaIds);
                    this._isInitialized = true;
                    this._player.initialize();
                } else {
                    // Generate dynamic playlist based on related content attributes (episode number, season number, etc)
                    this.getNextEpisodeId();
                    this.getLatestEpisodeIds();
                }
            }
        }
    }

    private onPlaylistLoaded(caller: Player, e: any): void {
        var orderedPlaylist: PlaylistItem[] = [];
        var { playlist } = caller.config.jwOptions;
        var isVideoFound: boolean = false;

        if (!this._config.isShowSpecific) {
            this._mediaIds.map(function (mediaId: string) {
                playlist.map(function (playlistItem: PlaylistItem) {
                    if (playlistItem.mediaId == mediaId) {
                        orderedPlaylist.push(playlistItem);
                    }

                    if (playlistItem.mediaId == caller.config.mediaId) {
                        isVideoFound = true;
                    }
                });
            });
        } else {
            // order playlist by seanson number and episode number
            orderedPlaylist = playlist.sort(this.compareBySeansonAndEpisode);
            // find the position of current mediaId in the playlist
            var pos = 0;
            for (let i = 0; i < orderedPlaylist.length; i++) {
                if (orderedPlaylist[i].mediaId == caller.config.mediaId) {
                    isVideoFound = true;
                    pos = i;
                    break;
                }
            }
            if (isVideoFound && pos > 0) {
                // slice the playlist based on pos
                // array[0 -> N] => array[pos -> N] + array[0 -> pos-1]
                orderedPlaylist = playlist.slice(pos).concat(playlist.slice(0, pos));
            }
        }

        caller.config.jwOptions.playlist = orderedPlaylist;

        if ( !isVideoFound ) {
            this.videoNotFound.fire( this, {} );
        }
    }

    private onPlaylistLoading(caller: Player, e: any): void {
        if (!this._config.isShowSpecific) {
            var criterias: string[] = this._mediaIds.map(function (value: string) { return 'id.exact:' + value; });
            var query: string = encodeURIComponent(criterias.join(' OR '));
            caller.config.playlistUrl = this._config.feedUrl + '?q=' + query;
        } else {
            var parameters: any = {
                endIndex: '100',
                byCustomValue: `{show}{${this._config.show}}`,
                sort: 'pubDate|desc',
            };
            caller.config.playlistUrl = this._config.feedUrl + '?' + this.dictionaryToQuery(parameters);
        }
    }

    private dictionaryToQuery(dictionary: { [key: string]: any }): string {
        var result: string = Object.keys(dictionary).map(function(value) {
            return `${value}=` + encodeURIComponent(dictionary[value]);
        }).join('&');
        return result;
    }

    private numeralToString(numeral: number, isPadded: boolean): string {
        var result: string = numeral.toString();
        if (numeral < 10 && isPadded) {
            result = '0' + result;
        }
        return result;
    }

    // order by season (desc) and episode (asc)
    private compareBySeansonAndEpisode(itemA: any, itemB: any) {
        // item with empty seanson number will be put to the tail
        var seasonNumberA: number = 0;
        var seasonNumberB: number = 0;
        // item with empty episode number will be put to the tail
        var episodeNumberA: number = 10000;
        var episodeNumberB: number = 10000;
        var airDateA: any;
        var airDateB: any;

        if (itemA.metadata) {
            if (itemA.metadata.seasonNumber) {
                seasonNumberA = parseInt(itemA.metadata.seasonNumber);
            }
            if (itemA.metadata.episodeNumber) {
                episodeNumberA = parseInt(itemA.metadata.episodeNumber);
            }
            airDateA = itemA.pubDate;
        }
        if (itemB.metadata) {
            if (itemB.metadata.seasonNumber) {
                seasonNumberB = parseInt(itemB.metadata.seasonNumber);
            }
            if (itemB.metadata.episodeNumber) {
                episodeNumberB = parseInt(itemB.metadata.episodeNumber);
            }
            airDateB = itemB.metadata.airDate;
        }
        var comparison = 0;

        if (seasonNumberA > seasonNumberB) {
            comparison = -1;
        } else if (seasonNumberA < seasonNumberB) {
            comparison = 1;
        } else {
            // compare episode number
            if (episodeNumberA > episodeNumberB) {
                comparison = 1;
            } else if (episodeNumberA < episodeNumberB) {
                comparison = -1;
            } else {
                // convert airDate from UTC string to Date for comparing
                airDateA = Date.parse(airDateA);
                airDateB = Date.parse(airDateB);
                comparison = airDateA - airDateB;
            }
        }

        return comparison;
    }
}
