import { Player, PlayerSubscriber } from './player';

export class ClosedCaptionsTransformationSubscriber implements PlayerSubscriber {
    public constructor(private urlFormat: string) {
        console.error('The ClosedCaptionsTransformationSubscriber class has been deprecated as it depended on MPX. If its functionality is required, it will need to be updated for VMS.');
        return;
    }

    public bindTo(player: Player): void {
        // Deprecated - binding removed for now
        // player.playlistLoaded.subscribe((player, e) => this.onPlaylistLoaded(player, e));
    }

    private onPlaylistLoaded(player: Player, e: any): void {
        var { playlist } = player.config.jwOptions;
        for (let i = 0, { length } = playlist; i < length; ++i) {
            const playlistItem: PlaylistItem = playlist[i];
            if (playlistItem.tracks && playlistItem.tracks.length) {
                playlistItem.tracks[0].file = this.transformUrl(playlistItem.mediaId);
            }
        }
    }

    private transformUrl(mediaId: string): string {
        return this.urlFormat.replace('{mediaId}', mediaId);
    }
}
