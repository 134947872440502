export interface UtilsConfig {
    debug?: boolean;
    logBox?: string;
}

export interface OSVersion {
    major: number;
    minor: number;
}

export class UtilsService {
    private static _instance: UtilsService;
    private _isAndroid: boolean;
    private _isSafari: boolean;
    private _isIOS: boolean;
    private _isMacOS: boolean;
    private _isWKWebview: boolean;
    private _isWindows: boolean;
    private _isIE: boolean;
    private _isEdge: boolean;
    private _isFirefox: boolean;

    constructor(private _config: UtilsConfig, private $window: Window = window) {
    }

    public static get Instance(): UtilsService {
        if (!UtilsService._instance) {
            UtilsService._instance = new UtilsService({});
        }
        return UtilsService._instance;
    }

    public setup(config: UtilsConfig) {
        this._config = config;
    }

    public log(callerName: string, ...args: any[]):void {
        if (this._config.debug) {
            const parameters: any = ['[' + callerName + ']'];

            for (let i = 0; i < args.length; i++) {
                if (args[i]) {
                    parameters.push(args[i]);
                }
            }

            try {
                console.log.apply(null, parameters);
            } catch (error) {
                // Apparently, IE and Edge doesn't support logging multiple arguments at once. Except when the developer console is open... Yeah.
                console.log({
                    eventType: args && args.length > 0 ? args[0] : '',
                    message: args && args.length > 1 ? args[1] : '',
                    info: args && args.length > 2 ? args[2] : ''
                });
            }


            if (this._config.logBox) {
                this._visibleLog(parameters);
            }
        }
    }

    public padNumber(number: string): string {
        var result: string = number.toString();
        while (result.length < 2) {
            result = '0' + result;
        }
        return result;
    }

    public writeCookie(name: string, value: string, days: number = 1) {
        var expires = '', domainParts = [], dotDomain = '';

        if (days) {
            var exdate = new Date();
            exdate.setDate(exdate.getDate() + days);
            expires = '; expires=' + exdate.toUTCString();
        }

        domainParts = this.$window.location.hostname.split('.');
        dotDomain += '.' + domainParts.slice(-2).join('.');

        document.cookie = name + '=' + value + expires + '; path=/; domain=' + dotDomain;
    }

    public readCookie(name: string) {
        var nameEQ = name + '=';
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
    }

    public eraseCookie(name: string) {
        this.writeCookie(name, '', -1);
    }

    public isAndroid(): boolean {
        if (UtilsService._instance._isAndroid == null) {
            UtilsService._instance._isAndroid = this.$window.navigator.userAgent && /android/i.test(this.$window.navigator.userAgent);
        }
        return UtilsService._instance._isAndroid;
    }

    public isIOS(): boolean {
        if (UtilsService._instance._isIOS == null) {
            UtilsService._instance._isIOS = this.$window.navigator.platform && /iP(hone|od|ad)/i.test(this.$window.navigator.platform);
        }
        return UtilsService._instance._isIOS;
    }

    public isMac(): boolean {
        if (UtilsService._instance._isMacOS == null) {
            UtilsService._instance._isMacOS = this.$window.navigator.platform && /Mac/i.test(this.$window.navigator.platform);
        }
        return UtilsService._instance._isMacOS;
    }

    public isSafari(): boolean {
        if (UtilsService._instance._isSafari == null) {
            UtilsService._instance._isSafari = this.$window.navigator.userAgent && !(/chrome/i.test(this.$window.navigator.userAgent)) && /safari/i.test(this.$window.navigator.userAgent);
        }
        return UtilsService._instance._isSafari;
    }

    public isWKWebview(): boolean {
        if (UtilsService._instance._isWKWebview == null) {
            UtilsService._instance._isWKWebview = this.isIOS() && this.$window.navigator.userAgent && !/safari/i.test(this.$window.navigator.userAgent);
        }
        return UtilsService._instance._isWKWebview;
    }

    public isWindows(): boolean {
        if (UtilsService._instance._isWindows == null) {
            UtilsService._instance._isWindows = this.$window.navigator.platform && (/win.+/i.test(this.$window.navigator.platform));
        }
        return UtilsService._instance._isWindows;
    }

    public isIE(): boolean {
        if (UtilsService._instance._isIE == null) {
            UtilsService._instance._isIE = this.$window.navigator.userAgent && (/Trident\/\d+\.\d+/i.test(this.$window.navigator.userAgent));
        }
        return UtilsService._instance._isIE;
    }

    public isEdge(): boolean {
        if (UtilsService._instance._isEdge == null) {
            UtilsService._instance._isEdge = this.$window.navigator.userAgent && (/Edge\/([0-9\._]+)/.test(this.$window.navigator.userAgent));
        }
        return UtilsService._instance._isEdge;
    }

    public isFirefox(): boolean {
        if (UtilsService._instance._isFirefox == null) {
            UtilsService._instance._isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
        }
        return UtilsService._instance._isFirefox;
    }

    public getWindowsVersion(): OSVersion {
        var version: OSVersion = { major: 0, minor: 0 };
        var ua: string = this.$window.navigator.userAgent;
        var matches: any = /Windows\sNT\s(\d+\.\d+)/.exec(ua);
        if (matches && matches.length > 1) {
            switch (matches[1]) {
                case '10.0':
                    version = { major: 10, minor: 0 };
                    break;
                case '6.3':
                    version = { major: 8, minor: 1 };
                    break;
                case '6.2':
                    version = { major: 8, minor: 0 };
                    break;
                case '6.1':
                    version = { major: 7, minor: 0 };
                    break;
            }
        }
        return version;
    }

    public getIOSVersion(): OSVersion {
        var version: OSVersion = this._parseVersion(/iP.+\sOS\s(\d+)_(\d+).+/i);
        return version;
    }

    public subtractDays (days: any) {
        const date = new Date();
        date.setDate(date.getDate() - days);
        const dateString = UtilsService.formatDate(date); // 2016-06-08
        return dateString;
    }

	/**
	 * Formats a date (eg: 2016-06-08)
	 * @param date
	 * @returns formatted date
	 */
	public static formatDate(date: Date): string {
		const year = date.getFullYear();
		const month = String(date.getMonth() + 1).padStart(2, '0');
		const day = String(date.getDate()).padStart(2, '0');
		return `${year}-${month}-${day}`;
	}

    public getMacOSVersion(): OSVersion {
        var version: OSVersion = this._parseVersion(/Mac\sOS.+\s(\d+)[._](\d+).+/i);
        return version;
    }

    private _parseVersion(regex:any): OSVersion {
        var version: OSVersion = { major: 0, minor: 0 };
        var matches: any = regex.exec(this.$window.navigator.userAgent);
        if (matches && matches.length > 2) {
            version = {
                major: parseInt(matches[1]),
                minor: parseInt(matches[2])
            };
        }
        return version;
    }

    private _visibleLog(params: any[]):void {
        const logBox: HTMLElement = document.getElementById(this._config.logBox);
        if (logBox) {
            var node = document.createElement('div');
            var output = '<strong>' + params[0] + '</strong> ';

            for (let i = 1; i < params.length; i++ ) {
                const info = params[i];
                if (typeof(info) !== 'string') {
                    output += info.toString();
                    for (const k in info) {
                        output += '-------- ' + k + ': ' + info[k] + '<br/>';
                    }
                } else {
                    output += info + ' | ';
                }
                node.innerHTML = output + '<hr/>';
            }

            logBox.insertAdjacentElement( 'afterbegin', node );
        }
    }
}
