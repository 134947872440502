import { CountdownTemplate } from './';
import { Player, Render } from '../index';

export class CountdownComponent {

    private _playerContainer: HTMLElement;
    private _animationId: any;
    private _strokeDashOffset: number = 314;
    private _countdownDuration: number;
    private _step: number;
    private _interval: number = 60; // 60 milliseconds

    public get countdownElement(): HTMLElement { return <HTMLElement> this._playerContainer.querySelector('.countdown-overlay'); }
    public get cancelButton(): HTMLElement { return <HTMLElement> this._playerContainer.querySelector('.countdown-cancel'); }
    public get closeButton(): HTMLElement { return <HTMLElement> this._playerContainer.querySelector('.countdown-close'); }
    public get playButton(): HTMLElement { return <HTMLElement> this._playerContainer.querySelector('.countdown-play'); }
    private get _svgContainer(): HTMLElement { return <HTMLElement> this._playerContainer.querySelector('.countdown-svg-container'); }
    private get _svgPath(): HTMLElement { return <HTMLElement> this._playerContainer.querySelector('.countdown-path'); }

    constructor(
        private _player: Player,
        private $document: Document = document,
        private $render: any = Render) {
        this._playerContainer = this._player.playerContainer;
        this._countdownDuration = this._player.config.countdown;
    }

    public render() {
        if (!this.countdownElement) {
            if (this._countdownDuration > 0) {
                const template: string = this.$render(CountdownTemplate, this._player.getNextPlaylistItem());
                this._player.jwOverlays.insertAdjacentHTML('afterend', template);
                this._step = parseFloat((this._strokeDashOffset / ((this._countdownDuration * 1000) / this._interval)).toFixed(2));
                this.cancelButton.focus();
                this._registerEventListeners();
                this._startCountdownAnimation();
            } else {
                this._playNext(false);
            }
        }
    }

    public remove = (evt?: Event) => {
        if (this.countdownElement) {
            if (evt) evt.preventDefault();
            this._stopCountdownAnimation();
            this._removeEventListners();
            this.countdownElement.parentElement.removeChild(this.countdownElement);
        }
    };

    private _cancel() {
        this._stopCountdownAnimation();
    }

    private _close() {
        this._player.closeCountdown();
        this.remove();
    }

    private _playNext(wasManual: boolean) {
        this._player.playNext(wasManual);
        this.remove();
    }

    private _registerEventListeners() {
        this.cancelButton.addEventListener('click', (e: Event) => this._cancel(), false);
        this.closeButton.addEventListener('click', (e: Event) => this._close(), false);
        this.playButton.addEventListener('click', (e: Event) => this._playNext(true), false);
        this._player.playlistItemChanged.subscribe((caller, e) => this._close());
    }

    private _removeEventListners() {
        this.cancelButton.removeEventListener('click', (e: Event) => this._cancel(), false);
        this.closeButton.removeEventListener('click', (e: Event) => this._close(), false);
        this.playButton.removeEventListener('click', (e: Event) => this._playNext(true), false);
    }

    private _startCountdownAnimation() {
        this._svgContainer.className += ' ' + 'start';
        let offset = this._strokeDashOffset;

        const offsetSvg = () => {
            if (offset > 0) {
                this._svgPath.setAttribute('stroke-dashoffset', `${offset}`);
                offset -= this._step;
            } else {
                this._stopCountdownAnimation();
                this._playNext(false);
            }
        };

        offsetSvg();
        this._animationId = setInterval(offsetSvg, this._interval);
    }

    private _stopCountdownAnimation() {
        const classes = this._svgContainer.getAttribute('class');
        this._svgContainer.className = classes.replace(/start/g, '');
        this._svgPath.setAttribute('stroke-dashoffset', `${this._strokeDashOffset}`);
        clearInterval(this._animationId);
    }

}
