var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ".jwplayer.jw-skin-corus .jw-knob {\n    border-color: "
    + alias4(((helper = (helper = lookupProperty(helpers,"themeColor") || (depth0 != null ? lookupProperty(depth0,"themeColor") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"themeColor","hash":{},"data":data,"loc":{"start":{"line":2,"column":18},"end":{"line":2,"column":32}}}) : helper)))
    + ";\n}\n\n.jwplayer.jw-skin-corus .jw-progress {\n    background-color: "
    + alias4(((helper = (helper = lookupProperty(helpers,"themeColor") || (depth0 != null ? lookupProperty(depth0,"themeColor") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"themeColor","hash":{},"data":data,"loc":{"start":{"line":6,"column":22},"end":{"line":6,"column":36}}}) : helper)))
    + ";\n}\n\n.jwplayer.jw-skin-corus .jw-nextup-container .jw-nextup-thumbnail:after {\n    background-color: rgba("
    + alias4(((helper = (helper = lookupProperty(helpers,"rgbColor") || (depth0 != null ? lookupProperty(depth0,"rgbColor") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"rgbColor","hash":{},"data":data,"loc":{"start":{"line":10,"column":27},"end":{"line":10,"column":39}}}) : helper)))
    + ", 0.85);\n}\n\n.playlist .playlist-content .playlist-item-figure-progress {\n    background-color: "
    + alias4(((helper = (helper = lookupProperty(helpers,"themeColor") || (depth0 != null ? lookupProperty(depth0,"themeColor") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"themeColor","hash":{},"data":data,"loc":{"start":{"line":14,"column":22},"end":{"line":14,"column":36}}}) : helper)))
    + ";\n}\n\n.playlist .playlist-content .playlist-item:hover .playlist-item-show{\n    color: "
    + alias4(((helper = (helper = lookupProperty(helpers,"themeColor") || (depth0 != null ? lookupProperty(depth0,"themeColor") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"themeColor","hash":{},"data":data,"loc":{"start":{"line":18,"column":11},"end":{"line":18,"column":25}}}) : helper)))
    + ";\n}\n\n.playlist .playlist-content--related .playlist-item:nth-child(2) .playlist-link:hover .playlist-item-show {\n    color: "
    + alias4(((helper = (helper = lookupProperty(helpers,"themeColor") || (depth0 != null ? lookupProperty(depth0,"themeColor") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"themeColor","hash":{},"data":data,"loc":{"start":{"line":22,"column":11},"end":{"line":22,"column":25}}}) : helper)))
    + ";\n}\n\n.videoplayerAuthMessage .videoplayerAuthMessage-copy .videoplayerAuthMessage-cta {\n    background: "
    + alias4(((helper = (helper = lookupProperty(helpers,"themeColor") || (depth0 != null ? lookupProperty(depth0,"themeColor") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"themeColor","hash":{},"data":data,"loc":{"start":{"line":26,"column":16},"end":{"line":26,"column":30}}}) : helper)))
    + ";\n}\n\n.videoplayerAuthMessage .videoplayerAuthMessage-copy {\n	background-color: rgba("
    + alias4(((helper = (helper = lookupProperty(helpers,"rgbColor") || (depth0 != null ? lookupProperty(depth0,"rgbColor") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"rgbColor","hash":{},"data":data,"loc":{"start":{"line":30,"column":24},"end":{"line":30,"column":36}}}) : helper)))
    + ", 0.5);\n}\n\n.jwplayer.jw-flag-live .jw-controlbar .jw-text-alt  {\n  position: relative;\n}\n\n.adBlockerMessage .videoErrorMessage-copy .icon-face:after {\n  color: "
    + alias4(((helper = (helper = lookupProperty(helpers,"themeColor") || (depth0 != null ? lookupProperty(depth0,"themeColor") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"themeColor","hash":{},"data":data,"loc":{"start":{"line":38,"column":9},"end":{"line":38,"column":23}}}) : helper)))
    + ";\n}\n\n.adBlockerMessage .videoErrorMessage-copy .videoErrorMessage-cta {\n  background-color: "
    + alias4(((helper = (helper = lookupProperty(helpers,"themeColor") || (depth0 != null ? lookupProperty(depth0,"themeColor") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"themeColor","hash":{},"data":data,"loc":{"start":{"line":42,"column":20},"end":{"line":42,"column":34}}}) : helper)))
    + ";\n}\n";
},"useData":true});