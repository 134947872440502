import { EventCallback, EventHandler } from '../../components/events';
import { PlaylistEntry } from '../../components/player';
import { UtilsService } from '../utils';

export interface ChapterEvent {
    chapterIndex: number,
}

export class ChaptersService {
    private static UPDATE_INTERVAL = 1;
    private chapterDurations: number[];
    private duration: number;
    private endTimes: number[];
    private isInitialized: boolean;
    private startTimes: number[];

    // The update method might get called multiple times per second,
    // which is unnecessary and expensive, so we need to limit it.
    private lastUpdate: number;

    // To avoid firing the chapter completed event again before the new chapter starts.
    private wasChapterCompletedFired = false;

    public chapterCompleted = new EventHandler<ChaptersService, ChapterEvent>();
    public chapterStarted = new EventHandler<ChaptersService, ChapterEvent>();
    public currentChapterDuration: number;
    public currentChapterIndex: number;
    public currentChapterOffset: number;
    public get totalChapters(): number { return this.isInitialized ? this.chapterDurations.length : 0; }

    public constructor(private _utils:UtilsService = UtilsService.Instance) {
        this.reset();
    }

    public initialize(playlistEntry: PlaylistEntry, startTime: number): void {
        this.duration = Math.floor(playlistEntry.metadata.duration);

        if (!this.isInitialized) {
            if (!playlistEntry.metadata.liveStream) {
                try {
                    const { metadata } = playlistEntry;
                    const startTimes: string = metadata ? metadata.chapterStartTimes : '';
                    const startTimeStrings: string[] = startTimes ? startTimes.split(',') : [];
                    this.startTimes = this.parseTimeStrings(startTimeStrings);
                    this.startTimes.unshift(0);

                    const endTimes: string = metadata ? metadata.chapterEndTimes : '';
                    const endTimeStrings: string[] = endTimes ? endTimes.split(',') : [];
                    this.endTimes = this.parseTimeStrings(endTimeStrings);
                    this.endTimes.push(this.duration);

                    this.calculateChapterDurations();
                } catch (error) {
                    this.startTimes = [0];
                    this.endTimes = [this.duration];
                }
            }
            else {
                this.startTimes = [0];
                this.endTimes = [this.duration];
                this.calculateChapterDurations();
            }

            this.isInitialized = true;
            this.update(startTime);
        }
    }

    public reset(): void {
        this.isInitialized = false;
        this.currentChapterDuration = 0;
        this.currentChapterIndex = -1;
        this.duration = 0;
        this.lastUpdate = -ChaptersService.UPDATE_INTERVAL;
        this.startTimes = [];
        this.endTimes = [];
        this.wasChapterCompletedFired = false;
    }

    public update(time: number, forced: boolean = false): void {
        if (this.isInitialized) {
            // On forced update, update chapter information regardless of time difference
            // between current time and last updated time
            if (forced || time - this.lastUpdate >= ChaptersService.UPDATE_INTERVAL || time < this.lastUpdate) {
                // snapped back, do nothing
                if (this.currentChapterIndex > 0 && !time) {
                    this._log('Snapped back after ad break. Ignore');
                    return;
                }

                const newChapterIndex = this.getChapterIndexForTime(time);

                this.lastUpdate = time;

                if (this.currentChapterIndex >= 0 && !this.wasChapterCompletedFired) {
                    const startTime: number = this.startTimes[this.currentChapterIndex];
                    const endTime: number = this.endTimes[this.currentChapterIndex];
                    if (endTime > 0 && ( time < startTime || time >= endTime)) {
                        this._log('chapter complete ' + this.currentChapterIndex);
                        this.chapterCompleted.fire(this, { chapterIndex: this.currentChapterIndex });
                        this.wasChapterCompletedFired = true;
                    }
                }

                if (newChapterIndex != this.currentChapterIndex) {
                    this.currentChapterIndex = newChapterIndex;
                    this.currentChapterDuration = this.chapterDurations[newChapterIndex];
                    this.currentChapterOffset = this.startTimes[newChapterIndex];
                    this.wasChapterCompletedFired = false;
                    this._log('chapter start ' + newChapterIndex);
                    this.chapterStarted.fire(this, { chapterIndex: newChapterIndex });
                }
            }

            // In case the user seeks really near the end of the clip.
            if (this.duration > 0 && time >= this.duration && !this.wasChapterCompletedFired) {
                this._log('chapter complete (near the end) ' + this.currentChapterIndex);
                this.chapterCompleted.fire(this, { chapterIndex: this.currentChapterIndex });
                this.wasChapterCompletedFired = true;
            }
        }
    }

    public getTimeByChapterIndex(chapterIdx: number):number {
        var time: number = -1;
        if ( chapterIdx < this.startTimes.length - 1 ) {
            time = this.startTimes[chapterIdx];
        }
        return time;
    }

    private calculateChapterDurations(): void {
        this.chapterDurations = [];
        for (let i = 0, { length } = this.startTimes; i < length; ++i) {
            this.chapterDurations[i] = this.endTimes[i] - this.startTimes[i];
        }
    }

    private getChapterIndexForTime(time: number): number {
        var result: number = 0;

        for (let i: number = this.startTimes.length - 1; i > 0; --i) {
            if (time >= this.startTimes[i]) {
                result = i;
                break;
            }
        }

        return result;
    }

    private parseTimeStrings(timeStrings: string[]): number[] {
        var result: number[] = [];

        for (let i: number = 0, { length } = timeStrings; i < length; ++i) {
            const timeString: string = timeStrings[i];
            const segments: string[] = timeString.split(':');
            result.push(this.parseTimeStringSegments(segments));
        }

        return result;
    }

    private parseTimeStringSegments(segments: string[]): number {
        var { length } = segments;
        var result: number = 0;

        if (length > 0) {
            result += parseInt(segments[length - 1], 10);
        }
        if (length > 1) {
            result += parseInt(segments[length - 2], 10) * 60;
        }
        if (length > 2) {
            result += parseInt(segments[length - 3], 10) * 3600;
        }

        return result;
    }

    private _log(message: string, info?: any) {
        this._utils.log('ChaptersService', message, info);
    }
}
