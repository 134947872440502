import { fetchJson } from './helpers';

interface EnvironmentUrls {
	[key: string]: string;
}

const ENVIRONMENT_URLS: EnvironmentUrls = {
    stage: 'https://corus-proxy-stage.herokuapp.com/api/container/v1',
    preprod: 'https://content-cdn.preprod.corusappservices.com/api/container/v1',
    prod: 'https://globalcontent.corusappservices.com/api/container/v1',
};

const DEFAULT_ENVIRONMENT = 'stage';

export interface VmsContentServiceParams {
    environmentName?: string,
    environmentUrl?: string
}

/**
 * Helper class to retrieve video data from the VMS content service.
 *
 * Often, the data retrieval should be done on the site level, since it is needed for metadata.
 * However, formatting the data for videoplayer usage can be helpful for consistency.
 */
export class VmsContentService {
    private _vmsContentUrl: string;

    // Construct a new VmsContentService class.
    // If the VMS Environment is a named environment, uses the predefined URL.
    // Otherwise, uses the environment param as the URL directly.
    constructor(params: VmsContentServiceParams = { environmentName: DEFAULT_ENVIRONMENT }) {
        if (params.environmentUrl) {
            this._vmsContentUrl = params.environmentUrl;
        } else if (params.environmentName) {
            this._vmsContentUrl = ENVIRONMENT_URLS[params.environmentName];
        } else {
            console.error('VMSContentService requires either environmentName or environmentUrl in its params object, if provided.');
        }
    }

    // Gets the chosen content item from the VMS.
    public async getContentItem(guid: string, origin: string = 'global') {
        const contentData = await fetchJson(`${this._vmsContentUrl}/?guid=${guid}&resource_types=thumbnail_small&origin=${origin}`, null, 'GET');
        return contentData.length ? contentData[0] : null;
    }

    // Gets the latest content matching the chosen brand and parameters.
    public async getContentItems(origin = 'global', params: any = { limit: 10, type__in: 'media__episode' }) {
        // Set origin separately so default params can be used for any brand.
        params.origin = origin;
        params.resource_types = 'thumbnail_small';
        const paramString = (new URLSearchParams(params)).toString();

        const contentData = await fetchJson(`${this._vmsContentUrl}/?${paramString}`, null, 'GET');

        return contentData.results ? contentData.results : contentData;
    }

    // Formats a returned result from VMS Content Service to match the general params used by the video player.
    // Note that not all arguments are set here - some from Realtime Services are required as well.
    public static formatContentItem(item: any) {
        const image = item.resources.find((r: any) => r.type === 'thumbnail') || null;
        const thumbnail = item.resources.find((r: any) => r.type === 'thumbnail_small') || null;

        const videoItem = {
			description: item.data.description,
			image: image ? image.uri : null,
			origin: item.origin,
			mediaId: item.guid || '0',
			metadata: {
				clipType: item.data.content_listing ? item.data.content_listing.toLowerCase() : null,
				duration: item.data.duration ? item.data.duration : 0,
				genre: item.data.genre,
				seasonNumber: item.data.season_number,
				episodeNumber: item.data.episode_number,
				episodeID: item.data.corus_s4m_episode_id,
				chapterStartTimes: item.data.chapter_start_times,
				chapterEndTimes: item.data.chapter_start_times,
				liveStream: item.type === 'channel',
				availableDate: item.data.available_date,
				keywords: item.data.keywords,
			},
			show: item.data.show_title,
			thumbnail: thumbnail && thumbnail.uri ? thumbnail.uri : null,
			title: item.data.title,
            iabCategories: item.data.iab?.categories ?? '',
            iabTags: item.data.iab?.tags ?? '',
        };

        return videoItem;
    }
}
