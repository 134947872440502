import { Player, PlayerSubscriber } from './player';
import { XhrService } from '../services';

type Log = { [key: string]: string };

export interface SplunkLoggingConfig {
    account: string,
    doLogAds?: boolean,
    interval?: number,
    maxBufferSize?: number,
    maxMessageSize?: number,
    url: string,
}

export interface SplunkLoggingDependencies {
    $window?: Window,
    xhrService?: XhrService,
}

export class SplunkLoggingSubscriber implements PlayerSubscriber {
    private static MESSAGE_SEPARATOR: string = '##'; // defined in the consuming service
    private _logBuffer: Log[] = [];

    public constructor(private config: SplunkLoggingConfig, private dependencies: SplunkLoggingDependencies) {
        this.config.interval = this.config.interval || 10000;
        this.config.maxBufferSize = this.config.maxBufferSize || 20;
        this.config.maxMessageSize = this.config.maxMessageSize || 10;

        this.dependencies = this.dependencies || {};
        this.dependencies.$window = this.dependencies.$window || window;
        this.dependencies.xhrService = this.dependencies.xhrService || new XhrService();

        this.scheduleFlush();
    }

    public bindTo(player: Player): void {
        player.adFailed.subscribe((caller, e) => this.onAdFailed(caller, e));
        player.playbackFailed.subscribe((caller, e) => this.onPlaybackFailed(caller, e));
        player.setupFailed.subscribe((caller, e) => this.onSetupFailed(caller, e));
    }

    private flushBuffer(doSchedule: boolean): void {
        if (doSchedule) {
            this.scheduleFlush();
        }
        while (this._logBuffer.length > 0) {
            const logs: Log[] = this._logBuffer.splice(0, this.config.maxMessageSize);
            this.sendLogs(logs);
        }
    }

    private log(log: Log): void {
        this._logBuffer.push(log);
        if (this._logBuffer.length >= this.config.maxBufferSize) {
            this.flushBuffer(false);
        }
    }

    private onAdFailed(caller: Player, e: AdErrorOptions): void {
        if (this.config.doLogAds) {
            this.log({ message: e.message, tag: e.tag, type: 'ad' });
        }
    }

    private onPlaybackFailed(caller: Player, e: ErrorOptions): void {
        this.log({ message: e.message, type: 'playback' });
    }

    private onSetupFailed(caller: Player, e: ErrorOptions): void {
        this.log({ message: e.message, type: 'setup' });
    }

    private prepareLog(log: Log): string {
        var pairs: string[] = [];
        log['account'] = this.config.account;
        log['player'] = 'videoplayer';
        log['ua'] = this.dependencies.$window.navigator.userAgent.toString();
        log['url'] = this.dependencies.$window.location.toString();
        log['version'] = Player.VERSION;
        Object.keys(log).forEach((key: string) => {
            pairs.push(key + '=' + encodeURIComponent(log[key]));
        });
        return pairs.join(',');
    }

    private scheduleFlush(): void {
        this.dependencies.$window.setTimeout(() => this.flushBuffer(true), this.config.interval);
    }

    private sendLogs(logs: Log[]): void {
        try {
            var preparedLogs: string[] = [];
            logs.forEach((log: Log) => {
                preparedLogs.push(this.prepareLog(log));
            });

            var query = 'log=' + preparedLogs.join(encodeURIComponent(SplunkLoggingSubscriber.MESSAGE_SEPARATOR));
            this.dependencies.xhrService.post(this.config.url, query);
        } catch (exception) {
            console.log('Could not send error report.', exception);
        }
    }
}
