import { UtilsService } from '..';
import { EventHandler } from '../../components/events';

export interface AuthenticationServiceConfig {
    siteAuth: any,
    signInUrl: string,
    handler?: any,
    authorizationHandler ?: any,
}

export class AuthenticationService {
    private _config: AuthenticationServiceConfig;
    private _siteAuth: any;

    public authTokenRetrieved = new EventHandler<AuthenticationService, string>();
    public authTokenFailed = new EventHandler<AuthenticationService, string>();

    public constructor( config?: AuthenticationServiceConfig,
                        private $window: Window = window,
                        private _utils: UtilsService = UtilsService.Instance) {
        if (config) {
            this.setup(config);
        }
    }

    public setup(config: AuthenticationServiceConfig) {
        this._config = config;
        this._siteAuth = this._config.siteAuth;
        this._siteAuth.setCallback({
            caller: this,
            setTokenCallback: this.onSetToken,
            tokenRequestFailedCallback: this.onTokenRequestFailed,
        });
    }

    public signIn():void {
        this._siteAuth.setRedirectUrl(this.$window.location.href, this._config.signInUrl);
    }

    public requestAuthorization(): void {
        this._log('requestAuthorization()', 'initiate authorization to generate token');
        this._siteAuth.getAuthorization();
    }

    public isAuthenticated(): boolean | Promise<any> {
        return this._siteAuth.isAuthenticatedUser();
    }

    // Public function for callback from siteAuth

    public onSetToken(token: string): void {
        this._log('AdobePass callback onSetToken', token);
        this.authTokenRetrieved.fire(this, token);
    }

    public onTokenRequestFailed(): void {
        this._log('AdobePass callback', 'token request failed');
        this.authTokenFailed.fire(this, '');
    }

    // Private methods

    private _log(message: string, info?: any) {
        this._utils.log('authenticationService', message, info);
    }
}
