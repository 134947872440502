import { ThemeTemplate } from './index';
import { Render } from '../index';

export interface ThemeDependencies {
    $document?: Document,
    $render?: any
}

export class ThemeComponent {
    private $document: Document;
    private $render: any;

    constructor(private _color: string, dependencies: ThemeDependencies) {
        this.$document = dependencies.$document || window.document;
        this.$render = dependencies.$render || Render;
        this._render();
    }

    private _render() {
        var css = this.$render(ThemeTemplate, {
            themeColor: this._color,
            rgbColor: this._hexToRgb(this._color)
        });
        var style = this.$document.createElement('style');
        style.type = 'text/css';
        style.appendChild(document.createTextNode(css));
        this.$document.head.appendChild(style);
    }

    private _hexToRgb(hex: string):string {
        const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
        hex = hex.replace(shorthandRegex, function(m, r, g, b) {
            return r + r + g + g + b + b;
        });

        const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        const rgbColor = result ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16)
        } : null;
        return [rgbColor.r, rgbColor.g, rgbColor.b].join();
    }
}
