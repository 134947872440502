interface FetchParams {
    body?: string,
    headers?: any,
    method?: string,
}

// Helper method to perform a request and decode the response JSON.
export async function fetchJson(url: string, body: object = null, method = 'POST') {
    const params: FetchParams = {
        method,
        headers: {
            'Content-Type': 'application/json',
        },
    };

    if (body) {
        params.body = JSON.stringify(body);
    }

    const response = await fetch(url, params);
    return await response.json();
}

// Helper method to extract the data from a JWT.
export function decodeJwt(token: string): string {
    // TODO: validate signature (in position 2) or use a JWT library?
    return atob(token.split('.')[1]);
}
