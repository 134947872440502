module.exports = (duration: any): any => {

	let result: any;

	if (isNaN(duration)) return duration;

	const hours = Math.floor(duration / 60 / 60);
	const minutes = Math.floor((duration / 60) % 60);
	const seconds = Math.floor(duration % 60);

	result = (hours == 0) ? '' : (hours > 0 && hours < 10) ? `0${hours}:` : `${hours}:`;
	result += minutes < 10 ? '0' + minutes : minutes;
	result += ':' + (seconds < 10 ? '0' + seconds : seconds);

	return result;
};
