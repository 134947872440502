import { BeforePlayingEvent, ChapterEvent, PlayerSubscriber, Player, PlayEvent } from './../player';
import { StreamsenseManager } from './streamsenseManager';
import { ComscoreConfig } from './comscoreConfig';

export class ComscoreSubscriber implements PlayerSubscriber {
    private _isStarted = false;
    private _adPaused = false;
    private _player: Player;

    // Custom flags needed for DAI tracking.
    private _cachedDaiAdStart = false;
    private _cachedDaiAdResumed = false;

    public constructor(private _config: ComscoreConfig, private $window: Window = window, private _manager: StreamsenseManager = null) {
        return;
    }

    public bindTo(player: Player): void {
        if (this._player) {
            throw new Error('Subscriber is already bound.');
        }

        if (!this._manager) {
            const secured: boolean = /^https:/.test(this.$window.location.href);
            this._manager = new StreamsenseManager(this._config, player, secured);
        }

        this._player = player;

        player.played.subscribe((caller, e) => this.onPlayed(caller, e));
        player.pausing.subscribe((caller, e) => this.onPausing(caller, e));
        player.adStarted.subscribe((caller, e) => this.onAdStarted(caller, e));
        player.adCompleted.subscribe((caller, e) => this.onAdCompleted(caller, e));
        player.adPaused.subscribe((caller, e) => this.onAdPaused(caller, e));
        player.adResumed.subscribe((caller, e) => this.onAdResumed(caller, e));
        player.completed.subscribe((caller, e) => this.onCompleted(caller, e));
        player.buffering.subscribe((caller, e) => this.onBuffering(caller, e));
        player.seeking.subscribe((caller, e) => this.onSeeking(caller, e));
        player.seeked.subscribe((caller, e) => this.onSeeked(caller, e));
        player.chapterStarted.subscribe((caller, e) => this.onChapterStarted(caller, e));

        // This is needed for DAI, player.played fail to fire.
        player.adFirstFrame.subscribe((caller, e) => this.onAdFirstFrame(caller, e));
        player.contentResumed.subscribe((caller, e) => this.onPlayed(caller, e));

        player.playlistItemChanged.subscribe((caller, e) => this.onPlaylistItemChanged(caller, e));

        // End video session when playback failed (so that hb calls stops being called)
        player.playbackFailed.subscribe((caller, e) => this.onCompleted(caller, e));
    }

    private onPausing(caller: Player, e: any): void {
        this._manager.trackPause();
    }

    private onPlayed(caller: Player, e: PlayEvent): void {
        this._manager.trackPlay();
    }

    private onChapterStarted(caller: Player, e: ChapterEvent): void {
        this._manager.trackChapterStart(e);
    }

    private onAdStarted(caller: Player, e: AdImpressionOptions): void {
        if ( 'dai' === e.client ) {
            this._cachedDaiAdStart = true;
            return;
        }

        this._manager.trackAdStart(e);
    }

    private onAdFirstFrame(caller: Player, e: any): void {
        if ( 'dai' !== e.client ) {
            return;
        }

        this._adPaused = false;

        if ( this._cachedDaiAdStart ) {
            this._manager.trackAdStart(e);
            this._cachedDaiAdStart = false;
        }

        if ( this._cachedDaiAdResumed ) {
            this._manager.trackAdResume(e);
            this._cachedDaiAdStart = false;
        }
    }

    private onAdPaused(caller: Player, e: AdPauseResumeOptions): void {
        this._adPaused = true;
        this._manager.trackAdPause(e);
    }

    private onAdResumed(caller: Player, e: AdPauseResumeOptions): void {
        // Cache adResumed event for DAI ad start tracking to capture the duration.
        // If the ad was paused by the user, then there is no need to cache the event
        // as the duration has already been set in previous events.
        if ( 'dai' === e.client && ! this._adPaused ) {
            this._cachedDaiAdResumed = true;
            return;
        }

        this._adPaused = false;
        this._manager.trackAdResume(e);
    }

    private onAdCompleted(caller: Player, e: AdCompleteOptions): void {
        this._manager.trackAdComplete(e);
    }

    private onCompleted(caller: Player, e: any): void {
        this._manager.trackComplete();
    }

    private onBuffering(caller: Player, e: any): void {
        this._manager.trackBuffering();
    }

    private onSeeking(caller: Player, e: SeekEvent): void {
        this._manager.trackSeeking(e);
    }

    private onSeeked(caller: Player, e: any): void {
        this._manager.trackSeeked();
    }

    private onPlaylistItemChanged(caller: Player, e: any): void {
        if (this._isStarted) {
            this._manager.trackComplete();
            this._manager.reset();
            this._isStarted = false;
        }

        this.start();
    }

    private start() {
        this._isStarted = true;
        this._manager.setup();
    }
}
