import { UtilsService, XhrService, LocalStorageService } from '..';
import { Player, PlaylistEntry, VmsRealtimeService } from '../../components/player';
import { EventHandler } from '../../components/events';


export interface LockControl {
    clientId: string,
    lockId: string,
    lock: string,
    serviceUrl: string,
    sequenceToken: string
}

export class ConcurrencyService {
    private static _instance: ConcurrencyService;

    private _sessionId: string;
    private _mediaId: string;
    private _duration: number;
    private _initialized: boolean = false;
    private _started: boolean = false;
    private _timer: any;

    private DATA_ID: string = '_ce_concurrency';
    private UPDATE_INTERVAL: number = 15000;

    public get initialized(): boolean { return this._initialized; }
    public get started(): boolean { return this._started; }

    public concurrencyErrorDetected = new EventHandler<ConcurrencyService, any>();

    constructor(private _util: UtilsService = UtilsService.Instance,
                private _localStorageService: LocalStorageService = LocalStorageService.Instance) {
    }

    public static get Instance(): ConcurrencyService {
        if (!ConcurrencyService._instance) {
            ConcurrencyService._instance = new ConcurrencyService();
        }
        return ConcurrencyService._instance;
    }

    public initialize(sessionId: string, mediaId: string, duration: number): void {
        this._sessionId = sessionId;
        this._mediaId = mediaId;
        this._duration = duration;
        this._log('initialize', { session: this._sessionId } );

        this._saveLock();
        this._initialized = true;
    }

    public start(): void {
        if (!this._started) {
            this._log('start service');
            this._timer = setInterval(() => this._updateService(), this.UPDATE_INTERVAL);
            this._started = true;
        }
    }

    public stop(): void {
        this._log('stop service');
        if (this._timer) {
            clearInterval(this._timer);
        }

        this.reset();
        this._started = false;
    }

    public reset(): void {
        this._log('reset()');

        var savedLock: string = this._getSavedLock();
        if (savedLock) {
            this._log('reset()', 'Clean up any old lock');
            this._unlockService(savedLock);
        }
        this._localStorageService.deleteDataById(this.DATA_ID);
    }

    // Private Methods

    private _saveLock(): void {
        this._log('save lock', this._sessionId);
        this._localStorageService.saveData(this.DATA_ID, this._sessionId);
    }

    private _getSavedLock(): string {
        var sessionId: string = this._localStorageService.getDataById(this.DATA_ID);

        this._log('get lock', sessionId);
        return sessionId;
    }

    private async _updateService(): Promise<void> {
        try {
            // TODO: get session progress from playing video
            VmsRealtimeService.touchSession(this._sessionId, this._getVideoProgress());
        } catch (error) {
            this._log('session expired remotely', this._sessionId);
            this.stop();
        }
    }

    private async _unlockService(sessionId: string): Promise<void> {
        this._log('Call unlock service', sessionId);
        const responseData = await VmsRealtimeService.closeSession(sessionId, this._getVideoProgress());
        this._log('Unlock service called', responseData);
    }

    private _log(message: string, info?: any): void {
        this._util.log('ConcurrencyService', message, info);
    }

    // TO GET THE CURRENT VIDEO POSITION AND MAINTAIN VMS KNOW THE VALUE
    private _getVideoProgress(): number {
        let sessionProgress = 0.0;
        if (this._mediaId && this._duration) {
            const position = this._localStorageService.get(this._mediaId);
            if (position && position.value) {
                sessionProgress = position.isCompleted ? 1.0 : (position.value / this._duration * 100) / 100;
                return sessionProgress;
            }
        }
        return sessionProgress;
    }
}
