import { UtilsService } from '../utils';
import { Player } from '../../components/player';
import { LocalStorageService, Service } from '..';

export class PermutiveService implements Service {
    private jwplayerInstance: JWPlayer;

    private player: Player;

    private video: any = {};

    private ad: any = null;

    private videoStarted: boolean = false;

    private contentPaused: boolean = true;

    private isNaturalCountdown: boolean = false;

    private isLiveStream: boolean = false;

    // Ad pod number is incremented at the beginning of every ad break.
    // DAI ad pod should start at 10 and thus the initial value should be 9.
    private daiAdPod: number = 9;

    private progressMarkers: any = [ 25, 50, 75 ];

    public constructor(
		private customDataCallback: any = false,
		private $window:Window = window,
		private _util:UtilsService = UtilsService.Instance,
		private _localStorage: LocalStorageService = LocalStorageService.Instance
	) {}

    public register( jwplayerInstance: JWPlayer, player: Player ): void {
        // Bail if permutive isn't available.
        if ( ! this.$window.hasOwnProperty( 'permutive' ) ) {
            return;
        }

        // Standard jw player events.
        jwplayerInstance.on('adItem', (e: any) => this.onAdItem(e));
        jwplayerInstance.on('adPause', (e: any) => this.onAdPaused(e));
        jwplayerInstance.on('adBreakEnd', (e: any) => this.onAdBreakEnded(e));
        jwplayerInstance.on('adPlay', (e: any) => this.onAdPlay(e));
        jwplayerInstance.on('adSkipped', (e: any) => this.onAdSkipped(e));
        jwplayerInstance.on('adClick', (e: any) => this.onAdClick(e));
        jwplayerInstance.on('adComplete', (e: any) => this.onAdComplete(e));
        jwplayerInstance.on('adError', (e: any) => this.onAdError(e));
        jwplayerInstance.on('adTime', (e: any) => this.onAdTime(e));
        jwplayerInstance.on('beforePlay', () => this.onBeforePlay());
        jwplayerInstance.on('seek', (e: any) => this.onSeek(e));
        jwplayerInstance.on('seeked', () => this.onSeeked());
        jwplayerInstance.on('play', (e: any) => this.onPlay(e));
        jwplayerInstance.on('pause', (e: any) => this.onPause(e));
        jwplayerInstance.on('time', (e: any) => this.onTime(e));
        jwplayerInstance.on('complete', () => this.onComplete());
        jwplayerInstance.on('fullscreen', (e: any) => this.onFullscreen(e));
        jwplayerInstance.on('mute', () => this.onMute());
        jwplayerInstance.on('playlistItem', (e: any) => this.onPlaylistItem(e));

        // Custom events specific to the corus video player.
        player.countdownCompleted.subscribe( (caller, e) => this.onCountdownCompleted(caller, e) );

        this.jwplayerInstance = jwplayerInstance;
        this.player = player;
    }

    private onAdPaused( e: any ) {
        this.track( 'VideoAdEvent', this.video, this.ad, 'VideoPause' );
    }

    private onAdPlay( e: any ) {
        // Ad starts will be handled via onAdTime for DAI.
        if ( 'dai' === e.client ) {
            return;
        }
        this.trackAdStart( e );
    }

    private trackAdStart( e: any ) {
        this.contentPaused = true;

        // Only track the ad if ad ID is available.
        if ( this.ad.id ) {
            this.track( 'VideoAdPlay', this.video, this.ad );
        }
    }

    private onAdTime( e: any ) {
        if ( 'dai' === e.client && ! this.ad ) {
            this.setupAdItem( e );

            // Manually keep track of ad pod number for DAI.
            if ( ! this.contentPaused ) {
                this.daiAdPod++;
                this._log( 'onAdPlay update ad pod number', this.daiAdPod );
            }

            this.ad.pod = this.daiAdPod;
            this.trackAdStart( e );
        }

        this.contentPaused = true;
        this.trackProgress( e, 'ad' );
    }

    private onAdSkipped( e: any ) {
        this.track( 'VideoAdEvent', this.video, this.ad, 'VideoAdSkipped' );
        this.ad = null;
    }

    private onAdClick( e: any ) {
        this.track( 'VideoAdClick', this.video, this.ad );
    }

    private onAdComplete( e: any ) {
        // Only track the ad if ad ID is available.
        if ( this.ad.id ) {
            this.ad.progress = 100;
            this.track( 'VideoAdProgress', this.video, this.ad );
        }

        // Clear ad upon ad completion.
        this.ad = null;
    }

    private onAdError( e: any ) {
        // Clear ad upon ad completion.
        this.ad = null;
    }

    private onAdBreakEnded( e: any ) {
        this.ad = null;
    }

    private onBeforePlay() {
        // Bail if the player has already been removed (e.g. ad blocker message shown)
        if ( this.player.isRemoved ) {
            return;
        }

        if ( ! this.videoStarted ) {
            this.videoStarted = true;
            this.track( 'VideoLoad', this.video );
        }
    }

    private onPlay( e: any ) {
        if ( this.contentPaused ) {
            this.contentPaused = false;
            this.track( 'VideoPlay', this.video );
        }
    }

    private onPause( e: any ) {
        this.contentPaused = true;
        this.track( 'VideoEvent', this.video, false, 'VideoPause' );
    }

    private onSeek( e: any ) {
        if ( ! this.isLiveStream ) {
            this.track( 'VideoEvent', this.video, false, 'VideoSeek' );
        }
    }

    private onSeeked() {
        if ( ! this.isLiveStream ) {
            this.track( 'VideoEvent', this.video, false, 'VideoSeeked' );
        }
    }

    private onTime( e: any ) {
        if ( this.video.duration ) {
            this.trackProgress( e );
        } else {
            // Live stream fails to trigger "play" event when DAI is enabled.
            // Track video play here.
            if ( this.contentPaused ) {
                this.contentPaused = false;
                this.track( 'VideoPlay', this.video );
            }
        }
    }

    private onComplete() {
        this.video.progress = 100;
        this.track( 'VideoProgress', this.video );
    }

    private onFullscreen( e: any ) {
        if ( this.ad ) {
            this.track( 'VideoAdEvent', this.video, this.ad, 'VideoFullscreen' );
        } else {
            this.track( 'VideoEvent', this.video, false, 'VideoFullscreen' );
        }
    }

    private onMute() {
        if ( this.ad ) {
            this.track( 'VideoAdEvent', this.video, this.ad, 'VideoMute' );
        } else {
            this.track( 'VideoEvent', this.video, false, 'VideoMute' );
        }
    }

    private onCountdownCompleted( caller: Player, e: any ) {
        this._log( 'countdown completed', e );
        this.isNaturalCountdown = ! e.wasManual;
    }

    private trackProgress( e: any, type: string = '' ) {
        const progress = ( e.position / e.duration ) * 100;
        const targetData = 'ad' === type ? this.ad : this.video;
        for ( let i = 0; i < this.progressMarkers.length; i++ ) {
            const marker = this.progressMarkers[i];
            if ( progress > marker && targetData && ( ! targetData.progress || marker > targetData.progress ) ) {
                this._log( 'track progress', marker );
                if ( 'ad' === type ) {
                    if ( this.ad.id ) {
                        this.ad.progress = marker;
                        this.track( 'VideoAdProgress', this.video, this.ad );
                    }
                } else {
                    this.video.progress = marker;
                    this.track( 'VideoProgress', this.video );
                }
                break;
            }
        }
    }

    private onAdItem( e: any ) {
        // Bail if ad already defined via previous event.
        if ( this.ad ) {
            return;
        }

        this.setupAdItem( e );
    }

    private setupAdItem( e: any ) {
        this.ad = {
            name: e.adtitle,
            duration: Math.round( e.duration ),
            id: e.adId
        };

        if ( e.ima && e.ima.ad ) {
            // creative id.
            const creativeId:number = e.ima.ad.getCreativeId();
            if ( creativeId ) {
                this.ad.creativeId = creativeId;
            }

            // ad pod index and ad position.
            const adPodInfo:any = e.ima.ad.getAdPodInfo();
            if ( adPodInfo ) {
                this.ad.pod = adPodInfo.getPodIndex() + 1;
                this.ad.position = adPodInfo.getAdPosition();
            }

        } else if ( 'dai' === e.client ) {
            this.ad.id = e.id;
            this.ad.position = e.sequence ? e.sequence : 1;

            // Note: Ad pod number returns the same number for all pods, set it manually on ad start.
        }
    }

    private onPlaylistItem( e: any ) {
        const itemRedirectURL = e.item.redirectUrl;

        //  Remember the number of videos watched.
        const bingeWatched = this.video.bingeWatched ? this.video.bingeWatched + 1 : 1;

        // Reset video object.
        this.video = {
            name: '',
            show: '',
            type: '',
            duration: 0,
            bingeWatched: bingeWatched,
        };

        // Populate "bingeWatched" field for non-live streams
        // when player plays next video with by redirecting to a new page
        this.isLiveStream = /live/.test( this.video.type || e.item.metadata.clipType );
        if ( ! this.isLiveStream && itemRedirectURL ) {
            // Check if there video object has been saved in local storage to properly update bingeWatched number.
            const savedItem = JSON.parse(this._localStorage.getDataById('items'));
            if ( savedItem ) {
                // if go to different show OR new bingewatched, reset bingeWatched to 1
                if ( ! savedItem.bingeWatched || savedItem.show !== e.item.show ) {
                    this.video.bingeWatched = 1;
                } else {
                    // if same show but different season/episode values OR from news, increment bingewatched
                    if (
                        savedItem.show === e.item.show &&
                        (parseInt(e.item.metadata.seasonNumber) !== savedItem.season || parseInt(e.item.metadata.episodeNumber) !== savedItem.episode)
                    ) {
                        this.video.bingeWatched = savedItem.bingeWatched + 1;
                    }
                }
            }
        }

        this.video.name = e.item.title;
        this.videoStarted = false;

        // Reset progress.
        delete this.video.progress;

        // For the first video in the current session,
        // report autoplay based on the autostart configuration.
        // For subsequent video, report autoplay based on whether or not
        // the video played automatically resulting from the next video countdown.
        if ( 0 === e.index ) {
            this.video.autoplay = this.player.config.jwOptions.autostart;
        } else {
            this.video.autoplay = this.isNaturalCountdown;
            this.isNaturalCountdown = false;
        }

        if ( e.item.metadata ) {
            if ( e.item.metadata.duration ) {
                this.video.duration = parseInt( e.item.metadata.duration, 10 );
            }

            if ( e.item.metadata.episodeNumber ) {
                this.video.episode = parseInt( e.item.metadata.episodeNumber, 10 );
            }

            if ( e.item.metadata.seasonNumber ) {
                this.video.season = parseInt( e.item.metadata.seasonNumber, 10 );
            }

            if ( e.item.metadata.genre ) {
                this.video.genre = e.item.metadata.genre;
            }

            if ( e.item.metadata.clipType ) {
                this.video.type = e.item.metadata.clipType;
            }
        }

        // Report show name.
        if ( e.item.show ) {
            this.video.show = e.item.show;
        }

        // Report keywords.
        if ( e.item.keywords ) {
            this.video.keywords = [];
            const keywords = e.item.keywords.split( ',' );
            for ( let i = 0; i < keywords.length; i++ ) {
                const keyword = keywords[i].trim();
                if ( keyword ) {
                    this.video.keywords.push( this.slugify( keywords[i].trim().toLowerCase() ) );
                }
            }
        }

        // Report IAB categories and tags
        if ( e.item.iabCategories || e.item.iabTags ) {
            this.video.iab = {};
            if ( e.item.iabCategories ) {
                this.video.iab.categories = e.item.iabCategories;
            }

            if ( e.item.iabTags ) {
                this.video.iab.tags = e.item.iabTags;
            }
        }

        // Report video categories as keywords.
        if ( e.item.categories ) {
            if ( ! this.video.keywords ) {
                this.video.keywords = [];
            }

            for ( let i = 0; i < e.item.categories.length; i++ ) {
                let categoryName = e.item.categories[i].name;
                const categoryParts = categoryName.split( '/' );
                categoryName = this.slugify( categoryParts[ categoryParts.length - 1 ].trim().toLowerCase() );

                if ( this.video.keywords.indexOf( categoryName ) < 0 ) {
                    this.video.keywords.push( categoryName.toLowerCase() );
                }
            }
        }

        this._localStorage.saveData('items', JSON.stringify(this.video));
        this._log( 'playlist item', this.video );
    }

    private slugify( str: any ) {
        str = str.replace( /[^a-z0-9 -]/g, '' );
        str = str.replace( /\s+/g, '-' );
        str = str.replace( /-+/g, '-' );

        return str;
    }

    private track( event: string, video: any = null, ad: any = null, customEvent: string = '' ) {
        let data: any = {};
        const muted: boolean = this.jwplayerInstance.getMute();
        const fullscreen: boolean = this.jwplayerInstance.getFullscreen();

        if ( video ) {
            // Clone video object.
            data.video = JSON.parse( JSON.stringify( video ) );
            data.video.muted = muted;
            data.video.fullscreen = fullscreen;
        }

        if ( ad ) {
            // Clone ad object.
            data.ad = JSON.parse( JSON.stringify( ad ) );
            data.ad.muted = muted;
            data.ad.fullscreen = fullscreen;
        }

        if ( customEvent ) {
            data.event = customEvent;
        }

        // Get custom video metadata.
        if ( this.customDataCallback ) {
            data = this.customDataCallback.call( null, data, this.player );
        }

        // Only report progress for progress events.
        if ( 'VideoProgress' !== event && 'VideoAdProgress' !== event ) {
            delete data.video.progress;
            if ( data.ad ) {
                delete data.ad.progress;
            }
        }

        this._log( 'track ' + event, data );
        this.$window['permutive'].track( event, data );
    }

    private _log( message: string, info?: any ): void {
        this._util.log( 'Permutive', message, info );
    }
}
