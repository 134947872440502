export * from './errorMessage.component';
export const GenericMessageTemplate = require('./genericMessage.html');

export interface ErrorMessageModel {
    blockPlayerOnly?: boolean
    image?: string,
    link?: string,
    linkTitle?: string,
    linkTarget?: string,
    message: string,
    skin?: string,
    title: string,
    type?: string
}

export class ErrorMessageType {
	public static VIDEO_NOT_FOUND: string = 'videoNotFound';
}