import { AdBlockerMessageModel, AdBlockerMessageTemplate } from '.';
import { Player, PlayerSubscriber, Render } from '../player';
import { EventHandler } from '../events';
import { UtilsService } from '../../services';

interface AdBlockConfig {
    container: string,
    message?: string,
    title?: string,
    ctaLabel?: string,
    ctaLink: string,
    linkTarget: string
}

export class AdBlockSubscriber implements PlayerSubscriber {
    private DEFAULT_TITLE: string = 'We noticed you have an ad blocker enabled.';
    private DEFAULT_MESSAGE: string = '<p>Ads keep our content available online for all to enjoy so please turn off any ad blockers to keep watching.';
    private DEFAULT_CTA_LABEL: string = 'Disable Ad Blocker Now';
    private DEFAULT_LINK_TARGET: string = '_blank';
    private AD_BLOCKER_DETECTED_COOKIE: string = 'videoAdBlockerDetected';

    public adBlockerEnabled = new EventHandler<AdBlockSubscriber, any>();
    public adBlockerDisabled = new EventHandler<AdBlockSubscriber, any>();

    private get _containerElement():HTMLElement { return <HTMLElement> this.$window.document.querySelector(this.config.container); }

    public constructor(private config: AdBlockConfig,
                        private $window: Window = window,
                        private $render: any = Render,
                        private _utils: UtilsService = UtilsService.Instance) {
    }

    public bindTo(player: Player): void {
        player.adBlockingDetected.subscribe((player: Player, e: any) => this.onAdBlockingDetected(player, e));
        player.adStarted.subscribe((player: Player, e: any) => this.onAdStarted(player, e));
    }

    private isForcedDisable():boolean {
        var forcedDisable: boolean = false;
        var abTestAvaialble: boolean = this.$window['abTest'] != null && this.$window['abTest']['blockAdBlocker'] != null;
        if ( abTestAvaialble ) {
            forcedDisable = !this.$window['abTest']['blockAdBlocker'];
        }
        return forcedDisable;
    }

    private onAdBlockingDetected(player: Player, e: any): void {
        if (this.isForcedDisable()) return;

        var image: string = null;
        var skin: string = null;

        if (player.currentMedia) {
            image = player.currentMedia.image;
        }
        if (player.config.jwOptions.skin.name) {
            skin = 'jw-skin-' + player.config.jwOptions.skin.name;
        }

        player.remove('Ad blocking detected');

        this._log('Ad Blocker Detected.');
        this._utils.writeCookie(this.AD_BLOCKER_DETECTED_COOKIE, '1');
        this.adBlockerEnabled.fire(this, {});

        this.render(image, skin);
    }

    private onAdStarted(player: Player, e: any): void {
        if (this.isForcedDisable()) return;

        // No ad blocker detected, playback started successfully
        if (this._utils.readCookie(this.AD_BLOCKER_DETECTED_COOKIE) === '1') {
            this._log('Ad Blocker was on, now turned off');
            this._utils.eraseCookie(this.AD_BLOCKER_DETECTED_COOKIE);
            this.adBlockerDisabled.fire(this, {});
        }
    }

    private render(image: string, skin: string): void {
        if (this._containerElement) {
            const model: AdBlockerMessageModel = {
                image: image,
                message: this.config.message ? this.config.message : this.DEFAULT_MESSAGE,
                skin: skin,
                title: this.config.title ? this.config.title : this.DEFAULT_TITLE,
                ctaLabel: this.config.ctaLabel ? this.config.ctaLabel : this.DEFAULT_CTA_LABEL,
                ctaLink: this.config.ctaLink,
                linkTarget: this.config.linkTarget ? this.config.linkTarget : this.DEFAULT_LINK_TARGET
            };
            const html = this.$render(AdBlockerMessageTemplate, model);
            this._containerElement.insertAdjacentHTML('afterbegin', html);
        }
    }

    private _log(message: string, info?: any) {
        this._utils.log('AdBlock', message, info);
    }
}
