// App Entry

import { AdConfig, AdService, ChapterEvent, ChaptersService, ChartbeatService, PermutiveService, LocalStorageService, XhrService, UtilsService, ContentRestrictionService, GeoContentDetectionService, GeoContentDetectionConfig, GeoContent, ContentRestrictionServiceConfig } from '../services';
import '../../libs';
import { EventHandler } from './events';
import { DateFormatter } from './dates';
import { PlatformRelatedContentConfig, PlatformRelatedContentComponent } from './platform.component';
import { ErrorMessageModel, ErrorMessageComponent, ErrorMessageType } from './errorMessage';
import { ContentRestrictionComponent, ContentRestrictionComponentConfig } from './contentRestriction';
import { RedirectionComponent } from './redirection.component';
import { CountdownComponent, NextUpComponent, OverlayComponent, PlaylistComponent, ThemeComponent, DVComponent, CaptionComponent } from './';
import './polyfills';

export * from '../services';
export * from './adobe';
export * from './closedCaptions';
export * from './krux';
export * from './events';
export * from './dates';
export * from './platform.component';
export * from './redirection.component';
export * from './index';

export interface BeforePlayingEvent {
    isInitialPlay: boolean;
}

export interface CancellableEvent {
    doCancel: boolean,
}

export interface CountdownCompletedEvent {
    wasManual: boolean,
}

export interface NextUpEvent {
    nextMedia: PlaylistEntry;
}

export interface PlayEvent extends PlayOptions {
    isInitialPlay: boolean;
}

export interface PlaylistEntry extends PlaylistItem {
    isCompleted?: boolean;
    progress?: number;
    startChapter?: number;
    unlocked?: boolean;
    redirectUrl: string;
    origin: string;
    title: any;
    show: any;
    metadata: MediaMetadata;
    error?: string;
    sessionId?: string;
    parentid?: string; // use for continues watch
    order?: string; // use for continues watch collection order
    ordering?: string; // use for continues watch collection ordering,
    daiSetting?: any; // DAI setting
    iabCategories?: Array<string>;
    iabTags?: Array<string>;
    contentcat?: string; // use for populating ad request custom param
}

export interface ResumingEvent {
    position: number;
    autostart: boolean;
}

export interface PlayerConfig {
    adConfig?: AdConfig,
    brand?: string,
    container: string,
    contentRestriction?: ContentRestrictionServiceConfig,
    countdown?: number,
    debug: boolean,
    feedUrl?: string,
    geoConfig?: GeoContentDetectionConfig,
    jwOptions: SetupOptions,
    key: string,
    listbar?: boolean,
    mediaId: string,
    messageContainer?: string,
    playlistUrl: string,
    playlist?: PlaylistEntry[],
    progressLimit?: number,
    redirectionUrlFormat?: string,
    relatedContent?: PlatformRelatedContentConfig,
    saveProgress?: boolean,
    themeColor?: string,
    messages?: any,
    skipOverlay?:boolean

}

export interface PlayerDependencies {
    $window?: Window;
    localStorageService?: LocalStorageService;
    playerFactory?: JWPlayerStatic;
    relatedContentComponent?: PlatformRelatedContentComponent;
    contentRestrictionService?: ContentRestrictionService;
    adService?: AdService;
    chaptersService?: ChaptersService;
    playlistComponent?: PlaylistComponent;
    xhrService?: XhrService;
    utilsService?: UtilsService;
    geoContentDetectionService?: GeoContentDetectionService;
    chartbeatService?: ChartbeatService;
    permutiveService?: PermutiveService;
}

export interface PlayerSubscriber {
    bindTo(player: Player): void;
}

export class Player {
    private DEFAULT_ASPECT_RATIO: string = '16:9';
    private DEFAULT_SKIN_NAME: string = 'corus';
    private DEFAULT_PRELOAD: string = 'auto';
    private DEFAULT_PRELOAD_LIVESTREAM: string = 'none';
    private DEFAULT_PRIMARY_PLAYMODE: string = 'html5';
    private DEFAULT_SAVEPROGRESS_LIMIT: number = 100;
    private DEFAULT_NEXTUP_OFFSET: number = -10;
    private DEFAULT_SAVE_PROGRESS: boolean = false;
    private DEFAULT_PLAYLIST_SIDEBAR: boolean = true;
    private DEFAULT_COUNTDOWN: number = 10; // in seconds
    private DEFAULT_WIDTH: string = '100%';
    private DEFAULT_BRAND: string = 'corus';
    private DEFAULT_MESSAGE_CONTAINER: string = '.jwplayer-container';
    private RELATED_PLAY_INLINE_MODE: string = 'play';
    private PLAYER_ID_FORMAT: string = '{brand}_player_{timestamp}{random}';
    private DEFAULT_CAPTIONS_OPTIONS: CaptionsOptions = {
        backgroundOpacity: 50,
        windowColor: '000000',
        windowOpacity: 0,
        fontFamily: 'proxima-nova'

    };

    private DEFAULT_VIDEO_NOT_FOUND_MESSAGE: ErrorMessageModel = {
        title: 'Sorry, Playback Is Unavailable',
        message: '<p>We couldn\'t find the video you were looking for.</p>'
    };

    private DISABLE_CC_IOS_VERSION: number = 9;
    public static VERSION: string = '__VERSION__';

    private $document: Document;
    private $window: Window = window;
    private chaptersService: ChaptersService;
    private countdownComponent: CountdownComponent;
    private chartbeatService: ChartbeatService;
    private permutiveService: PermutiveService;
    private ccComponent: CaptionComponent;
    private dvComponent: DVComponent;
    private nextUpComponent: NextUpComponent;
    private dateFormatter: DateFormatter = new DateFormatter();
    private initialPlaylistIndex: number = -1;
    private isInitialBeforePlay: boolean;
    private isInitialPlay: boolean;
    private isUsingRedirection: boolean = false;
    private localStorageService: LocalStorageService;
    private utilsService: UtilsService;
    private geoContentDetectionService: GeoContentDetectionService;
    private overlayComponent: OverlayComponent;
    private relatedContentComponent: PlatformRelatedContentComponent;
    private contentRestrictionComponent: ContentRestrictionComponent;
    private resumeAt: number;
    private adService: AdService;
    private playerInstance: JWPlayer;
    private playerFactory: JWPlayerStatic;
    private adsManager: AdsManager;
    private nextShowInfo: HTMLElement;
    private xhrService: XhrService;
    private _playerId: string = '';
    private _isAdBlocked: boolean = false;
    private _isNotFound: boolean = false;
    private _isRemoved: boolean = false;

    private get titlePrimary(): HTMLElement { return <HTMLElement> this.playerContainer.querySelector('.jw-title-primary'); }
    private get titleSecondary(): HTMLElement { return <HTMLElement> this.playerContainer.querySelector('.jw-title-secondary'); }

    // Public Properties

    public get currentChapter(): number { return this.chaptersService.currentChapterIndex + 1; }
    public get currentChapterDuration(): number { return this.chaptersService.currentChapterDuration; }
    public get currentChapterOffset(): number { return this.chaptersService.currentChapterOffset; }
    public get totalChapters(): number { return this.chaptersService.totalChapters; }
    public get currentMedia(): PlaylistEntry { return this.playerInstance
        ? <PlaylistEntry> this.playerInstance.getPlaylistItem() : this.currentMediaData; }

    public get currentMediaData(): PlaylistEntry { return <PlaylistEntry> this.config.jwOptions.playlist[0]; }
    public get currentMediaPosition(): number { return this.playerInstance.getPosition(); }
    public get currentAudioTrack(): number { return this.playerInstance.getCurrentAudioTrack(); }
    public get currentCaptionsTrack(): number { return this.playerInstance.getCurrentCaptions(); }
    public get iconContainer(): HTMLElement { return <HTMLElement> this.playerContainer.querySelector('.jw-display-icon-container'); }
    public get jwReplayIcon(): HTMLElement { return <HTMLElement> this.playerContainer.querySelector('.jw-display-icon-display'); }
    public get jwOverlays(): HTMLElement { return <HTMLElement> this.playerContainer.querySelector('.jw-overlays'); }
    public get playerContainer(): HTMLElement { return <HTMLElement> this.$document.getElementById(this.config.container); }
    public get playerId(): string { return this._playerId; }
    public get playlistIndex(): number { return this.playerInstance.getPlaylistIndex(); }
    public get playlist(): PlaylistEntry[] { return <PlaylistEntry[]> this.config.jwOptions.playlist; }
    public get isAdBlocked(): boolean { return this._isAdBlocked; }
    public get isNotFound(): boolean { return this._isNotFound; }
    public get isRemoved(): boolean { return this._isRemoved; }

    public get currentMediaDuration(): number {
        var value: any = this.playerInstance.getDuration() || this.currentMedia.metadata.duration || 0;
        var result: number = parseFloat(value);
        return result;
    }

    public get currentMediaMetaData(): string {
        return this.formatMetaData();
    }

    public constructor(public config: PlayerConfig, dependencies: PlayerDependencies = {}) {
        this.$window = dependencies.$window ? dependencies.$window : window;

        if (dependencies.utilsService) {
            this.utilsService = dependencies.utilsService;
        } else {
            this.utilsService = UtilsService.Instance;
            this.utilsService.setup({
                debug: this.config.debug || /debug=true/.test(this.$window.location.search)
            });
        }

        this.log('constructor', 'Config', config);
        this.log('constructor', 'Dependencies', dependencies);

        if (this.config.feedUrl && (!this.config.mediaId && !this.config.geoConfig)) {
            throw new Error('Please provide a media ID when specifying the feed URL.');
        }

        if (this.config.feedUrl && this.config.playlistUrl) {
            throw new Error('Please only specify either the feed URL or the playlist URL.');
        }

        if (this.config.geoConfig && !this.config.feedUrl) {
            throw new Error('Please provide a feedUrl for geo content detection.');
        }

        this.setDefaults(dependencies);

        if ( this.config.listbar ) {
            this.playerContainer.parentElement.className += ' playlist-right';
        }

        if (config.key) {
            this.log('constructor', 'Using key', config.key);
            (<any> this.playerFactory).key = config.key;
        }

        this.reset();
    }

    private setDefaults(dependencies: PlayerDependencies) {
        // Initialize local properties from dependencies object where possible.
        this.$document = this.$window.document;
        this.chaptersService = dependencies.chaptersService ? dependencies.chaptersService : new ChaptersService();
        this.chartbeatService = dependencies.chartbeatService ? dependencies.chartbeatService : null;
        this.permutiveService = dependencies.permutiveService ? dependencies.permutiveService : null;
        this.localStorageService = dependencies.localStorageService ? dependencies.localStorageService : LocalStorageService.Instance;
        this.playerFactory = dependencies.playerFactory ? dependencies.playerFactory : (<any>window).jwplayer;
        this.xhrService = dependencies.xhrService || new XhrService();
        this.config.jwOptions = this.config.jwOptions || {};

        // Initialize ad service and options if advertising is enabled.
        if (this.config.adConfig) {
            this.adService = dependencies.adService ? dependencies.adService : new AdService(this.config.adConfig, this.$document, this.$window);
            this.config.jwOptions.advertising = this.adService.adOptions;
            this.log('setDefaults', 'Using ads', this.adService.adOptions);
        }

        // Initialize geo content detection service if configured (for determining the live stream to play based on user's geo location)
        if (this.config.geoConfig) {
            this.config.geoConfig.feedUrl = this.config.feedUrl;
            this.geoContentDetectionService = dependencies.geoContentDetectionService
                ? dependencies.geoContentDetectionService : new GeoContentDetectionService();
            this.geoContentDetectionService.contentDetected.subscribe((caller, e) => this.onGeoContentDetected(e));
        }

        if (this.config.redirectionUrlFormat) {
            this.log('setDefaults', 'Using redirection URLs', this.config.redirectionUrlFormat);
            new RedirectionComponent(this);
            this.isUsingRedirection = true;
        }

        if (dependencies.relatedContentComponent) {
            this.relatedContentComponent = dependencies.relatedContentComponent;
        } else if (this.config.relatedContent) {
            this.relatedContentComponent = new PlatformRelatedContentComponent(this.config.relatedContent, this, this.xhrService);
            if ( this.config.redirectionUrlFormat ) {
                this.relatedContentComponent.videoNotFound.subscribe((caller, e) => this.onVideoNotFound(caller, e));
            }
            this.log('setDefaults', 'Using related content', this.config.relatedContent);
        }

        var contentRestrictionService: ContentRestrictionService =
            dependencies.contentRestrictionService || new ContentRestrictionService(this.config.contentRestriction);
        var contentRestrictionComponentConfig: ContentRestrictionComponentConfig = {
            container: this.DEFAULT_MESSAGE_CONTAINER
        };

        if (this.config.contentRestriction) {
            contentRestrictionComponentConfig = {
                container: this.config.messageContainer ? this.config.messageContainer : this.DEFAULT_MESSAGE_CONTAINER,
                drm: this.config.contentRestriction.drm && this.config.contentRestriction.drm.error ? this.config.contentRestriction.drm.error : null,
                authentication: this.config.contentRestriction.auth && this.config.contentRestriction.auth.handler
                    ? this.config.contentRestriction.auth.handler : null,
                authorization: this.config.contentRestriction.auth && this.config.contentRestriction.auth.authorizationHandler
                    ? this.config.contentRestriction.auth.authorizationHandler : null,
                geoBlock: this.config.contentRestriction.geoBlock && this.config.contentRestriction.geoBlock.error
                    ? this.config.contentRestriction.geoBlock.error : null,
                concurrency: this.config.contentRestriction.concurrency && this.config.contentRestriction.concurrency.error
                    ? this.config.contentRestriction.concurrency.error : null
            };
        }

        this.contentRestrictionComponent = new ContentRestrictionComponent(this, contentRestrictionService, contentRestrictionComponentConfig);
        this.contentRestrictionComponent.contentPrepared.subscribe((caller, e) => this.onContentPrepared(caller, e));
        this.contentRestrictionComponent.contentFailed.subscribe((caller, e) => this.onContentFailed(caller, e));

        // Initialize other default values that sites often won't need to change.
        this.config.jwOptions.aspectratio = this.config.jwOptions.aspectratio || this.DEFAULT_ASPECT_RATIO;
        this.config.jwOptions.primary = this.config.jwOptions.primary || this.DEFAULT_PRIMARY_PLAYMODE;
        this.config.brand = this.config.brand ? this.config.brand : this.DEFAULT_BRAND;
        this.config.countdown = this.config.countdown != null ? this.config.countdown : this.DEFAULT_COUNTDOWN;
        this.config.progressLimit = this.config.progressLimit || this.DEFAULT_SAVEPROGRESS_LIMIT;
        this.config.listbar = this.config.listbar != null ? this.config.listbar : this.DEFAULT_PLAYLIST_SIDEBAR;
        this.config.saveProgress = this.config.saveProgress != null ? this.config.saveProgress : this.DEFAULT_SAVE_PROGRESS;
        this.config.jwOptions.nextupoffset = this.config.jwOptions.nextupoffset || this.DEFAULT_NEXTUP_OFFSET;
        this.config.jwOptions.width = this.config.jwOptions.width || this.DEFAULT_WIDTH;
        this.config.jwOptions.skin = this.config.jwOptions.skin || {
            name: this.DEFAULT_SKIN_NAME
        };
        this.config.jwOptions.hlshtml = true;

        // set player ID based on configured brand
        this.setPlayerId();

        this.localStorageService.config(this.config.progressLimit);
    }

    public get position(): number {
        const media = this.localStorageService.get(this.currentMedia.mediaId);
        if (!media) return 0;
        return media.value;
    }

    public set position(value: number) {
        const resumePosition = value - 1.5; // Save progress slightly before real position to preserve ad playback.

        if (this.config.saveProgress && this.chaptersService.currentChapterIndex >= 0 && resumePosition > 0) {
            const percent =  resumePosition / this.currentMediaDuration * 100; // to handle the percentage for server side and client rendering
            this.localStorageService.put({
                mediaId: this.currentMedia.mediaId,
                value: resumePosition,
                percentage:percent,
                chapter: this.chaptersService.currentChapterIndex,
                timestamp: Date.now()
            });
        }
    }

    public initialize(): void {
        this.log('initialize', 'Started');

        var args: CancellableEvent = { doCancel: false };

        if (this.geoContentDetectionService && !this.geoContentDetectionService.detected) {
            this.log('geo content detection triggered');
            this.geoContentDetectionService.initialize(this.config.geoConfig);
            args.doCancel = true;
        }

        this.initializing.fire(this, args);

        if (args.doCancel) {
            this.log('initialize', 'Cancelled');
            return;
        }

        this.playlistLoading.fire(this, {});

        if ( this.config.playlist ) {
            // Passing a list of playlistEntry objects
            this.setPlaylist( this.config.playlist );
        } else {
            var dataUrl: string = this.config.playlistUrl ? this.config.playlistUrl : this.config.feedUrl + '?byId=' + this.config.mediaId;
            this.xhrService.getJson(
                dataUrl,
                (success: boolean, status: number, data: any) => {
                    this.log('initialize', 'JSON fetched', { success: success, data: data });
                    if (success) {
                        this.setPlaylist( data );
                    } else {
                        throw new Error('Failed to fetch data from ' + dataUrl);
                    }
                }
            );
        }
    }

    private setPlaylist( data: any ): void {
        this.processPlaylistData(data);

        this.config.jwOptions.playlist = data;
        this.applyBrowserTreatment();

        // trigers ordering of playlist to ensure selected video is at the top of the list
        this.playlistLoaded.fire(this, {});
    }

    private onContentPrepared(caller: ContentRestrictionComponent, e: any): void {
        this.initializeJWPlayer();
    }

    private onContentFailed(caller: ContentRestrictionComponent, e: any): void {
        this.remove('ContentRestrictionType ' + e.exception);

        if (!this.playerInstance) {
            this.initPlaylist();
        }
    }

    private onVideoNotFound(caller: PlatformRelatedContentComponent, e: any): void {
        this.log('video not found');

        // Set isRemoved flag to put player in error state.
        this._isRemoved = true;
        this.initPlaylist();

        // Clear playlist to prevent player from loading subsequent playable video.
        this.config.jwOptions.playlist = [];

        this.displayVideoNotFound();
    }

    public displayVideoNotFound() {
        this._isNotFound = true;

        var container: string = this.config.messageContainer ? this.config.messageContainer : this.DEFAULT_MESSAGE_CONTAINER;
        var model: ErrorMessageModel = this.DEFAULT_VIDEO_NOT_FOUND_MESSAGE;

        if ( this.config.messages && this.config.messages.hasOwnProperty( ErrorMessageType.VIDEO_NOT_FOUND ) ) {
            model = this.config.messages[ErrorMessageType.VIDEO_NOT_FOUND];
        }

        model.type = ErrorMessageType.VIDEO_NOT_FOUND;
        new ErrorMessageComponent( container, model, this.config.jwOptions.skin.name );
    }

    private processPlaylistData(data: any): void {
        for (let i = 0, { length } = data; i < length; ++i) {
            data[i].mediaId = this.stripId(data[i].mediaId);

            if (data[i].mediaId !== this.config.mediaId) {
                // Prioritize thumbnail field for non-main videos
                data[i].image = data[i].thumbnail || data[i].image;
            } else {
                // in case image field isn't available, use thumbnail field
                data[i].image = data[i].image || data[i].thumbnail;
            }

            // replace (brackets) with ascii equivelents
            // it breaks inline style background-image:url() as url is not in "quotes"
            if (data[i].image) {
                data[i].image = data[i].image.split('(').join('%28').split(')').join('%29');
            }

            /* TODO: Update this for VMS if required */
            /*
            let availability: string = data[i].metadata.availabilityWindow;
            let matches: any = (new RegExp('start=([^;]+)')).exec(availability);
            if (matches && matches.length > 1) {
                let startDate: any = new Date(matches[1]);
                data[i].metadata.availableDate = startDate.toUTCString();
            } else {
                data[i].metadata.availableDate = data[i].metadata.airDate;
            }
            */
            data[i].preload = 'none';

            if ('drm' in data[i]) {
                data[i].isDRM = data[i].drm;
                data[i].drm = null;
            }
        }

        this.log('processPlaylistData', data);
    }

    private initializeJWPlayer():void {
        this.log('initializeJWPlayer', null, this.config.jwOptions.playlist);

        if (this.config.saveProgress) {
            this.setVideoProgress(this.config.jwOptions);
        }

        if (this.adService) {
            this.adService.applySchedule(this.config.jwOptions.playlist as PlaylistEntry[]);

            // Re-apply jw adversiting configuration as adOption might have been updated
            // when daiSetting is detected in the playlist.
            this.config.jwOptions.advertising = this.adService.adOptions;
        }

        this.chaptersService.chapterCompleted.subscribe((caller, e) => this.onChapterCompleted(e));
        this.chaptersService.chapterStarted.subscribe((caller, e) => this.onChapterStarted(e));
        this.log('set up JW', null, this.config.jwOptions);

        if ( this.config.jwOptions.playlist && this.config.jwOptions.playlist.length > 0 ) {
            this.playerInstance = this.playerFactory.call(null, this.config.container);
            this.playerInstance.setup(this.config.jwOptions);
            this.registerJWPlayerEventHandlers(this.playerInstance);

            this.initialized.fire(this, {});
        }
    }

	/**
	 * Trigger JW Player adBlock if ad is blocked
	 */
    private triggerAdblock(): void {
		const playerDivSelector = this.config.container;
		const playerDiv = document.getElementById(playerDivSelector);
		const adBaitEl = document.createElement('div');
		adBaitEl.classList.add('topAds');
		playerDiv.appendChild(adBaitEl);
		if (window.getComputedStyle(playerDiv.querySelector('.topAds')).display == 'none') {
			this.log('AdBlock triggered');
			this.playerInstance.trigger('adBlock');
		}
	}

    private applyBrowserTreatment() {
        // Temporary workaround for iOS until JW fixes their player: https://corusent.atlassian.net/projects/CT/issues/CT-65
        if (this.utilsService.isIOS() && this.utilsService.getIOSVersion().major == this.DISABLE_CC_IOS_VERSION) {
            if (this.config.jwOptions.playlist) {
                for (let i = 0, { length } = this.config.jwOptions.playlist; i < length; ++i) {
                    this.config.jwOptions.playlist[i].tracks = [];
                }
            } else {
                this.config.jwOptions.tracks = [];
            }
        }
    }

    private setVideoProgress(jwOptions: any) {
        const position = this.localStorageService.get(jwOptions.mediaId || jwOptions.playlist[0].mediaId);
        if (position && position.value) {
            this.log('initialize', 'Progress found, disabling autoplay', position);
            this.config.jwOptions.autostart = false;
            this.currentMedia.startChapter = position.chapter;
        }
    }

    public registerJWPlayerEventHandlers(jwPlayerInstance: JWPlayer) {
        jwPlayerInstance.on('adBlock', () => this.onAdBlock());
        jwPlayerInstance.on('adPause', (e: AdPauseResumeOptions) => this.onAdPaused(e));
        jwPlayerInstance.on('adPlay', (e: AdPauseResumeOptions) => this.onAdResumed(e));
        jwPlayerInstance.on('adBreakStart', (e: AdBreakOptions) => this.onAdBreakStarted(e));
        jwPlayerInstance.on('adBreakEnd', (e: AdBreakOptions) => this.onAdBreakEnded(e));
        jwPlayerInstance.on('adSkipped', (e: AdCompleteOptions) => this.onAdComplete(e));
        jwPlayerInstance.on('adsManager', (e: AdsManagerOptions) => this.onAdsManager(e));
        jwPlayerInstance.on('captionsList', (e: CaptionsEvent) => this.onCaptionsList(e));
        jwPlayerInstance.on('audioTracks', (e: AudioTracksOptions) => this.onAudioTracks(e));
        jwPlayerInstance.on('audioTrackChanged', (e:any) => this.onAudioTrackChanged(e));
        jwPlayerInstance.on('adComplete', (e: AdCompleteOptions) => this.onAdComplete(e));
        jwPlayerInstance.on('adError', (e: AdErrorOptions) => this.onAdError(e));
        jwPlayerInstance.on('adImpression', (e: AdImpressionOptions) => this.onAdImpression(e));
        jwPlayerInstance.on('beforePlay', () => this.onBeforePlay());
        jwPlayerInstance.on('error', (e: ErrorOptions) => this.onError(e));
        jwPlayerInstance.on('ready', () => this.onReady());
        jwPlayerInstance.on('play', (e: PlayOptions) => this.onPlay(e));
        jwPlayerInstance.on('buffer', () => this.onBuffer());
        jwPlayerInstance.on('bufferFull', () => this.onBufferCompleted());
        jwPlayerInstance.on('bufferChange', (e: BufferOptions) => this.onBufferChange(e));
        jwPlayerInstance.on('captionsChange', (e: CaptionsEvent) => this.onCaptionsChange(e));
        jwPlayerInstance.on('complete', () => this.onComplete());
        jwPlayerInstance.on('beforeComplete', () => this.onBeforeComplete());
        jwPlayerInstance.on('fullscreen', (e: FullscreenOptions) => this.onFullscreen(e));
        jwPlayerInstance.on('pause', (e: any) => this.onPause(e));
        jwPlayerInstance.on('setupError', (e: ErrorOptions) => this.onSetupError(e));
        jwPlayerInstance.on('time', (e: TimeOptions) => this.onTime(e));
        jwPlayerInstance.on('seek', (e: SeekEvent) => this.onSeek(e));
        jwPlayerInstance.on('seeked', () => this.onSeeked());
        jwPlayerInstance.on('playlistItem', (e: PlaylistItemChangeOptions) => this.onPlaylistItem(e));

        if (this.adService) {
            jwPlayerInstance.on('adCompanions', (e: AdCompanionsOptions) => this.adService.populateAdCompanions(e));
        }

        if (this.chartbeatService) {
            this.chartbeatService.register( jwPlayerInstance, this );
        }

        if (this.permutiveService) {
            this.permutiveService.register( jwPlayerInstance, this );
        }
    }

    // External Event Handlers

    public static AD_BLOCK_COMPLETED_EVENT: string = 'player.adBlockCompleted';
    public adBlockCompleted = new EventHandler<Player, AdCompleteOptions>(Player.AD_BLOCK_COMPLETED_EVENT, this.$window);

    public static AD_BLOCKING_DETECTED_EVENT: string = 'player.adBlockingDetected';
    public adBlockingDetected = new EventHandler<Player, any>(Player.AD_BLOCKING_DETECTED_EVENT, this.$window);

    public static AD_COMPLETED_EVENT: string = 'player.adCompleted';
    public adCompleted = new EventHandler<Player, AdCompleteOptions>(Player.AD_COMPLETED_EVENT, this.$window);

    public static AD_FAILED_EVENT: string = 'player.adFailed';
    public adFailed = new EventHandler<Player, AdErrorOptions>(Player.AD_FAILED_EVENT, this.$window);

    public static AD_STARTED_EVENT: string = 'player.adStarted';
    public adStarted = new EventHandler<Player, AdImpressionOptions>(Player.AD_STARTED_EVENT, this.$window);

    public static AD_STARTING_EVENT: string = 'player.adStarting';
    public adStarting = new EventHandler<Player, AdImpressionOptions>(Player.AD_STARTING_EVENT, this.$window);

    public static AD_RESUMED_EVENT: string = 'player.adResumed';
    public adResumed = new EventHandler<Player, any>(Player.AD_RESUMED_EVENT, this.$window);

    public static AD_PAUSED_EVENT: string = 'player.adPaused';
    public adPaused = new EventHandler<Player, any>(Player.AD_PAUSED_EVENT, this.$window);

    public static AD_BREAK_STARTED_EVENT: string = 'player.adBreakStarted';
    public adBreakStarted = new EventHandler<Player, AdBreakOptions>(Player.AD_BREAK_STARTED_EVENT, this.$window);

    public static AD_BREAK_ENDED_EVENT: string = 'player.adBreakEnded';
    public adBreakEnded = new EventHandler<Player, AdBreakOptions>(Player.AD_BREAK_ENDED_EVENT, this.$window);

    public static AD_FIRST_FRAME_EVENT: string = 'player.adFirstFrame';
    public adFirstFrame = new EventHandler<Player, any>(Player.AD_FIRST_FRAME_EVENT, this.$window);

    public static AUTH_REQUEST_EVENT: string = 'player.authorizationRequest';
    public authorizationRequest = new EventHandler<Player, any>(Player.AUTH_REQUEST_EVENT, this.$window);

    public static BEFORE_COMPLETING_EVENT: string = 'player.beforeCompleting';
    public beforeCompleting = new EventHandler<Player, any>(Player.BEFORE_COMPLETING_EVENT, this.$window);

    public static BEFORE_PLAYING_EVENT: string = 'player.beforePlaying';
    public beforePlaying = new EventHandler<Player, BeforePlayingEvent>(Player.BEFORE_PLAYING_EVENT, this.$window);

    public static BUFFERING_EVENT: string = 'player.buffering';
    public buffering = new EventHandler<Player, any>(Player.BUFFERING_EVENT, this.$window);

    public static BUFFERED_EVENT: string = 'player.buffered';
    public buffered = new EventHandler<Player, any>(Player.BUFFERED_EVENT, this.$window);

    public static UNAUTH_USER_BLOCK_EVENT: string = 'player.unAuthUserBlocked';
    public unAuthUserBlocked = new EventHandler<Player, any>(Player.UNAUTH_USER_BLOCK_EVENT, this.$window);

    public static SEEKED_EVENT: string = 'player.seeked';
    public seeked = new EventHandler<Player, any>(Player.SEEKED_EVENT, this.$window);

    public static SEEKING_EVENT: string = 'player.seeking';
    public seeking = new EventHandler<Player, SeekEvent>(Player.SEEKING_EVENT, this.$window);

    public static CONTENT_RESUMED: string = 'player.contentResumed';
    public contentResumed = new EventHandler<Player, any>(Player.CONTENT_RESUMED, this.$window);

    public static CAPTIONS_CHANGED_EVENT: string = 'player.captionsChanged';
    public captionsChanged = new EventHandler<Player, CaptionsEvent>(Player.CAPTIONS_CHANGED_EVENT, this.$window);

    public static COUNTDOWN_CLOSED_EVENT: string = 'player.countdownClosed';
    public countdownClosed = new EventHandler<Player, any>(Player.COUNTDOWN_CLOSED_EVENT, this.$window);

    public static COUNTDOWN_COMPLETED_EVENT: string = 'player.countdownCompleted';
    public countdownCompleted = new EventHandler<Player, CountdownCompletedEvent>(Player.COUNTDOWN_COMPLETED_EVENT, this.$window);

    public static CHAPTER_COMPLETED_EVENT: string = 'player.chapterCompleted';
    public chapterCompleted = new EventHandler<Player, ChapterEvent>(Player.CHAPTER_COMPLETED_EVENT, this.$window);

    public static CHAPTER_STARTED_EVENT: string = 'player.chapterStarted';
    public chapterStarted = new EventHandler<Player, ChapterEvent>(Player.CHAPTER_STARTED_EVENT, this.$window);

    public static COMPLETED_EVENT: string = 'player.completed';
    public completed = new EventHandler<Player, any>(Player.COMPLETED_EVENT, this.$window);

    public static FULLSCREEN_CHANGED_EVENT: string = 'player.fullscreenChanged';
    public fullscreenChanged = new EventHandler<Player, FullscreenOptions>(Player.FULLSCREEN_CHANGED_EVENT, this.$window);

    public static INITIALIZED_EVENT: string = 'player.initialized';
    public initialized = new EventHandler<Player, any>(Player.INITIALIZED_EVENT, this.$window);

    public static INITIALIZING_EVENT: string = 'player.initializing';
    public initializing = new EventHandler<Player, CancellableEvent>(Player.INITIALIZING_EVENT, this.$window);

    public static PAUSING_EVENT: string = 'player.pausing';
    public pausing = new EventHandler<Player, any>(Player.PAUSING_EVENT, this.$window);

    public static PLAYBACK_FAILED: string = 'player.playbackFailed';
    public playbackFailed = new EventHandler<Player, ErrorOptions>(Player.PLAYBACK_FAILED, this.$window);

    public static PLAYED_EVENT: string = 'player.played';
    public played = new EventHandler<Player, PlayEvent>(Player.PLAYED_EVENT, this.$window);

    public static PLAYING_EVENT: string = 'player.playing';
    public playing = new EventHandler<Player, PlayEvent>(Player.PLAYING_EVENT, this.$window);

    public static PLAYBACK_STARTED_EVENT: string = 'player.playbackStarted';
    public playbackStarted = new EventHandler<Player, PlayEvent>(Player.PLAYBACK_STARTED_EVENT, this.$window);

    public static PLAYLIST_ITEM_CHANGED_EVENT: string = 'player.playlistItemChanged';
    public playlistItemChanged = new EventHandler<Player, any>(Player.PLAYLIST_ITEM_CHANGED_EVENT, this.$window);

    public static PLAYLIST_LOADED_EVENT: string = 'player.playlistLoaded';
    public playlistLoaded = new EventHandler<Player, any>(Player.PLAYLIST_LOADED_EVENT, this.$window);

    public static PLAYLIST_LOADING_EVENT: string = 'player.playlistLoading';
    public playlistLoading = new EventHandler<Player, any>(Player.PLAYLIST_LOADING_EVENT, this.$window);

    public static READY_EVENT: string = 'player.ready';
    public ready = new EventHandler<Player, any>(Player.READY_EVENT, this.$window);

    public static RESTARTING_EVENT: string = 'player.restarting';
    public restarting = new EventHandler<Player, any>(Player.RESTARTING_EVENT, this.$window);

    public static RESUMING_EVENT: string = 'player.resuming';
    public resuming = new EventHandler<Player, ResumingEvent>(Player.RESUMING_EVENT, this.$window);

    public static SETUP_FAILED: string = 'player.setupFailed';
    public setupFailed = new EventHandler<Player, ErrorOptions>(Player.SETUP_FAILED, this.$window);

    public static NEXT_UP_EVENT: string = 'player.nextUp';
    public nextUp = new EventHandler<Player, NextUpEvent>(Player.NEXT_UP_EVENT, this.$window);

    public static UNAUTHORIZED_RESOURCE_EVENT: string = 'player.unauthorizedResource';
    public unauthorizedResource = new EventHandler<Player, any>(Player.UNAUTHORIZED_RESOURCE_EVENT, this.$window);

    public static VIDEO_UNLOAD: string = 'player.videoUnload';
    public videoUnload = new EventHandler<Player, any>(Player.VIDEO_UNLOAD, this.$window);

    // Public Actions

    public goToPlaylistItem(index: number): void {
        this.log('goToPlaylistItem', null, index);
        this.playerInstance.playlistItem(index);
    }

    public pause(): void {
        if (this.playerInstance) {
            this.log('player pause', null, this.playerInstance.getState());
            if (this.playerInstance.getState() !== 'paused') {
                this.playerInstance.pause();
            }
        }

    }

    public play(): void {
        if (this.playerInstance) {
            this.log('player play', null, this.playerInstance.getState());
            if (this.playerInstance.getState() !== 'playing') {
                this.playerInstance.play();
            }
        }
    }

    public remove(reason: string): void {
        try {
            this._isRemoved = true;
            this.playerContainer.parentElement.className += ' state--error';
            this.playerInstance.remove();
        } catch (e) {
            // swallow exception - player may be in various invalid states
        }

        if (reason) {
            this.onError({ message: 'Removing player: ' + reason });
        }
    }

    public restart(): void {
        this.log('restart');
        this.restarting.fire(this, {});
        this.localStorageService.remove(this.currentMedia.mediaId);
        this.playerInstance.play();
    }

    public resume(autostart: boolean = false): void {
        this.log('restart', null, this.position);
        this.resuming.fire(this, { position: this.position, autostart: autostart });
        this.resumeAt = this.position;
        if (!autostart) {
            this.log('restart', 'Autoplay disabled, launch video manually');
            this.play();
        }
    }

    public unloadVideo(reason: string): void {
        this.log('unload video', reason);
        this.videoUnload.fire(this, {});
        this.reset();
        if (this.playerInstance) {
            this.remove('Video Unloaded');
        }
    }

    // Internal Event Handlers

    private onAdBlock() {
        this.log('Adblock','Setting adblock trigger on');
        this._isAdBlocked = true;
        this.adBlockingDetected.fire(this, {});
    }

    private onAdComplete(e: AdCompleteOptions) {
        this.log('adComplete', null, e);
        this.adCompleted.fire(this, e);
        if (e.sequence === e.podcount) {
            this.adBlockCompleted.fire(this, e);
        }
    }

    private onAdError(e: AdErrorOptions): void {
        this.log('adError', null, e);
        this.adFailed.fire(this, e);
    }

    private onAdPaused(e: AdPauseResumeOptions): void {
        this.adPaused.fire(this, e);
    }

    private onAdResumed(e: AdPauseResumeOptions): void {
        this.adResumed.fire(this, e);
    }

    private onAdBreakStarted(e: AdBreakOptions): void {
        this.adBreakStarted.fire(this, e);
        if (this.resumeAt && this.currentMedia.startChapter !== undefined) {
            const podIndex = this.adsManager.getCurrentAd().getAdPodInfo().getPodIndex();
            if (podIndex <= this.currentMedia.startChapter) {
                this.log('resuming; skipping ad break at position ' + podIndex);
                this.adsManager.discardAdBreak();
            }
        }
    }

    private onAdBreakEnded(e: AdBreakOptions): void {
        this.playerInstance.once('time', () => this.onContentResumed());
        this.adBreakEnded.fire(this, e);
    }

    private onAdsManager(e: AdsManagerOptions): void {
        this.adsManager = e.adsManager;
    }

    private onContentResumed(): void {
        this.contentResumed.fire(this, {});
    }

    private onAdImpression(e: AdImpressionOptions) {
        this.log('adImpression', null, e);

        if ( e.ima && e.ima.ad ) {
            // For midrolls, ensure to update chapterService position to trigger
            // necessary chapter complete / start before adStart for proper tracking
            var adPodIdx: number = e.ima.ad.getAdPodInfo().getPodIndex();
            if (adPodIdx > 0) {
                var time: number = this.chaptersService.getTimeByChapterIndex(adPodIdx);
                if ( time > 0 ) {
                    this.chaptersService.update(time, true);
                }
            }
        }

        this.playerInstance.once('adTime', (e:any) => this.onAdTime(e));

        this.adStarting.fire(this, e);
        this.chaptersService.initialize(this.currentMedia, this.currentMediaPosition);
        this.adStarted.fire(this, e);
    }

    private onAdTime(e: any) {
        this.adFirstFrame.fire( this, e );
    }

    private onBeforePlay(): void {
        this.log('beforePlay');
        this.triggerAdblock();

        // Bail if the player has already been removed (e.g. ad blocker message shown)
        if ( this.isRemoved ) {
            return;
        }

        this.beforePlaying.fire(this, { isInitialPlay: this.isInitialBeforePlay });
        if (this.position && this.isInitialBeforePlay) this.resume(true);
        this.isInitialBeforePlay = false;
    }

    private onAudioTracks(e: AudioTracksOptions) {
        this.log('onAudioTracks', null, e);
        if (!this.dvComponent && e.tracks && e.tracks.length > 0) {
            // set the default language as 'English' for audio track
            e.tracks.map((track, index) => {
                if (track.language === 'en') {
                    e.currentTrack = index;
                }
            });
            this.dvComponent = new DVComponent(this, e);
            if (this.dvComponent) {
                this.setCurrentAudioTrack(e.currentTrack);
                this.dvComponent.update(e);
            }
        }
    }

    private onAudioTrackChanged( e: any ) {
        if (this.dvComponent) {
            this.dvComponent.update(e);
        }
    }

    private onCaptionsList(e: CaptionsEvent) {
        this.log('onCaptionsList', null, e);

        if (!this.ccComponent) {
            this.log('onCaptionsList', 'initialize CaptionComponent', e);
            this.ccComponent = new CaptionComponent(this, e);
        }

        this.ccComponent.update(e);
    }

    private onCaptionsChange(e: CaptionsEvent) {
        this.log('captionsChange', null, e);
        this.captionsChanged.fire(this, e);
    }

    private onChapterCompleted(e: ChapterEvent): void {
        this.chapterCompleted.fire(this, e);
    }

    private onChapterStarted(e: ChapterEvent): void {
        this.chapterStarted.fire(this, e);
    }

    private onError(e: ErrorOptions) {
        this.log('error', null, e);

        // Bail if the player has already been removed (e.g. ad blocker message shown)
        if ( this.isRemoved ) {
            return;
        }

        this.playbackFailed.fire(this, e);
    }

    private onFullscreen(e: FullscreenOptions) {
        this.log('fullscreen', null, e);
        this.fullscreenChanged.fire(this, e);
    }

    private onGeoContentDetected(e: GeoContent): void {
        this.log('onGeoContentDetected', null, e);
        this.config.mediaId = e.id;
        this.initialize();
    }

    public onReady() {
        this.log('ready');
        this.ready.fire(this, {});
        this.initComponents();

        if ( this.position && !this.config.jwOptions.autostart ) {
            this.overlayComponent.render();
        }
    }

    public onPlay(e: PlayOptions) {
        this.log('jwevent on play', null, e);
        var args = <PlayEvent>e;
        args.isInitialPlay = this.isInitialPlay;
        this.isInitialPlay = false;

        this.playing.fire(this, args);
        if ( args.isInitialPlay ) {
            this.updateTitleElement();
            if (this.config.jwOptions.resumeAt < this.playerInstance.getDuration()) {
                this.log('play', 'Resuming on initial play', this.config.jwOptions.resumeAt);
                this.playerInstance.seek( this.config.jwOptions.resumeAt );
            }
        }

        this.chaptersService.initialize(this.currentMedia, this.currentMediaPosition);

        if (args.isInitialPlay) {
            this.playbackStarted.fire(this, args);
        }

        this.played.fire(this, args);
    }

    public onPlaylistItem(e: PlaylistItemChangeOptions) {
        this.log('playlistItem', null, e);
        this.log('playlistItem', 'Current media manifest', e.item.file);

        var isRedirecting: boolean = false;

        if (this.initialPlaylistIndex !== -1 && this.initialPlaylistIndex !== e.index) {
            const redirectUrl = e ? (<PlaylistEntry>e.item).redirectUrl : null;
            if (redirectUrl) {
                this.log('playlistItem', 'Redirecting', redirectUrl);
                this.$window.location.href = redirectUrl;
                isRedirecting = true;
            }
        }
        this.initialPlaylistIndex = e.index;

        if (!isRedirecting) {
            this.log('playlistItem', 'Loading without redirection');
            this.updateTitleElement();
            this.playlistItemChanged.fire(this, {});
            this.reset();
        }
    }

    public onPause(e: any) {
        this.log('on pause');
        this.pausing.fire(this, e);
        var video = this.playerInstance.getPlaylistItem(this.playerInstance.getPlaylistIndex());
    }

    public onSetupError(e: ErrorOptions) {
        this.log('setupError', null, e);
        this.setupFailed.fire(this, e);
    }

    public getNextPlaylistItem(): PlaylistItem | boolean {
        const playlistLength = this.config.jwOptions.playlist.length;
        const nextItemIdx = this.playlistIndex + 1;
        let result: PlaylistItem | boolean = false;

        if (nextItemIdx < playlistLength) {
            result = this.config.jwOptions.playlist[nextItemIdx];
        }

        this.log('getNextPlaylistItem', null, result);

        return result;
    }

    public onBeforeComplete() {
        this.log('beforeComplete');
        this.beforeCompleting.fire(this, {});
        this.renderCountdown();
    }

    private renderCountdown() {
        if (this.getNextPlaylistItem()) {
            this.playerInstance.stop();
            // Temporary workaround. Countdown does not render over video when in fullscreen on iOS
            if (this.playerInstance.getFullscreen() && this.detectDevice() === 'iOS') {
                this.$window.setTimeout(() => {
                    this.playerInstance.next();
                }, 2000);
            } else {
                this.jwReplayIcon.setAttribute('style', 'display:none');
                this.countdownComponent.render();
            }
        }
    }

    public detectDevice(): string {
        const userAgent = this.$window.navigator.userAgent || this.$window.navigator.vendor;
        if (/iPad|iPhone|iPod/.test(userAgent)) {
            return 'iOS';
        }
        return null;
    }

    public onComplete() {
        this.log('complete');
        this.chaptersService.update(this.currentMediaDuration, true);
        this.completed.fire(this, {});
        this.localStorageService.setCompleted(this.currentMedia.mediaId, true);
    }

    public onBuffer() {
        this.buffering.fire(this, {});
    }

    public onBufferCompleted() {
        this.buffered.fire(this, {});
    }

    public onBufferChange(e: BufferOptions) {
    }

    public onSeek(e: SeekEvent) {
        this.seeking.fire(this, e);
    }

    public onSeeked() {
        this.log('on seeked!');
        this.seeked.fire(this, {});
    }

    private onTime(e: TimeOptions) {
        this.chaptersService.update(e.position);

        if (!this.currentMedia.metadata.liveStream) {
            this.position = e.position;

            if (this.nextUpComponent.isTime(e.position)) {
                this.nextUpComponent.render();
                this.localStorageService.remove(this.currentMedia.mediaId);
            }
        }
    }

    private initComponents() {
        this.initQueryStringParams();
        this.updateTitleElement();
        this.initPlaylist();
        this.overlayComponent = new OverlayComponent(this, this.$document);
        this.countdownComponent = new CountdownComponent(this, this.$document);
        this.nextUpComponent = new NextUpComponent(this);
        if (this.config.jwOptions.skin.name === this.DEFAULT_SKIN_NAME && this.config.themeColor) {
            new ThemeComponent(this.config.themeColor, { $document: this.$document });
        }
    }

    private initQueryStringParams() {
        const urlParams = new URLSearchParams(window.location.search);
        const resumeAt = urlParams.get('resumeTime');
        const action = urlParams.get('action');
        this.config.jwOptions.resumeAt = parseFloat(resumeAt);
        if ( typeof action !== 'undefined' && (action === 'play' || action === 'smartPlay')) {
            this.config.jwOptions.autostart = true;
            this.play();
        }
    }

    private initPlaylist() {
        if (this.config.listbar) {
            new PlaylistComponent(this.isUsingRedirection, {
                $document: this.$document,
                player: this,
                localStorageService: this.localStorageService
            });
        }
    }

    public closeCountdown() {
        this.countdownClosed.fire(this, {});
        this.jwReplayIcon.setAttribute('style', 'display:inline-block');
    }

    public playNext(wasManual: boolean) {
        const urlParams = new URLSearchParams(window.location.search);
        const isShuffle = Boolean(urlParams.get('shuffle'));
        const isSmartPlay = (urlParams.get('action') === 'smartPlay');
        const urlParameters = (isSmartPlay ? '?action=smartPlay' : (isShuffle ? '?shuffle=true&action=play' : ''));

        if (this.config.redirectionUrlFormat) {
            const nextItem = <PlaylistEntry> this.getNextPlaylistItem();
            this.$window.location.href = nextItem.redirectUrl + urlParameters;
        } else {
            this.playerInstance.next();
            this.countdownCompleted.fire(this, { wasManual: wasManual });
        }
    }

    public setCurrentAudioTrack(index: number) {
        this.playerInstance.setCurrentAudioTrack(index);
    }

    public setCurrentCaptions(index: number) {
        this.playerInstance.setCurrentCaptions(index);
    }

    private updateTitleElement() {
        const media = this.playerInstance.getPlaylistItem(this.playerInstance.getPlaylistIndex());
        if (media.show) {
            this.log('updateTitleElement', 'Media has show info', media.show);
            this.titlePrimary.innerHTML = media.show;
            this.titleSecondary.innerHTML = media.title;
        } else {
            this.log('updateTitleElement', 'Media has no show info');
            this.titlePrimary.innerHTML = media.title;
        }
    }

    private reset(): void {
        this.isInitialBeforePlay = true;
        this.isInitialPlay = true;
        this.resumeAt = 0;
        this.chaptersService.reset();
    }

    public stripId(idStr: string): string {
        var result = idStr.replace(/.+\//, '');
        return result;
    }

    private formatMetaData(): string {
        const unavailable: string = 'Unavailable';
        var result: string = null;
        var mediaInformationParts: string[] = [];

        if (this.currentMedia) {

            /* Replace any semicolons in the actual field with greek question marks (unicode 37e decimal 894),
             * so they look the same, but won't break the reporting tools that our
             * analytics team uses. https://www.fileformat.info/info/unicode/char/037e/index.htm
             */
            mediaInformationParts = mediaInformationParts.concat([
                this.currentMedia.show ? this.currentMedia.show.replace(';', '%3B') : unavailable,
                this.currentMedia.metadata.seasonNumber ? this.utilsService.padNumber(this.currentMedia.metadata.seasonNumber).replace(';', '%3B') : unavailable,
                this.currentMedia.title ? this.currentMedia.title.replace(';', '%3B') : unavailable,
                this.currentMedia.origin ? mediaInformationParts.concat([ this.currentMedia.origin ]) : unavailable,
                this.currentMedia.metadata.episodeNumber ? this.utilsService.padNumber(this.currentMedia.metadata.episodeNumber).replace(';', '%3B') : unavailable
            ]);

            result = mediaInformationParts.join(';');
        }

        return result;
    }

    private setPlayerId(): void {
        var result: string = this.PLAYER_ID_FORMAT;
        result = result.replace(/{brand}/i, this.config.brand)
                        .replace(/{timestamp}/i, (new Date()).getTime().toString())
                        .replace(/{random}/i, Math.random().toString(36).substr(2, 10)); // Generate random string

        this._playerId = result;

        this.log('setPlayerId()', 'ID:', this.playerId);
    }

    public log(eventType: string, message?: string, info?: any): void {
        this.utilsService.log(this.config.container, eventType, message, info);
    }
}
