import { PlaylistEntry } from '../../components/player';
import { EventHandler } from '../../components/events';
import { UtilsService, XhrService, AuthenticationService, DRMService, VMSDRMService, DRMServiceConfig, AuthenticationServiceConfig, ConcurrencyService } from '..';

export interface RestrictionPolicy {
    id: string,
    title: string,
    relativeEnforcementStart: string,
    relativeEnforcementEnd: string,
    enforceEntitlements: boolean
}

export interface ContentRestrictionEvent {
    entry: PlaylistEntry,
    exception: ContentRestrictionType
}

export enum ContentRestrictionType {
    None = 0,
    GeoLocation = 1,
    Authorization = 2,
    Authentication = 3,
    Concurrency = 4,
    DRM = 5,
    Others = 6
}

export interface ContentRestrictionServiceConfig {
    policies?: RestrictionPolicy[],
    requireAuth?: boolean,
    auth?: AuthenticationServiceConfig,
    drm?: DRMServiceConfig,
    geoBlock?: any,
    concurrency?: any,
    hideManifestUrl?: boolean
}

export class ContentRestrictionService {
    private _config: ContentRestrictionServiceConfig;
    private _policies: RestrictionPolicy[];

    private _concurrencyService: ConcurrencyService;
    private _drmService: DRMService;

    private _currentEntry: PlaylistEntry;
    private _clientId: string;

    private GEO_ERROR_CODE: string = 'GeoLocationBlocked';
    private CONCURRENCY_ERROR_CODE: string = 'ConcurrencyLimitViolation';

    public authenticationService: AuthenticationService;
    public restrictionHandled = new EventHandler<ContentRestrictionService, any>();

    constructor(config?: ContentRestrictionServiceConfig,
                private _util: UtilsService = UtilsService.Instance,
                private _xhrService: XhrService = new XhrService()) {

        this.setup(config);
    }

    public setup(config?: ContentRestrictionServiceConfig) {
        this._config = config || {};

        if (this._config.auth) {
            this._policies = this._config.policies;
            this.authenticationService = new AuthenticationService(this._config.auth);
            this.authenticationService.authTokenRetrieved.subscribe((caller, e) => this._onAuthToken(caller, e));
            this.authenticationService.authTokenFailed.subscribe((caller, e) => this._onAuthToken(caller, e));
        }

        if (this._config.drm) {
            if (this._config.drm.vmsRealtimeUrl) {
                this._drmService = new VMSDRMService(this._config.drm);

                // Concurrency service is currently only supported for VMS.
                this._concurrencyService = ConcurrencyService.Instance;
                this._concurrencyService.reset(); // Clean up any old locks
            } else if (this._config.drm.tokenAPIUrl) {
                this._drmService = new DRMService(this._config.drm);
            } else {
                this._log('DRM config requires either tokenAPIUrl or vmsRealtimeUrl. Please refer to docs.');
            }

            this._drmService.drmHandled.subscribe((caller, e) => this._onDRMHandled(caller, e));
        }
    }

    public handleRestriction(entry: PlaylistEntry, clientId: string):void {
        this._log('handleRestriction()', clientId, entry.metadata);

        this._currentEntry = entry;
        this._clientId = clientId;
        entry.metadata.restricted = this._isAuthorizationRequired(entry);

        if (entry.metadata.restricted) {
            // Handle authentication restrictions
            this._handleAuthRestriction(entry);
        } else {
            // Handle other restrictions (geo, concurrency)
            this._handleRestriction(entry);
        }
    }

    /* Private functions */

    private _isAuthorizationRequired(entry: PlaylistEntry): boolean {
        let result: boolean = false;
        const { metadata } = entry;
        this._log('_isAuthorizationRequired()', 'Check auth restrictions for restriction id', metadata.restrictionId);

        if (this._config.requireAuth) {
            result = true;
        } else if (this._policies && entry.metadata.restrictionId) {
            for (let i = 0; i < this._policies.length; i++) {
                const policy = this._policies[i];
                if (metadata.restrictionId == policy.id ) {
                    const restrictionStartOffset: number = parseInt(policy.relativeEnforcementStart, 10); // in seconds

                    if (policy.enforceEntitlements) {
                        const restrictionWindowStartDate: any = new Date(Date.parse(metadata.availableDate) + ( restrictionStartOffset * 1000 ));
                        const today: any = new Date();

                        this._log('_isAuthorizationRequired()', 'restriction start date (local time)', restrictionWindowStartDate);
                        this._log('_isAuthorizationRequired()', 'today (local time)', today);

                        if (today >= restrictionWindowStartDate) {
                            // Authentication required
                            result = true;
                        }
                    }

                    break;
                }
            }
        }

        return result;
    }

    private async _handleAuthRestriction(entry: PlaylistEntry) {
        const isAuthenticated = await this.authenticationService.isAuthenticated();

        if (!isAuthenticated) {
            this.restrictionHandled.fire(this, {
                entry: entry,
                exception: ContentRestrictionType.Authentication
            });
        } else {
            this.authenticationService.requestAuthorization();
        }
    }

    private _handleRestriction(entry: PlaylistEntry, token: string = ''): void {
        const url: string = entry.sources[0].publicUrl ? entry.sources[0].publicUrl : entry.sources[0].file;

        this._xhrService.getPlain(url, (success: boolean, status: number, data: any) => {
            let exception: string;
            try {
                const dataObj: any = JSON.parse(data);
                exception = dataObj.exception;
            } catch (e) {
                // Response is not JSON, so it's a SMIL
                exception = '';
            }
            let restrictionType: ContentRestrictionType = ContentRestrictionType.None;

            this._log('_handleRestriction()', 'exception code = ', exception);

            switch (exception) {
                case this.GEO_ERROR_CODE:
                    restrictionType = ContentRestrictionType.GeoLocation;
                    break;
                case this.CONCURRENCY_ERROR_CODE:
                    restrictionType = ContentRestrictionType.Concurrency;
                    break;
                default:
                    let restrictionIdentified = false;
                    const sourceRegex: any = new RegExp('video src=\"([^\"]+)\"');
                    const sourceMatches: any = sourceRegex.exec(data);
                    if (sourceMatches && sourceMatches.length > 1) {
                        if (entry.metadata.restricted || (!this._config.hideManifestUrl && !this._util.isAndroid() && !this._util.isIOS())) {
                            entry.sources[0].file = sourceMatches[1];
                        } else {
                            // Be sure to pass a manifest url. Some publicURL's default to returning SMIL instead of manifest
                            entry.sources[0].file += '&metafile=false';
                        }
                        restrictionIdentified = true;
                    }

                    entry.sources[0].publicUrl = url;

                    if (entry.sessionId && this._concurrencyService) {
                        this._concurrencyService.initialize(entry.sessionId, entry.mediaId, entry.metadata.duration);
                    }

                    if (entry.metadata.restricted) {
                        entry.unlocked = true;
                        restrictionIdentified = true;
                    }

                    if (entry.isDRM) {
                        this._prepareDRMContent(entry);
                        restrictionIdentified = true;
                    }

                    if (!restrictionIdentified) {
                        restrictionType = ContentRestrictionType.Others;
                    }

                    break;
            }

            // If an exception is detected OR when content is non-DRM, fire notification right away.
            // For DRM content that does not have concurrency / geoblock error, allow notification to be fired after drm is handled
            if (restrictionType !== ContentRestrictionType.None || !entry.isDRM) {
                this.restrictionHandled.fire(this, {
                    entry: entry,
                    exception: restrictionType
                });
            }
        });
    }

    private _onDRMHandled(caller: DRMService, e: any): void {
        this._log('onDRMHandled');
        this.restrictionHandled.fire(this, {
            entry: e.entry,
            exception: ContentRestrictionType.None
        });
    }

    private _prepareDRMContent(entry: PlaylistEntry): void {
        if (this._drmService) {
            this._drmService.handleDRM(entry);
        } else {
            this.restrictionHandled.fire(this, {
                entry: entry,
                exception: ContentRestrictionType.DRM
            });
        }
    }

    private _onAuthToken(caller: AuthenticationService, token: string) {
        if (token) {
            this._log('onAuthToken', token);
            this._handleRestriction(this._currentEntry, token);
        } else {
            this._log('onAuthToken', 'failed');
            this.restrictionHandled.fire(this, {
                entry: this._currentEntry,
                exception: ContentRestrictionType.Authorization
            });
        }
    }

    private _log(eventType: string, message?: string, info?: any): void {
        if (this._util) {
            this._util.log('ContentRestrictionService', eventType, message, info);
        }
    }
}
