import { PlaylistTemplate } from './index';
import { OverlayComponent, Player, PlaylistEntry, Render } from '../index';
import { LocalStorageService } from '../../services';

export interface PlaylistData {
    currentItem: PlaylistEntry;
    data: PlaylistEntry[];
    isForRelatedContent: boolean;
    noCurrentVideo: boolean;
}

export interface PlaylistDependencies {
    $document?: Document,
    $render?: any,
    player: Player,
    localStorageService?: LocalStorageService,
}

export class PlaylistComponent {
    private _dependencies: PlaylistDependencies;
    private _playerContainer: HTMLElement;
    private _playlistData: PlaylistData = { currentItem: null, data: [], isForRelatedContent: false, noCurrentVideo: true };
    private _overlayComponent: OverlayComponent;
    public playlistElement: HTMLElement;

    private get _playlistLinks(): NodeListOf<HTMLElement> { return <NodeListOf<HTMLElement>> this.playlistElement.querySelectorAll('.playlist-link'); }

    constructor(isForRelatedContent: boolean, dependencies: PlaylistDependencies) {
        this._dependencies = dependencies;
        this._dependencies.$document = this._dependencies.$document || window.document;
        this._dependencies.$render = this._dependencies.$render || Render;
        this._dependencies.localStorageService = this._dependencies.localStorageService || LocalStorageService.Instance;

        this._overlayComponent = new OverlayComponent(this._dependencies.player);
        this._playerContainer = this._dependencies.player.playerContainer;
        this._playlistData.data = this._dependencies.player.playlist;
        this._playlistData.isForRelatedContent = isForRelatedContent;
        this._playlistData.noCurrentVideo = this._dependencies.player.isNotFound;

        if ( !this._playlistData.noCurrentVideo ) {
            this._setCurrentItem();
            this.bindTo(this._dependencies.player);
        }

        this._render();
    }

    public bindTo(player: Player): void {
        player.playlistItemChanged.subscribe((caller, e) => this.onPlaylistItemChanged(caller, e));
    }

    private _applyProgressIndicators(): void {
        for (let i = 0, { length } = this._playlistData.data; i < length; ++i) {
            var entry = this._playlistData.data[i];
            var progress = this._dependencies.localStorageService.get(entry.mediaId);

            entry.isCompleted = false;
            entry.progress = 0;
            if (progress) {
                if (progress.isCompleted) {
                    entry.isCompleted = true;
                } else if (progress.value && entry.metadata.duration) {
                    entry.progress = Math.ceil(progress.value / entry.metadata.duration * 100);
                }
            }
        }
    }

    private _setCurrentItem() {
        this._playlistData.currentItem = this._dependencies.player.currentMedia;
    }

    private _render() {
        this._applyProgressIndicators();
        this.playlistElement = this._dependencies.$document.createElement('section');
        this.playlistElement.innerHTML = this._dependencies.$render(PlaylistTemplate, this._playlistData);

        this.playlistElement.classList.add('playlist');
        if (this._dependencies.player.config.jwOptions.skin.name) {
            this.playlistElement.classList.add('jw-skin-' + this._dependencies.player.config.jwOptions.skin.name);
        }

        this._playerContainer.parentElement.insertBefore(this.playlistElement, this._playerContainer.nextSibling);
        this._renderClearFloatElement();

        // No redirectionUrlFormat defined, play video inline on playlist item click
        if (!this._dependencies.player.config.redirectionUrlFormat) {
            this._registerEventListeners();
        }
    }

    private _renderClearFloatElement() {
        const clearElement = this._dependencies.$document.createElement('div');
        clearElement.setAttribute('class', 'clear');
        this.playlistElement.parentElement.insertBefore(clearElement, this.playlistElement.nextSibling);
    }

    private _registerEventListeners() {
        const elements = this._playlistLinks;
        for (var i = 0; i < elements.length; i++) {
            const data: number = parseInt(elements[i].getAttribute('data-index'));
            elements[i].addEventListener('click', (e: Event) => this.playItem(e, data), false);
        }

    }

    public playItem(e: Event, data: number) {
        e.preventDefault();
        this._setCurrentItem();
        this._overlayComponent.removeOverlay();
        this._dependencies.player.goToPlaylistItem(data);
    }

    private onPlaylistItemChanged(caller: Player, e: any): void {
        const index: number = this._dependencies.player.playlistIndex;
        const playlistContent: Element = this.playlistElement.querySelector('.playlist-content');
        const activePlaylistItem: Element = this.playlistElement.querySelector('.active');
        const activePlaylistItemClasses = activePlaylistItem.getAttribute('class');
        activePlaylistItem.setAttribute('class', activePlaylistItemClasses.replace(/active/g, ''));
        var items = playlistContent.getElementsByTagName('li');
        items[index].className += 'active';
    }
}
