import { ChapterEvent, PlayerSubscriber, Player, PlaylistEntry, BeforePlayingEvent } from './player';
import { UtilsService } from '../services';

export interface KruxConfig {
    startEventData?: any
}

export interface KruxSubscriberDependencies {
    dataManager?: KruxDataManager;
    config?: KruxConfig;
}

export class KruxSubscriber implements PlayerSubscriber {
    private readonly CC_DISABLED_EVENT: string = 'event70';
    private readonly CC_ENABLED_EVENT: string = 'event69';
    private dataManager: KruxDataManager;
    private config: KruxConfig;
    private player: Player;

    public constructor(dependencies: KruxSubscriberDependencies = {}) {
        this.config      = dependencies.config ? dependencies.config : null;
        this.dataManager = dependencies.dataManager ? dependencies.dataManager : new KruxDataManager(undefined, undefined, this.config);
    }

    public bindTo(player: Player): void {
        player.beforePlaying.subscribe((caller, e) => this.onBeforePlaying(caller, e));
        player.captionsChanged.subscribe((caller, e) => this.onCaptionsChanged(caller, e));
        player.fullscreenChanged.subscribe((caller, e) => this.onFullscreenChanged(caller, e));
    }

    private onBeforePlaying(caller: Player, e: BeforePlayingEvent): void {
        if (e.isInitialPlay) {
            this.dataManager.sendStartEvent();
            this.sendMetaData(caller);
        }
    }

    private onCaptionsChanged(caller: Player, e: CaptionsEvent) {
        // track stands for the index of the currently selected CC, will be 0 when it's disabled.
        var name: string = e.track > 0 ? this.CC_ENABLED_EVENT : this.CC_DISABLED_EVENT;
        this.dataManager.sendGlobalEvent(name);
    }

    private onFullscreenChanged(caller: Player, e: FullscreenOptions) {
        if (e.fullscreen) {
            this.dataManager.sendGlobalEvent('event72');
        }
    }

    private sendMetaData(player: Player) {
        this.dataManager.sendMetaDataEvent(player.currentMedia);
    }
}

export class KruxDataManager {
    public constructor(private $window: Window = window, private util: UtilsService = UtilsService.Instance, private config: KruxConfig = {}) { }

    public sendGlobalEvent(name: string): void {
        this.addToKruxQueue('KrLauLot', { Video_player_event: name });
    }

    public sendMetaDataEvent(currentMedia: PlaylistEntry): void {
        const unavailable: string = 'Unavailable';

        this.addToKruxQueue('KrLdMJ0c', {
            ShowName:   currentMedia.show ? currentMedia.show : unavailable,
            EpisodeName: currentMedia.title ? currentMedia.title : unavailable,
            Genre: currentMedia.metadata.genre ? currentMedia.metadata.genre : unavailable,
            SeasonCode: currentMedia.metadata.seasonNumber ? this.util.padNumber(currentMedia.metadata.seasonNumber) : unavailable,
            EpisodeCode: currentMedia.metadata.episodeNumber ? this.util.padNumber(currentMedia.metadata.episodeNumber) : unavailable,
            Brand: currentMedia.origin ? currentMedia.origin : unavailable
        });
    }

    public sendSegmentEvent(segmentNumber: number): void {

        var data = {
            Video_segment_event_set: 'segmentStart',
            Video_segment_number: segmentNumber
        };
        this.addToKruxQueue('Kqq_x290', data);
    }

    public sendStartEvent(): void {
        let eventData = {
            Video_Start: 'start',
        };

        eventData = this.config ? { ...eventData, ...this.config.startEventData } : eventData;

        this.addToKruxQueue('KprV2MQz', eventData);
    }

    private addToKruxQueue(code: string, data: any) {
        var globalScope = <any> this.$window;

        if (!globalScope.Krux) {
            globalScope.Krux = function(...args: any[]) {
                globalScope.Krux.q.push(args);
            };
            globalScope.Krux.q = [];
        }

        globalScope.Krux('ns:corus', 'admEvent', code, data);
    }
}
