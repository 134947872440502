export * from './authentication.component';
export const AuthMessageTemplate = require('./authMessage.html');

export interface AuthMessageModel {
    image: string,
    message: string,
    skin: string,
    title: string,
    ctaLabel: string
}
